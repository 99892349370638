import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { ICreateInterviewPayload, ICreateZoomMeetPayload, InterviewSortCols, InterviewType } from "Modules/Core/Interviews/InterviewsModel";
import { ILabelValueBase, ITableHeadersBase, ITableHeadersOptions } from "shared/SharedModels";

export const InterviewHeaders: (ITableHeadersBase<InterviewSortCols> & ITableHeadersOptions)[] = [
    { columName: 'alphabetical', label: 'Title', order: 1, isSortable: true },
    { columName: 'recent', label: 'Date', order: 1, isSortable: true },
];

export const VideoInterviewTypes: ILabelValueBase[] = [
    { label: 'Other', value: 'other' },
    // To be enabled once api is fixed
    { label: 'Zoom', value: 'zoom' }
];

// export const TimeZones: ILabelValueBase[] = [
//     { label: 'Eastern Standard Time', value: 'EST' },
//     { label: 'Central Standard Time', value: 'CST' },
//     { label: 'Pacific Standard Time', value: 'PST' },
//     { label: 'Pacific Daylight Time', value: 'PDT' },
//     { label: 'Atlantic Standard Time', value: 'AST' },
//     { label: 'Hawaii-Aleutian Standard Time', value: 'HAST' },
//     { label: 'Hawaii-Aleutian Daylight Time', value: 'HADT' },
//     { label: 'Newfoundland Standard Time', value: 'NST' },
//     { label: 'Newfoundland Daylight Time', value: 'NDT' },
//     { label: 'Atlantic Daylight Time', value: 'ADT' },
//     { label: 'Eastern Daylight Time', value: 'EDT' },
//     { label: 'Central Daylight Time', value: 'CDT' },
//     { label: 'Mountain Standard Time', value: 'MST' },
//     { label: 'Mountain Daylight Time', value: 'MDT' },
//     { label: 'Alaska Daylight Time', value: 'AKDT' }
// ];

// export const InterviewDurations: ILabelValueBase<string, number>[] = [
//     { label: '30 Minutes', value: 30 },
//     { label: '60 Minutes', value: 60 },
//     { label: '90 Minutes', value: 90 },
//     { label: '120 Minutes', value: 120 }
// ];

export const CreateInterviewPayload: ICreateInterviewPayload = {
    application_id: undefined,
    from_time: '',
    info: '',
    note: '',
    schedule_date: '',
    to_time: '',
    type_of_interview: ''
};

export const CreateZoomMeetPayload: ICreateZoomMeetPayload = {
    agenda: '',
    from_time: '',
    password: '',
    schedule_date: '',
    topic: ''
}

export const InterviewModes: InterviewType[] = ['In-person', 'Phone', 'Video'];

export const InterviewModeIconMap = {
    'In-person': <BusinessOutlinedIcon color='primary' />,
    'Phone': <LocalPhoneOutlinedIcon color='primary' />,
    'Video': <VideocamOutlinedIcon color='primary' />,
};