import { Box, LinearProgress, Tab } from "@mui/material";
import { ApplicantWrapperStyled } from "Modules/Core/Applicants/ApplicantProfile/Applicant.styled";
import { ApplicantPath, ApplicantPathEnum } from "Modules/Core/Applicants/ApplicantProfile/ApplicantProfileModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ShTabs } from "shared/SharedStyles/ShNavigation";
import { getApplicantComments } from "store/slices/employer/applicants/applicant-comments-slice";
import { getApplicantDetails, getFullFingerPrintImage } from "store/slices/employer/applicants/applicant-profile-slice";
import { fetchApplicantSummary } from "store/slices/employer/applicants/applicants-list-slice";

export const ApplicantWrapper = () => {
    const pathname = useLocation()?.pathname?.split('/').pop() as ApplicantPath;
    const { jobId, applicantId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [curPage, setCurPage] = useState<ApplicantPath>('profile');
    const { getApplicantDetailsStatus, applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);

    // Set tab selection based on url path.
    useEffect(() => {
        if (pathname) {
            if ((pathname === 'questions' && applicantDetails?.atsPurchased) || !applicantDetails) {
                navigate(`/employer/job/${jobId}/applicant/${applicantId}/profile`);
            } else {
                setCurPage(pathname as ApplicantPath);
            }
        }
    }, [applicantDetails, applicantId, jobId, navigate, pathname]);

    useEffect(() => {
        if (jobId && applicantId) {
            dispatch(getApplicantDetails({ applicationId: parseInt(applicantId), jobId: parseInt(jobId) }));
        }
    }, [applicantId, dispatch, jobId]);

    useEffect(() => {
        if (applicantDetails && applicantId) {
            dispatch(fetchApplicantSummary({ candidateEmployerJobId: parseInt(applicantId), jobId: parseInt(jobId ?? '') }));
            if (!applicantDetails.atsPurchased) {
                dispatch(getFullFingerPrintImage({ applicantId: parseInt(applicantId) }));
            }
            dispatch(getApplicantComments({ applicantId: parseInt(applicantId) }));
        }
    }, [applicantDetails, applicantId, dispatch, jobId]);

    return (<>
        <ApplicantWrapperStyled>
            {
                getApplicantDetailsStatus === 'pending' ? <LinearProgress /> :
                    <>
                        <Box paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
                            <ShTabs className="applicant-tabs" aria-label="Applicant Tabs" variant="scrollable" scrollButtons='auto' allowScrollButtonsMobile
                                value={curPage} onChange={(e, nV) => setCurPage(nV as ApplicantPath)}>
                                <Tab label="Profile" component={Link} value={ApplicantPathEnum.Profile} to={`/employer/job/${jobId}/applicant/${applicantId}/profile`} />
                                <Tab label="Resume" component={Link} value={ApplicantPathEnum.Resume} to={`/employer/job/${jobId}/applicant/${applicantId}/resume`} />
                                <Tab label="Comments" component={Link} value={ApplicantPathEnum.Comments} to={`/employer/job/${jobId}/applicant/${applicantId}/comments`} />
                                {/* Show Questions tab only if atsPurchased is false */}
                                {applicantDetails && !applicantDetails?.atsPurchased &&
                                    <Tab label="Questions" component={Link} value={ApplicantPathEnum.Questions} to={`/employer/job/${jobId}/applicant/${applicantId}/questions`} />
                                }
                                {/* <Tab label="Stage" component={Link} value={ApplicantPathEnum.Stage} to={`/employer/job/${jobId}/applicant/${applicantId}/stage`} /> */}
                            </ShTabs>
                        </Box>
                        <Box className="applicant-outlet" padding={{ xs: 2, sm: 3, md: 4, lg: 4 }} flex={1}>
                            <Outlet />
                        </Box>
                    </>
            }
        </ApplicantWrapperStyled>
    </>);
};