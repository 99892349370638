import { Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { DashboardContentWrapper } from "Modules/Core/Admin/AdminDashboard/AdminDashboard.styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { deleteCandidateByEmail, resetDeleteCandidateByEmail } from "store/slices/admin/candidate/admin-candidate-slice";
import { getEmployerByEmail, resetGetEmployerByEmail } from "store/slices/admin/employer/admin-employer-slice";

export const AdminDashboard = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { adminEmployerByEmail, getEmployerByEmailResponse, getEmployerByEmailStatus } = useAppSelector(state => state.admin.adminEmployer);
  const { deleteCandidateByEmailResponse, deleteCandidateByEmailStatus } = useAppSelector(state => state.admin.adminCandidate);
  const [candidateEmail, setCandidateEmail] = useState<string>('');
  const [employerEmail, setEmployerEmail] = useState<string>('');

  useEffect(() => {
    if (getEmployerByEmailStatus === 'success' && adminEmployerByEmail?.redirectId) {
      navigate(`/admin/employers/${adminEmployerByEmail?.redirectId}/edit`);
      dispatch(resetGetEmployerByEmail());
    }
  }, [adminEmployerByEmail?.redirectId, dispatch, getEmployerByEmailStatus, navigate]);

  return (
    <DashboardContentWrapper>
      {(getEmployerByEmailStatus === 'failed' || (getEmployerByEmailStatus === 'success' && !adminEmployerByEmail?.redirectId)) &&
        <ShAlert severity="error" marginBottom={2}>{getEmployerByEmailResponse}</ShAlert>}
      {(deleteCandidateByEmailStatus === 'failed' || deleteCandidateByEmailStatus === 'success') &&
        <ShAlert severity={deleteCandidateByEmailStatus === 'failed' ? "error" : 'success'}
          onClose={() => dispatch(resetDeleteCandidateByEmail())}>{deleteCandidateByEmailResponse}</ShAlert>}
      <Stack direction='row' padding={2} columnGap={1}>
        <TextField id="candidate_email" label="Candidate Email"
          variant="outlined" value={candidateEmail} onChange={e => setCandidateEmail(e.target.value)} />
        <ShButton disabled={!candidateEmail || deleteCandidateByEmailStatus === 'pending'}
          onClick={() => dispatch(deleteCandidateByEmail({ email: candidateEmail }))}>
          {deleteCandidateByEmailStatus === 'pending' ? 'Deleting...' : 'Delete'}
        </ShButton>
      </Stack>
      <Stack direction='row' padding={2} columnGap={1}>
        <TextField id="employer_email" label="Employer Email"
          variant="outlined" value={employerEmail} onChange={e => setEmployerEmail(e.target.value)} />
        <ShButton disabled={!employerEmail || getEmployerByEmailStatus === 'pending'}
          onClick={() => dispatch(getEmployerByEmail({ email: employerEmail }))}>
          {getEmployerByEmailStatus === 'pending' ? 'Searching...' : 'Search'}
        </ShButton>
      </Stack>
    </DashboardContentWrapper>
  );
};