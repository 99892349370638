import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    ICreateInterviewPayload, IInterviewsActionsState, IUpdateInterviewScheduleParams, IUpdateInterviewStatusParams
} from 'Modules/Core/Interviews/InterviewsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { IAllJobDetails } from '../jobs/jobs-slice-model';

const initialInterviewsActionsState: IInterviewsActionsState = {
    createInterviewScheduleResponse: '',
    createInterviewScheduleStatus: 'idle',
    getJobDetailsResponse: '',
    getJobDetailsForInterviewStatus: 'idle',
    updateInterviewScheduleResponse: '',
    updateInterviewScheduleStatus: 'idle',
    updateInterviewStateResponse: '',
    updateInterviewStateStatus: 'idle'
};

export const createInterviewSchedule = createAsyncThunk<IBaseResponse, ICreateInterviewPayload, { rejectValue: IBaseResponse }>(
    "createInterviewSchedule",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INTERVIEWS}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateInterviewStatus = createAsyncThunk<IBaseResponse, IUpdateInterviewStatusParams, { rejectValue: IBaseResponse }>(
    "updateInterviewStatus",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.INTERVIEWS}/${id}/status`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateInterviewSchedule = createAsyncThunk<IBaseResponse, IUpdateInterviewScheduleParams, { rejectValue: IBaseResponse }>(
    "updateInterviewSchedule",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.INTERVIEWS}/${id}`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobDetailsForInterview = createAsyncThunk<IBaseResponse<IAllJobDetails>, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getJobDetailsForInterview",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}`)
            .then((response: AxiosResponse<IBaseResponse<IAllJobDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const interviewsActionsSlice = createSlice({
    name: 'interviewsActions',
    initialState: initialInterviewsActionsState,
    reducers: {
        resetCreateInterview: (state) => { state.createInterviewScheduleResponse = ''; state.createInterviewScheduleStatus = 'idle' },
        resetUpdateInterviewStatus: (state) => { state.updateInterviewStateResponse = ''; state.updateInterviewStateStatus = 'idle' },
        resetUpdateInterviewSchedule: (state) => { state.updateInterviewScheduleResponse = ''; state.updateInterviewScheduleStatus = 'idle' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialInterviewsActionsState;
        });
        // create interview
        builder.addCase(createInterviewSchedule.pending, (state) => {
            state.createInterviewScheduleStatus = 'pending'
        });
        builder.addCase(createInterviewSchedule.fulfilled, (state, action) => {
            state.createInterviewScheduleStatus = 'success';
            state.createInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(createInterviewSchedule.rejected, (state, action) => {
            state.createInterviewScheduleStatus = 'failed';
            state.createInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update interview status
        builder.addCase(updateInterviewStatus.pending, (state) => {
            state.updateInterviewStateStatus = 'pending'
        });
        builder.addCase(updateInterviewStatus.fulfilled, (state, action) => {
            state.updateInterviewStateStatus = 'success';
            state.updateInterviewStateResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateInterviewStatus.rejected, (state, action) => {
            state.updateInterviewStateStatus = 'failed';
            state.updateInterviewStateResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update interview schedule
        builder.addCase(updateInterviewSchedule.pending, (state) => {
            state.updateInterviewScheduleStatus = 'pending'
        });
        builder.addCase(updateInterviewSchedule.fulfilled, (state, action) => {
            state.updateInterviewScheduleStatus = 'success';
            state.updateInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateInterviewSchedule.rejected, (state, action) => {
            state.updateInterviewScheduleStatus = 'failed';
            state.updateInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // Job details
        builder.addCase(getJobDetailsForInterview.pending, (state, action) => {
            state.getJobDetailsForInterviewStatus = 'pending';
        });
        builder.addCase(getJobDetailsForInterview.fulfilled, (state, action) => {
            state.getJobDetailsForInterviewStatus = 'success';
            state.jobDetails = action.payload.data;
        });
        builder.addCase(getJobDetailsForInterview.rejected, (state, action) => {
            state.getJobDetailsForInterviewStatus = 'failed';
            state.getJobDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetCreateInterview, resetUpdateInterviewStatus, resetUpdateInterviewSchedule } = interviewsActionsSlice.actions;
export default interviewsActionsSlice;
