import EastIcon from '@mui/icons-material/East';
import { Link, Stack, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IsLgScreen } from 'helpers/hooks';
import { Outlet, Link as RouterLink } from "react-router-dom";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShButton, ShWordpressButton } from "shared/SharedStyles/ShInputs";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";
import { ResourceCTAColorBase } from "shared/SharedStyles/styleConstants";
import { ResourceFooter } from "./ResourceFooter";
import { ResourceWrapperStyle, ResourcesWrapperBox } from "./Resources.styled";
import { ResourceWrapperCTAConstantMessage } from "./ResourcesConstants";
import { MegaMenu } from "./ResourcesMegaMenu";

export const ResourcesWrapper = () => {
    const isLgScreen = IsLgScreen()

    return (
        <ResourcesWrapperBox>
            <ResourceWrapperStyle justifyContent='center' direction='row' backgroundColor={ResourceCTAColorBase}>
                <Typography fontSize={15} textAlign='center'>
                    {ResourceWrapperCTAConstantMessage.message}
                    <Link component={RouterLink} to={ResourceWrapperCTAConstantMessage.redirection_url}
                        underline="none" color='inherit' justifyContent='center' alignContent='center' display='inline-flex'>
                        <strong> {ResourceWrapperCTAConstantMessage.messageCTA}</strong> &nbsp;
                        <EastIcon />
                    </Link>
                </Typography>

            </ResourceWrapperStyle>

            <Stack height='100%'>
                <ShAppBar position="sticky">
                    <Toolbar>
                        <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                            <a href="https://smoothhiring.com" target="_self" rel="noopener noreferrer">
                                <ShLogo />
                            </a>
                            <MegaMenu />
                            <Stack direction='row' alignItems='center'>
                                {isLgScreen && (
                                    <Stack direction="row" columnGap={2} alignItems='center'>
                                        {/* Removed for now TBD */}
                                        {/* <ContactInfo showDivider dividerOrientation="vertical" /> */}
                                        <ShButton variant='outlined' size='large' href="https://app.smoothhiring.com/accounts/login"> Login </ShButton>
                                        <ShWordpressButton variant='outlined' size='large' color='success' href="https://app.smoothhiring.com/employers/new"> Sign Up </ShWordpressButton>
                                    </Stack>
                                )}
                                {/* Removed for resources so we can use more a little custom styling */}
                                {/* <ThemeToggler /> */}
                            </Stack>
                        </Stack>
                    </Toolbar>
                </ShAppBar>
                <Box flex={1}>
                    <Outlet />
                </Box>
                <ResourceFooter />
            </Stack>
        </ResourcesWrapperBox>
    )
}
