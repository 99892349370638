import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DarkModeBackground, LightModeBackground, LogoutRedColor, TextPrimary, WhiteColor } from 'shared/SharedStyles/styleConstants';

const drawerWidth = 250;

export const ParentContainerStyled = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    flexDirection: 'column',
  })
);

export const TopNavItemsContainerStyled = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'light' ? TextPrimary : WhiteColor,
    },
    '& .MuiSvgIcon-root.logout-icon': {
      color: theme.palette.mode === 'light' ? LogoutRedColor : WhiteColor,
    }
  })
);

export const Wrapper = styled(Box)(
  ({ theme }) => ({
    flex: 1,
    overflow: 'hidden',
    display: 'flex'
  })
);

export const MainContainer = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
      '& .side-bar-tgl': {
        display: 'none'
      },
      '& .breadcrumb-control': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // TBD
        // marginBottom: 0
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& .side-bar-tgl': {
        display: 'none'
      },
      '& .breadcrumb-control': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        // TBD
        // marginBottom: theme.spacing(1),
      }
    },
    [theme.breakpoints.up('md')]: {
      '& .side-bar-tgl': {
        display: 'flex'
      },
      '& .breadcrumb-control': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        // TBD
        // marginBottom: theme.spacing(1),
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& .breadcrumb-control': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        // TBD
        // marginBottom: theme.spacing(1),
      }
    },
    height: '100%',
    overflow: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.mode === 'light' ? LightModeBackground : DarkModeBackground,
    '& .breadcrumb-control': {
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      top: 0,
      zIndex: 10,
      // borderBottom: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
      backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground
    },
    '& .main-outlet-container': {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '& .dsk-only-inline-flex': {
          display: 'none'
        }
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '& .dsk-only-inline-flex': {
          display: 'inline-flex'
        }
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        '& .dsk-only-inline-flex': {
          display: 'inline-flex'
        }
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      flex: 1,
      overflow: 'auto',
      /////////////////////////////////// Shared Styles //////////////////////////////////
      '& .MuiInputBase-input': {
        '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0
        }
      }
    },
    '& .center-h': {
      alignSelf: 'center'
    }
  })
);

export const SideNavContainer = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    zIndex: 20,
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    width: '225px',
    overflow: 'hidden',
    '&.is-mini-drawer': {
      width: '60px'
    }
  })
);

export const LeftDrawerStyled = styled(Drawer)(
  ({ theme }) => ({
    width: drawerWidth,
    height: '100vh',
    '& .MuiDrawer-paperAnchorLeft': {
      width: drawerWidth,
    },
    overflow: 'auto'
  })
);