import { Stack, Typography } from "@mui/material"
import { ShMuiLink } from "shared/SharedStyles/ShNavigation"
import { ShPaper } from "shared/SharedStyles/ShSurfaces"
import { DashboardFeatureTile } from "../Dashboard.styled"
import { DashboardFeatureTiles } from "../DashboardConstants"
import { useAppSelector } from "helpers/hooks"
import { Link as RouterLink } from 'react-router-dom'
import { RoleAccessControlMap } from "Modules/Core/AuthV2/AuthConstants"

export const DashboardQuickActionTilesWidget = () => {

    const {accountAccess } = useAppSelector((state) => state.auth.auth);

    return (
        <ShPaper height='100%' marginBottom='10px' variant='outlined' >
                <Stack>
                    <Typography variant="body1" fontWeight="bold" gutterBottom>
                        Get Started!
                    </Typography>
                        <Stack direction='row' columnGap={2} rowGap={2} flexWrap='wrap'>
                        {DashboardFeatureTiles?.map((tile) => (
                            RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                            ?.[tile.featureName]?.visibility !== 'disabled' &&
                                <DashboardFeatureTile key={tile?.featureRedirectUrl}>
                                    <ShMuiLink noUnderline component={RouterLink} to={tile.featureRedirectUrl}
                                        display='flex' alignItems='center'
                                        columnGap={1} noBlueHighlight paddingLeft={2}
                                        paddingRight={1} paddingY={1} width='100%'>
                                        {tile.tileIcon}
                                        <Stack>
                                            <Typography variant="body1" fontWeight="bold">
                                                {tile.tileLabel}
                                            </Typography>
                                            <Typography variant="caption" color='gray'>
                                                {tile.caption}
                                            </Typography>
                                        </Stack>
                                    </ShMuiLink>
                                </DashboardFeatureTile>
                            ))}
                        </Stack>
                </Stack>
            </ShPaper>
    )
}
