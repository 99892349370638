import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";
import { ICandidateInfoInResume, IResumeSliceState } from "./resume-slice-model";

const initialResumeSliceState: IResumeSliceState = {};

export const getCandidateInfoFromResume = createAsyncThunk<IBaseResponse<ICandidateInfoInResume>, { payload: FormData }, { rejectValue: IBaseResponse }>(
    "getCandidateInfoFromResume",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INFO_BY_RESUME}`, payload)
            .then((response: AxiosResponse<IBaseResponse<ICandidateInfoInResume>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const uploadApplicantResume = createAsyncThunk<string, { applicationId: number, resume: FormData },
    { rejectValue: string }>(
        "uploadApplicantResume",
        async ({ resume, applicationId }, { rejectWithValue }) => {
            return httpAdapterInstance
                .post(`${EmployerApiEndpoints.CANDIDATES}/${applicationId}/resume`, resume, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }).then((response) => response.data.message)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const resumeSlice = createSlice({
    name: "resume",
    initialState: initialResumeSliceState,
    reducers: {
        resetGetCandidateInfoFromResume: (state) => {
            state.getCandidateInfoFromResumeStatus = 'idle';
            state.getCandidateInfoFromResumeResponse = '';
            state.candidateInfoInResume = undefined;
        },
        resetUploadApplicantResume: (state) => {
            state.uploadResumeStatus = 'idle';
            state.uploadResumeResponse = '';
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialResumeSliceState;
        });
        // Fetch jobs counts
        builder.addCase(getCandidateInfoFromResume.pending, (state) => {
            state.getCandidateInfoFromResumeStatus = 'pending'
        });
        builder.addCase(getCandidateInfoFromResume.fulfilled, (state, action) => {
            state.getCandidateInfoFromResumeStatus = 'success';
            state.candidateInfoInResume = action.payload?.data;
        });
        builder.addCase(getCandidateInfoFromResume.rejected, (state, action) => {
            state.getCandidateInfoFromResumeStatus = 'failed';
            state.getCandidateInfoFromResumeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // uploadApplicantResume
        builder.addCase(uploadApplicantResume.pending, (state) => {
            state.uploadResumeStatus = "pending";
        })
        builder.addCase(uploadApplicantResume.fulfilled, (state, action) => {
            state.uploadResumeStatus = "success";
            state.uploadResumeResponse = action?.payload;
        });
        builder.addCase(uploadApplicantResume.rejected, (state, action) => {
            state.uploadResumeStatus = "failed";
            state.uploadResumeResponse = action?.payload ?? DefaultAPIErrorMsg;
        })
    }
});

export const { resetGetCandidateInfoFromResume, resetUploadApplicantResume } = resumeSlice.actions;
export default resumeSlice;
