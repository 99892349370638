import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { IConfirmationDialog } from "shared/SharedComponents/ConfirmationDialog/ConfirmationDialogModel";
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";

/**
 * ConfirmationDialog Component
 *
 * @param {Function} onCancel - Callback function to handle cancellation action.
 * @param {Function} onConfirm - Callback function to handle confirmation action.
 * @param {string} contentText - Text content to be displayed in the dialog.
 * @param {string} title - Title of the confirmation dialog.
 * @param {boolean} isDialogOpen - Boolean to control the visibility of the dialog.
 * @param {string} cancelButtonLabel - Label for the cancel button.
 * @param {string} confirmButtonLabel - Label for the confirm button.
 */

export const ConfirmationDialog = ({
    onCancel, onConfirm, contentText, title, isDialogOpen, cancelButtonLabel, actionsPlacement, onDialogClose,
    confirmButtonLabel, isCancelDisabled = false, isConfirmDisabled = false, isShowCloseIcon }: IConfirmationDialog) => {

    return (<>
        <ShDialog open={isDialogOpen} actionsPlacement={actionsPlacement} onClick={e => e.stopPropagation()}
            aria-labelledby="title" aria-describedby="sub_title">
            <DialogTitle id='title' textAlign='center'>
                <Box paddingRight={isShowCloseIcon ? 2 : ''}>{title ?? 'Confirm'}</Box>
                {isShowCloseIcon && <IconButton onClick={onDialogClose}><CloseIcon /></IconButton>}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="sub_title" whiteSpace='break-spaces'>
                    {contentText ?? 'Are you sure!'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ShButton size='small' onClick={onCancel} disabled={isCancelDisabled} minWidth={64}>
                    {cancelButtonLabel ?? 'Cancel'}
                </ShButton>
                <ShGreenBtn size='small' onClick={onConfirm} disabled={isConfirmDisabled}>
                    {confirmButtonLabel ?? 'Confirm'}
                </ShGreenBtn>
            </DialogActions>
        </ShDialog>
    </>);
};