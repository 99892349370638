import { Alert, AlertProps, Backdrop, Dialog, DialogProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import TwoStepAuthImage from 'assets/Login/DeviceAuth.svg';
import { TJustifyContent } from 'shared/SharedModels';
import { WhiteColor } from 'shared/SharedStyles/styleConstants';

interface IShAlert extends AlertProps {
    marginTop?: number
    marginBottom?: number
};

export const ShAlert = styled(Alert, {
    shouldForwardProp: (prop) => prop !== "marginTop" && prop !== "marginBottom"
})<IShAlert>(({ theme, marginTop, marginBottom }) => ({
    marginTop: marginTop ? theme.spacing(marginTop) : 0,
    marginBottom: marginBottom ? theme.spacing(marginBottom) : 0,
    display: 'flex',
    alignItems: 'center',
}));

export const ShBackdrop = styled(Backdrop)(
    ({ theme }) => ({
        color: WhiteColor,
        zIndex: theme.zIndex.drawer + 1,
        '& .MuiCard-root': {
            maxWidth: '400px',
        },
        '& .MuiCircularProgress-root': {
            alignSelf: 'center'
        }
    })
);

interface IShDialog extends DialogProps {
    actionsPlacement?: TJustifyContent
};

export const ShDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "actionsPlacement"
})<IShDialog>(({ theme, actionsPlacement = 'space-between' }) => ({
    '& .MuiDialogTitle-root': {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        '& .MuiButtonBase-root': {
            position: 'absolute',
            right: '4px',
            top: '4px'
        }
    },
    '& .MuiDialog-paper': {
        minWidth: '350px',
        minHeight: '150px',
        '& .auth-icon': {
            backgroundImage: `url(${TwoStepAuthImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            height: '21vh',
            width: '17vw',
            minHeight: '80px',
            minWidth: '60px'
        },
    },
    '& .MuiDialogActions-root': {
        justifyContent: actionsPlacement
    }
}));