import { CircularProgress, DialogActions, DialogContent, DialogContentText, Stack, Typography } from "@mui/material";
import parse from 'html-react-parser';
import { Dispatch, SetStateAction } from "react";
import { ShFailureAlert } from "shared/SharedComponents/ShAlerts/ShFailureAlert";
import { ApiState } from "shared/SharedModels";
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { HtmlRegex } from "shared/constants";

interface IJobDescriptionDialog {
    isJobDescOpen: boolean,
    setIsJobDescOpen: Dispatch<SetStateAction<boolean>>,
    jobDescApiStatus: ApiState,
    jobDescApiResponse: string,
    jobName?: string,
    jobDescription?: string
};

export const JobDescriptionDialog = ({ isJobDescOpen, jobDescApiStatus, setIsJobDescOpen,
    jobDescription, jobDescApiResponse, jobName }: IJobDescriptionDialog) => {

    return (<>
        <ShDialog open={isJobDescOpen} onClose={(e) => setIsJobDescOpen(false)}
            onClick={e => e.stopPropagation()} scroll='paper' aria-labelledby='job-desc-title'>
            {jobDescApiStatus === 'pending' ?
                <Stack justifyContent='center' alignItems='center' flex={1}>
                    <CircularProgress />
                </Stack> :
                <>
                    {jobDescApiStatus === 'failed' &&
                        <ShFailureAlert msg={jobDescApiResponse} />
                    }
                    {jobDescription ? <>
                        <Typography id='job-desc-title' fontWeight='bold' paddingX={2} paddingY={1}>{jobName}</Typography>
                        <DialogContent dividers>
                            {/* 
                                Check if the 'jobDescription' is plain text or stringified HTML with a Regex.
                                Using 'html-react-parser' to parse the 'jobDescription' cause it is stringified HTML.
                                If 'jobDescription' is a plain text, display it in 'DialogContentText' element.
                            */}
                            {HtmlRegex.test(jobDescription) ? parse(jobDescription) :
                                <DialogContentText whiteSpace='break-spaces' color='inherit'>
                                    {jobDescription}
                                </DialogContentText>}
                        </DialogContent>
                    </> : <>
                        <Typography textAlign='center' padding={2} flex={1}>Job description not found!</Typography>
                    </>}
                    <DialogActions>
                        <ShButton variant='outlined' disableElevation size='small'
                            onClick={(e) => { setIsJobDescOpen(false); e.stopPropagation() }}>
                            Close</ShButton>
                    </DialogActions>
                </>}
        </ShDialog>
    </>);
};