import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { BorderColorLight } from 'shared/SharedStyles/styleConstants';
import { OneAlphabet, OneNumeric } from 'shared/constants';
import { IResetPasswordPayload } from 'store/slices/auth-v2/auth-v2-model';
import { resetPassReset, resetPassword } from 'store/slices/auth-v2/password-slice';

export const ResetPassword = () => {

    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { resettingPassword, resetPasswordResponse, resetPassValidations } = useAppSelector((state) => state.auth.password);
    const [resetPasswordPayload, setResetPasswordPayload] = useState<IResetPasswordPayload>({ password: '', confirmPassword: '' });
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const resetPass = () => {
        const token = queryParams.get('token');
        if (token !== null) {
            dispatch(resetPassword({ token: token, payload: resetPasswordPayload }));
        }
    };

    // Disable save button.
    useEffect(() => {
        setIsSaveDisabled(resetPasswordPayload?.confirmPassword?.trim()?.length === 0 ||
            resetPasswordPayload?.password?.trim()?.length === 0 || (resetPasswordPayload?.confirmPassword?.trim()?.length > 0 &&
                resetPasswordPayload?.password?.trim()?.length > 0 &&
                resetPasswordPayload?.confirmPassword?.trim() !== resetPasswordPayload?.password?.trim()) ||
            !OneAlphabet.test(resetPasswordPayload.password) || !OneNumeric.test(resetPasswordPayload.password) ||
            resetPasswordPayload?.password?.trim()?.length < 6);
    }, [resetPasswordPayload?.confirmPassword, resetPasswordPayload?.password]);

    return (<>
        <ShContainer maxWidth='sm' margin='auto' height='100%' display='flex' alignItems='center' justifyContent='center'>
            <ShPaper elevation={1} height='fit-content' width='100%'>
                <Typography variant='h6' fontWeight={600} textAlign='center' gutterBottom
                    borderBottom={`1px solid ${BorderColorLight}`} padding={1}>Reset Password</Typography>
                {/* List of password validations. Ex: Min 8 characters, At least 1 digit etc*/}
                {resetPassValidations?.length > 0 &&
                    <Stack>
                        {resetPassValidations.map((val, i) =>
                            (<Typography key={i} variant='caption' className='color-grey' gutterBottom>{val.message}</Typography>))}
                    </Stack>
                }
                <Stack mb={3} mt={1} spacing={3} paddingX={{ xs: 3, sm: 3, md: 4, lg: 4 }} paddingY={1}>
                    {resettingPassword === 'failed' &&
                        <Alert className='pass-reset-alert' severity='error'
                            onClose={() => { dispatch(resetPassReset()) }}>{resetPasswordResponse}</Alert>}
                    {resettingPassword === 'success' &&
                        <Alert className='pass-reset-alert' severity='success'>
                            <Typography fontWeight={600}>{resetPasswordResponse}</Typography>
                        </Alert>}
                    <Stack width='100%'>
                        <TextField size='small' id='password' label='New Password' variant='outlined' type={showPassword ? 'text' : 'password'}
                            value={resetPasswordPayload.password} onChange={(e) => setResetPasswordPayload({ ...resetPasswordPayload, password: e.target.value })}
                            margin='dense'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <IconButton
                                        edge='end' onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }} />
                        {resetPasswordPayload.password?.trim().length > 0 && <>
                            <Typography variant='body2'>Password must contain :</Typography>
                            <Typography variant='body2' display='flex' alignItems='center'>
                                {resetPasswordPayload.password?.length < 6 ? <ClearIcon color='error' fontSize='small' /> :
                                    <CheckIcon color='success' fontSize='small' />}&nbsp;At least 6 characters</Typography>
                            <Typography variant='body2' display='flex' alignItems='center'>
                                {OneAlphabet.test(resetPasswordPayload.password) ? <CheckIcon color='success' fontSize='small' /> :
                                    <ClearIcon color='error' fontSize='small' />}&nbsp;At least one Alphabet</Typography>
                            <Typography variant='body2' display='flex' alignItems='center'>
                                {OneNumeric.test(resetPasswordPayload.password) ? <CheckIcon color='success' fontSize='small' /> :
                                    <ClearIcon color='error' fontSize='small' />}&nbsp;At least one number</Typography>
                        </>}
                    </Stack>
                    <Stack width='100%'>
                        <TextField size='small' id='confirm_password' label='Confirm Password' variant='outlined' type={isShowConfirmPassword ? 'text' : 'password'}
                            value={resetPasswordPayload.confirmPassword} onChange={(e) => setResetPasswordPayload({ ...resetPasswordPayload, confirmPassword: e.target.value })}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <IconButton
                                        edge='end' onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}>
                                        {isShowConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }} />
                        {resetPasswordPayload.password?.trim().length > 0 && resetPasswordPayload.confirmPassword?.trim()?.length > 0 &&
                            (resetPasswordPayload.password === resetPasswordPayload.confirmPassword ?
                                <Typography variant='body2' display='flex' alignItems='center'>
                                    <CheckIcon color='success' fontSize='small' /> &nbsp; Passwords matched</Typography> :
                                <Typography variant='body2' display='flex' alignItems='center'>
                                    <ClearIcon color='error' fontSize='small' /> &nbsp;Passwords didn't match</Typography>)}
                    </Stack>
                    {resettingPassword === 'success' ? <ShGreenBtn variant='contained' disableElevation
                        onClick={() => navigate('/account/login')}>Go to Login</ShGreenBtn> :
                        <ShGreenBtn className='self-center' variant='contained' disableElevation disabled={isSaveDisabled}
                            onClick={resetPass}>{resettingPassword === 'pending' ? 'Resetting password...' : 'Reset password'}</ShGreenBtn>}
                </Stack>
            </ShPaper>
        </ShContainer>

    </>);
}