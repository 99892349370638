import { CircularProgress, Stack, Typography } from "@mui/material";
import { JobDescriptionDialog } from "Modules/Core/JobsTs/JobDescriptionDialog";
import jobDescFill from 'assets/Images/job_desc_fill.png';
import { useAppSelector } from "helpers/hooks";
import { useState } from "react";
import { ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { DesktopOnlyStack } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

export const JobDetailsPanel = () => {

    const [isJobDescriptionOpen, setIsJobDescriptionOpen] = useState<boolean>(false);
    const { jobDetailsByCode, getJobDetailsByCodeResponse, getJobDetailsByCodeStatus } = useAppSelector(state => state.employer.employerJobs.jobInfo);

    return (<>
        <ShPaper variant='outlined'>
            {
                getJobDetailsByCodeStatus === 'pending' ?
                    <>
                        <CircularProgress />
                        <Typography variant="body2">Loading Job details...</Typography>
                    </> : <>
                        <Stack rowGap={{ xs: 1, sm: 2, md: 2, lg: 2 }} width='100%'
                            height={{ xs: '100%', sm: '100%', md: 'unset', lg: 'unset' }}
                            alignItems={{ xs: 'center', sm: 'center', md: 'unset', lg: 'unset' }}
                            direction={{ xs: 'row', sm: 'row', md: 'column', lg: 'column' }}
                            justifyContent='space-evenly' columnGap={1} flexWrap='wrap'>
                            {jobDetailsByCode?.company_alias &&
                                <Stack>
                                    <Typography variant='body1' fontWeight={600}>Company</Typography>
                                    <Typography variant='body2'>{jobDetailsByCode?.company_alias}</Typography>
                                </Stack>}
                            {jobDetailsByCode?.name &&
                                <Stack>
                                    <Typography variant='body1' fontWeight={600}>Job Title</Typography>
                                    <Typography variant='body2'>{jobDetailsByCode?.name}</Typography>
                                </Stack>
                            }
                           {jobDetailsByCode?.city && (
                            <Stack>
                                <Typography variant='body1' fontWeight={600}>Job Location</Typography>
                                <Typography variant='body2'>
                                {jobDetailsByCode?.city}
                                {jobDetailsByCode?.state_abbreviation && `, ${jobDetailsByCode?.state_abbreviation}`}
                                </Typography>
                            </Stack>
                            )}
                            <DesktopOnlyStack>
                                <img src={jobDescFill} alt="jobDescFill" width={150} />
                            </DesktopOnlyStack>
                            <ShGreenBtn onClick={() => setIsJobDescriptionOpen(true)}>View job description</ShGreenBtn>
                            {/* Job description dialog. */}
                            <JobDescriptionDialog isJobDescOpen={isJobDescriptionOpen} setIsJobDescOpen={setIsJobDescriptionOpen}
                                jobDescApiResponse={getJobDetailsByCodeResponse} jobDescApiStatus={getJobDetailsByCodeStatus}
                                jobDescription={jobDetailsByCode?.description} jobName={jobDetailsByCode?.name} />
                        </Stack>
                    </>
            }
        </ShPaper>
    </>)
}