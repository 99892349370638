import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { EmployerSubDomainOutletWrapper } from "./EmployerSubDomain.styled";
import { EmployerSubDomainTopNav } from "./EmployerSubDomainTopNav";

export const EmployerSubDomainWrapper = () => {

    return (<>
        <Stack height='100vh'>
            <EmployerSubDomainTopNav />
            <EmployerSubDomainOutletWrapper>
                <Outlet />
            </EmployerSubDomainOutletWrapper>
        </Stack>
    </>);
};