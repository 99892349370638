import SvgIcon from "@mui/material/SvgIcon";
import { StrongFitIcon, FitIcon, WeakFitIcon, DistortionIcon } from "assets/Icons";

export const renderFingerprintImage = (imageUrl: string, parentElementId: string): void => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(imageUrl ?? '', 'image/svg+xml');
    const svgEle = doc.querySelector('svg');
    const parentEle = document.getElementById(parentElementId);
    if (svgEle && !parentEle?.hasChildNodes()) {
        parentEle?.appendChild(svgEle);
    }
};

interface ChipInfo {
    chipColor: 'success' | 'error' | 'primary' | 'warning' | 'default';
    chipLabel: string;
    chipVariant: 'filled' | 'outlined';
    iconComponent: JSX.Element;
}

export const getApplicantChipInfo = (matchType: string): ChipInfo => {
    let chipColor: 'success' | 'error' | 'primary' | 'warning' | 'default' = 'default';
    let chipLabel = matchType;
    let chipVariant: 'filled' | 'outlined' = 'filled';
    let iconComponent = <></>; 

    switch (matchType) {
        case 'strong':
            chipColor = 'default';
            chipLabel = 'Strong Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={StrongFitIcon} fontSize='small' />;
            break;
        case 'strong fit':
            chipColor = 'default';
            chipLabel = 'Strong Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={StrongFitIcon} fontSize='small' />;
            break;
        case 'good':
            chipColor = 'default';
            chipLabel = 'Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'match':
            chipColor = 'default';
            chipLabel = 'Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'fair':
            chipColor = 'default';
            chipLabel = 'Fair Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'none':
            chipColor = 'default';
            chipLabel = 'Weak Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={WeakFitIcon} fontSize='small' />;
            break;
        case 'weak':
            chipColor = 'default';
            chipLabel = 'Weak Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={WeakFitIcon} fontSize='small' />;
            break;
        case 'distortion':
            chipColor = 'default';
            chipLabel = 'Distortion';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={DistortionIcon} fontSize='small' />;
            break;
        default:
            chipColor = 'default';
            chipLabel = 'Unknown';
            break;
    }

    return { chipColor, chipLabel, chipVariant, iconComponent };
};