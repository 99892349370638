
import { IBaseJson, ILabelValueBase } from 'shared/SharedModels';
import { utils, writeFile } from 'xlsx';

export const exportToExcel = (data: IBaseJson[], fileName: string, headers: ILabelValueBase[]) => {
    /*
        Format the data according to the headers and column order before adding to the worksheet.
    */
    const formattedRows: (string | number)[][] = [];
    data.forEach(d => {
        const row: (string | number)[] = [];
        headers.forEach(hd => {
            row.push(d[hd.value]);
        });
        formattedRows.push(row);
    });
    const worksheet = utils.aoa_to_sheet([headers.map(hd => hd.label), ...formattedRows]);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    writeFile(workbook, `${fileName}.xlsx`);
}

export const exportToCSV = (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const exportToExcelFromBlob = (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};
export const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        // Maximum width for the compressed image .
        const maxWidth = 250;

        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new Image();
            img.src = event?.target?.result as string;
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                canvas.width = width;
                canvas.height = height;
                ctx?.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a compressed data URL (JPEG format).
                // Adjust the quality as needed.
                const compressedDataURL = canvas.toDataURL('image/jpeg', 1);

                // Create a Blob from the data URL
                const blob = dataURLtoBlob(compressedDataURL);

                // Create a File object with the Blob
                const compressedFile = new File([blob], 'compressed-image.jpg', { type: 'image/jpeg' });

                // Return the compressed File
                resolve(compressedFile);
            };
        };
        reader.readAsDataURL(file);
    });
}

// Function to convert data URL to Blob
const dataURLtoBlob = (dataURL: string) => {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const byteString = atob(parts[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
}

export const downloadPdfFromUrl = async (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const fileToBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            // @ts-ignore
            const base64String = reader?.result?.split(',')[1];
            resolve(base64String);
        };

        reader.onerror = error => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
}

export const base64ToFile = (base64String: string, fileName: string) => {
    // Split the Base64 string to get mime type and data
    const parts = base64String.split(';base64,');
    const contentType = parts[0].split(':')[1];

    // Extract file extension from the fileName
    const extension = fileName.split('.').pop();

    // Use the file extension to determine the correct MIME type
    const type = extension ? `image/${extension}` : contentType;

    // Decode the Base64 data
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    // Create Blob from Uint8Array
    const blob = new Blob([uInt8Array], { type });

    // Create File from Blob
    const file = new File([blob], fileName, { type });

    return file;
}
