import { Box, Stack, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { PrimaryThemeColor, TextPrimary, WhiteColor } from "shared/SharedStyles/styleConstants";

export const AdminSidebarNavLink = styled(NavLink)(
  ({ theme }) => ({
    display: "flex",
    textDecoration: "none",
    alignItems: "flex-start",
    color: TextPrimary,
    "& .active-link": {
      color: PrimaryThemeColor,
      position: "relative",
      "& span": {
        fontWeight: 600,
      }
    }
  })
);

export const AdminNavLink = styled(Stack)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& a': {
      fontWeight: 400,
      whiteSpace: 'nowrap',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
      textDecoration: 'none',
      color: theme.palette.mode === 'light' ? TextPrimary : WhiteColor,
      '& .MuiSvgIcon-root, svg': {
        color: theme.palette.mode === 'light' ? TextPrimary : WhiteColor
      },
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& .active': {
      '& .MuiSvgIcon-root, svg': {
        color: theme.palette.mode === 'light' ? `${theme.palette.primary.main}` : theme.palette.primary.contrastText,
      },
      backgroundColor: theme.palette.mode === 'light' ? '' : WhiteColor,
      color: theme.palette.mode === 'light' ? `${theme.palette.primary.main}` : theme.palette.primary.contrastText,
    }
  })
);

export const ShNotification = styled(Box)(
  ({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0.2),
  })
);
