import { Box, Paper, styled } from "@mui/material";
import { grey } from "@mui/material/colors";

export const QuestionBox = styled(Box)(
    ({ theme }) => ({
        textAlign: 'center',
        opacity: 0.2,
        width: 'fit-content',
        margin: 'auto',
        zIndex: 1,
        '& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            justifyContent: 'center'
        },
        '&:not(.in-view):hover': {
            '& .MuiTypography-root': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        },
        '& .answer-toggle': {
            display: 'none'
        },
        '&.in-view': {
            opacity: 1,
            '& .MuiTypography-root': {
                pointerEvents: 'none',
                '& svg': {
                    display: 'none'
                }
            },
            '& .answer-toggle': {
                display: 'inline-flex',
                gap: theme.spacing(2),
                flexWrap: 'wrap',
                justifyContent: 'center',
                '& .MuiToggleButton-root': {
                    position: 'relative',
                    border: `1px solid ${grey[200]}`,
                    borderRadius: `${theme.spacing(10)} !important`,
                    color: theme.palette.text.primary,
                    overflow: 'hidden',
                    '& .button-label': {
                        marginLeft: theme.spacing(1)
                    }
                }
            }
        }
    })
);

export const QuestionHighlightOverlay = styled(Paper)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            height: '260px',
            marginTop: '95px',
            width: 'calc(100% - 10px)'
        },
        [theme.breakpoints.up('sm')]: {
            height: '250px',
            marginTop: '100px',
            width: 'calc(100% - 10px)'
        },
        [theme.breakpoints.up('md')]: {
            height: '170px',
            marginTop: '140px',
            width: 'calc(100% - 20px)',
        },
        [theme.breakpoints.up('lg')]: {
            height: '140px',
            marginTop: '160px',
            width: 'calc(100% - 20px)',
        },
        position: 'absolute',
        pointerEvents: 'none',
        margin: '175px 0px 175px 0px',
        borderRadius: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        maxWidth: '900px'
    })
);