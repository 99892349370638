import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn, ShTextField } from 'shared/SharedStyles/ShInputs';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { EmailRegEx } from "shared/constants";
import { IAccountLoginPayload } from "store/slices/auth-v2/auth-v2-model";
import { loginAccount, logoutAccount, setAccountRememberMe } from "store/slices/auth-v2/auth-v2-slice";
import { resetTwoStepAuthState } from 'store/slices/auth-v2/two-step-auth-slice';
import {
    AdminLoginRedirect, AdminLogoutRedirect, CandidateLoginRedirect, CandidateSurveyLogoutRedirect,
    EmployerLoginRedirect, EmployerLogoutRedirect
} from './AuthConstants';
import { IAccountLoginFormMeta, ILoginProps } from "./AuthModel";
import { ForgotPassword } from "./ForgotPassword";
import { SocialLogin } from "./SocialLogin";
import { TwoStepAuthDialog } from './TwoStepAuthDialog';

export const Login = ({ isShowSocialLogin, emailAutoFill, usedIn, getIsForgotPassword }: ILoginProps) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { jobCode } = useParams();
    const socialLoginRef = useRef<any>(null);
    const {
        isRememberMe, isAccountLoggedIn, accountAccess, accountLoginStatus, socialSignUpSource,
        twoStepAuth, accountLoginResponseMsg, logoutType, email } = useAppSelector(state => state.auth.auth);
    const { postingSource } = useAppSelector((state) => state.candidate.applyJobHome);
    const [accountLoginPayload, setAccountLoginPayload] = useState<IAccountLoginPayload>({
        email: isRememberMe && email ? email : emailAutoFill ?? '',
        password: ''
    });
    const [loginFormMeta, setLoginFormMeta] = useState<IAccountLoginFormMeta>({
        emailTouched: false,
        passwordTouched: false,
        emailError: null,
        passwordError: null
    });
    const [isShowForgotPassword, setIsShowForgotPassword] = useState<boolean>(false);
    const [isAuthDialogOpen, setIsAuthDialogOpen] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const updateRememberMe = () => {
        dispatch(setAccountRememberMe({
            isRememberMe: !isRememberMe,
            email: isRememberMe ? accountLoginPayload.email : ''
        }));
    }

    const updateLoginFormValues = (value: string, field: 'email' | 'password') => {
        setAccountLoginPayload({ ...accountLoginPayload, [field]: value });

        // Update form errors only if form is touched.
        if (field === 'email' && loginFormMeta.emailTouched) {
            setLoginFormMeta({
                ...loginFormMeta, emailError: value?.length === 0 ? 'Please enter email' :
                    new RegExp(EmailRegEx).test(value) ? null : 'Please enter a valid email'
            });
        } else if (field === 'password' && loginFormMeta.passwordTouched) {
            setLoginFormMeta({
                ...loginFormMeta, passwordError: value.length === 0 ? 'Please enter password' : null
            });
        }
    }

    const login = (evt: FormEvent<HTMLFormElement>) => {
        // preventDefault to stop browser reload on submit
        evt.preventDefault();

        const { email, password } = accountLoginPayload;

        // set form errors.
        const formMeta = {
            emailError: email?.length === 0 ? 'Please enter email' :
                new RegExp(EmailRegEx).test(email) ? null : 'Please enter a valid email',
            passwordError: password.length === 0 ? 'Please enter password' : null,
            emailTouched: true,
            passwordTouched: true
        }
        setLoginFormMeta(formMeta);

        // proceed to login only if there are no form errors.
        if (formMeta.emailError === null && formMeta.passwordError === null) {
            dispatch(loginAccount({ payload: accountLoginPayload, loginPage: usedIn }));
        }
    }

    const onAuthDialogClose = (isAuthenticated: boolean) => {
        /* 
            If isAuthenticated is true then the user/device is authenticated.
            Set user login state to true, so that 'Authenticate' wrapper function gives access to the application URLs.
        */
        setIsAuthDialogOpen(false);
        if (isAuthenticated) {
            redirectAfterLogin();
        } else {
            dispatch(logoutAccount({ logoutType: 'auto' }));
        }
        // Reset device auth store on dialog close to ensure it works properly when its toggled again.
        dispatch(resetTwoStepAuthState());
    };

    // Open two step dialog
    useEffect(() => {
        if (twoStepAuth?.showTwoStepAuthWindow) {
            setIsAuthDialogOpen(true);
        }
    }, [twoStepAuth?.showTwoStepAuthWindow]);

    const redirectAfterLogin = useCallback(() => {
        switch (accountAccess?.dashboard) {
            case 'employer':
                navigate(EmployerLoginRedirect);
                break;
            case 'admin':
                navigate(AdminLoginRedirect);
                break;
            case 'candidate':
                if (usedIn === 'employer') {
                    navigate(CandidateLoginRedirect);
                }
                break;
            default:
                break;
        }
    }, [accountAccess?.dashboard, navigate, usedIn]);

    const redirectAfterLogout = useCallback(() => {
        switch (usedIn) {
            case 'employer':
                navigate(EmployerLogoutRedirect);
                break;
            case 'admin':
                navigate(AdminLogoutRedirect);
                break;
            case 'candidate_apply_job':
                navigate(`/candidate/apply-job/${jobCode}${postingSource ? ('?ps=' + postingSource) : ''}`);
                break;
            case 'candidate_survey':
                navigate(CandidateSurveyLogoutRedirect);

        }
    }, [jobCode, navigate, postingSource, usedIn])

    // if user is logged in, navigate accordingly to route
    useEffect(() => {
        if (isAccountLoggedIn) {
            redirectAfterLogin();
        }
    }, [isAccountLoggedIn, redirectAfterLogin]);

    // if user is logged out, navigate accordingly to route
    useEffect(() => {
        if (!isAccountLoggedIn && logoutType === 'user') {
            redirectAfterLogout();
        }
    }, [isAccountLoggedIn, logoutType, redirectAfterLogout]);

    // get social login redirect if user signed up with social profile.
    useEffect(() => {
        if (accountLoginStatus === 'failed' && socialSignUpSource) {
            socialLoginRef.current?.handleGetRedirect(socialSignUpSource);
            dispatch(logoutAccount({ logoutType: 'auto' }));
        }
    }, [accountLoginStatus, dispatch, socialSignUpSource]);

    const showForgotPassword = () => {
        setIsShowForgotPassword(true);
        if (getIsForgotPassword) {
            getIsForgotPassword(true);
        }
    }

    return (<>
        <Box maxWidth={500} minWidth={300}>
            {/* Show forgot password or login panels */}
            {isShowForgotPassword ? <>
                <ForgotPassword setIsShowForgotPassword={setIsShowForgotPassword} />
            </> : <>
                <form id="account_login" onSubmit={(e) => login(e)}>
                    <Stack rowGap={2}>
                        {accountLoginStatus === 'failed' && accountLoginResponseMsg &&
                            <ShAlert severity='error' icon={<ErrorIcon />} >
                                {accountLoginResponseMsg}</ShAlert>}
                        {/* {accountLoginStatus === 'failed' && socialSignUpSource &&
                            <ShAlert severity='info'>
                                You have used {socialSignUpSource} to sign up, please click on {socialSignUpSource} icon to login.
                            </ShAlert>
                        } */}
                        <ShTextField size="small" name='email' id="email" label="Email" fullWidth autoFocus
                            variant="outlined" value={accountLoginPayload.email}
                            onChange={e => updateLoginFormValues(e.target.value, 'email')}
                            error={loginFormMeta.emailTouched && loginFormMeta.emailError !== null}
                            helperText={loginFormMeta.emailError} />
                        <ShTextField size="small" name='password' id="password" label="Password" fullWidth
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined" value={accountLoginPayload.password}
                            onChange={e => updateLoginFormValues(e.target.value, 'password')}
                            error={loginFormMeta.passwordTouched && loginFormMeta.passwordError !== null}
                            helperText={loginFormMeta.passwordError}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <IconButton
                                        edge='end' onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />

                        <Stack direction='row' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                            <FormControlLabel control={<Checkbox size='small' checked={isRememberMe}
                                onChange={updateRememberMe} />}
                                label={<Typography variant="body2" whiteSpace='nowrap'>Remember Me</Typography>} />
                            <ShMuiLink noUnderline color='inherit' underline='hover' onClick={showForgotPassword}>
                                <Typography variant="body2" whiteSpace='nowrap'>Forgot Password ?</Typography></ShMuiLink>
                        </Stack>
                        <Stack alignItems='center' rowGap={3} marginTop={1}>
                            <ShGreenBtn type="submit" variant="outlined" disabled={accountLoginStatus === 'pending'}>
                                {accountLoginStatus === 'pending' ? 'Logging in ...' : 'Login'}
                            </ShGreenBtn>
                            {isShowSocialLogin && <>
                                <Typography variant="caption">Or continue with</Typography>
                                <SocialLogin buttonType="icon" isSignUp={false} usedIn={usedIn} forwardedRef={socialLoginRef} />
                            </>}
                            <ShMuiLink variant='body2' alignSelf='center' mb={1} target='_blank'
                                href='https://smoothhiring.com/privacy-policy/'>Privacy Policy</ShMuiLink>
                        </Stack>
                    </Stack>
                </form>
                {twoStepAuth.showTwoStepAuthWindow &&
                    <TwoStepAuthDialog isAuthDialogOpen={isAuthDialogOpen} usedIn={usedIn}
                        onAuthDialogClose={(isAuthenticated: boolean) => onAuthDialogClose(isAuthenticated)} />}
            </>}
        </Box>
    </>)
}