import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Chip, Container, DialogContentText, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { getJobDetails } from "store/slices/employer/jobs/job-info-slice";
import { FacebookMcIcon, GoogleMcIcon, LinkedInMcIcon } from "../../../assets/Icons";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { HtmlRegex } from "../../../shared/constants";
import { DefaultState } from "../../Core/CreateJob/CreateJobConstants";
import { CareerPagesBorderRadius, JobDetailsBox } from "./CareerPages.styled";

export const JobDetailsPage = () => {

    const navigate = useNavigate();
    const { jobId } = useParams();
    const dispatch = useAppDispatch();
    const { getJobDetailsStatus, jobDetailsInInfo } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const { jobStates } = useAppSelector((state) => state.metadata.jobsMeta);

    useEffect(() => {
        if (jobId !== undefined && !isNaN(parseInt(jobId))) {
            dispatch(getJobDetails({ jobId: jobId }));
        }
    }, [dispatch, jobId]);

    return (
        <Box overflow='auto' height='100%'>
            <Container maxWidth='md'>
                <Stack direction='row' alignItems='flex-start' marginY={1} columnGap={1} rowGap={1} flexWrap='wrap'>
                    <IconButton component={RouterLink} to="/careers">
                        <ArrowBackIcon />
                    </IconButton>
                    <Stack rowGap={1} flex={1} minWidth={300}>
                        {getJobDetailsStatus === 'pending' ? <Skeleton variant='rounded' animation='wave' width='100%' height={300} /> :
                            <ShPaper variant="outlined" borderRadius={CareerPagesBorderRadius}>
                                <JobDetailsBox>
                                    <Typography variant="h5">
                                        {jobDetailsInInfo?.name}
                                    </Typography>
                                    <Stack direction='row' alignItems='center' columnGap={1} marginTop={1}>
                                        <PlaceIcon fontSize='small' />
                                        <Typography variant="body2" color="text.secondary">
                                            {`${jobDetailsInInfo?.city}, ${jobStates.find(s => s.id === jobDetailsInInfo?.province_id)?.name_en ??
                                                DefaultState}`}
                                        </Typography>
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <Stack direction='row' columnGap={3} rowGap={1} flexWrap='wrap'>
                                            <ShButton color='primary' variant='contained' onClick={() => navigate('/careers')}>
                                                View All Jobs</ShButton>
                                            <ShButton color='primary' variant='contained'>View Company Website</ShButton>
                                        </Stack>
                                        <Typography marginTop={2} variant="h5" component="div"> Share Job </Typography>
                                        <Stack marginTop={2} direction='row' columnGap={1}>
                                            <GoogleMcIcon />
                                            <FacebookMcIcon />
                                            <LinkedInMcIcon />
                                        </Stack>
                                    </Stack>
                                </JobDetailsBox>
                            </ShPaper>
                        }
                        {jobDetailsInInfo?.keywords?.some(keyword => keyword.length) &&
                            <Box>
                                {getJobDetailsStatus === 'success' &&
                                    <ShPaper variant='outlined' borderRadius={CareerPagesBorderRadius}>
                                        <JobDetailsBox>
                                            <Typography variant="h6" fontWeight='bold' component="div">
                                                Job Requirements
                                            </Typography>
                                            <Stack marginTop={1} direction='row' columnGap={1} rowGap={1} flexWrap='wrap'>
                                                {jobDetailsInInfo?.keywords?.filter(keyword => keyword.length).map(keyword =>
                                                    <Chip key={keyword} color='primary' variant='outlined' label={keyword} />)}
                                            </Stack>
                                        </JobDetailsBox>
                                    </ShPaper>
                                }
                            </Box>
                        }
                        <Box>
                            {getJobDetailsStatus === 'pending' ? <Skeleton variant='rounded' animation='wave' width='100%' height={500} /> :
                                <ShPaper variant='outlined' borderRadius={CareerPagesBorderRadius}>
                                    <JobDetailsBox>
                                        <Typography variant="h6" fontWeight='bold' component="div">
                                            Job Description
                                        </Typography>
                                        {HtmlRegex.test(jobDetailsInInfo?.description ?? '') ? parse(jobDetailsInInfo?.description ?? '') :
                                            <DialogContentText whiteSpace='break-spaces' color='inherit'>
                                                {jobDetailsInInfo?.description}
                                            </DialogContentText>}
                                        <Box display='flex' justifyContent='end'><ShGreenBtn borderRadius={20} onClick={() =>
                                            navigate(`/candidate/apply-job/${jobDetailsInInfo?.code}`)}>
                                            Apply</ShGreenBtn>
                                        </Box>
                                    </JobDetailsBox>
                                </ShPaper>
                            }
                        </Box>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
};