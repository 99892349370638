import { Stack, TextField, Typography } from "@mui/material";
import { IZoomMeetComponent } from "Modules/Core/Interviews/InterviewsModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { CRUD } from "shared/SharedModels";
import { ShButton, ShWordpressButton } from "shared/SharedStyles/ShInputs";
import { createZoomMeet, deleteZoomMeet, updateZoomMeet } from "store/slices/employer/applicants/applicant-actions-slice";

export const ZoomMeet = ({ zoomMeet, setZoomMeet, interviewDetails, applicantInfo,
    jobId, interviewInfo }: IZoomMeetComponent) => {

    const dispatch = useAppDispatch();
    const { zoomMeetCreationStatus, zoomMeetUpdateStatus, zoomMeetDeleteStatus } = useAppSelector((state) => state.employer.applicants.applicantActions);

    const handleZoomMeet = (mode: CRUD) => {
        const zoomMeetPayload = {
            ...zoomMeet,
            from_time: interviewDetails.from_time,
            schedule_date: interviewDetails.schedule_date,
            topic: interviewDetails.note,
            zoom_meeting_id: interviewInfo?.zoom_meeting_id

        };
        if ((applicantInfo?.candidateEmployerJobId || interviewInfo?.candidate_id) && jobId) {
            switch (mode) {
                case 'create':
                    dispatch(createZoomMeet({ payload: zoomMeetPayload }));
                    break;
                case 'update':
                    dispatch(updateZoomMeet({ zoomId: zoomMeetPayload.zoom_meeting_id, payload: zoomMeetPayload }));
                    break;
                case 'delete':
                    dispatch(deleteZoomMeet({ zoomId: zoomMeetPayload.zoom_meeting_id }));
                    break;
                default:
                    break;
            }
        }
    };

    return (<>
        <Typography variant='body1' fontWeight={600}>Setup Zoom Interview</Typography>
        <Stack direction='row' flexWrap='wrap' columnGap={2} rowGap={1}>
            <TextField id="agenda" label="Agenda" variant="outlined"
                size='small' value={zoomMeet.agenda} onChange={e => setZoomMeet({ ...zoomMeet, agenda: e.target.value })} />
            <TextField id="zoom_pass" label="Password" variant="outlined" multiline
                size='small' value={zoomMeet.password} onChange={e => setZoomMeet({ ...zoomMeet, password: e.target.value?.trim() })} />
            {zoomMeet?.zoom_meeting_id !== undefined ? <>
                <Stack direction='row' columnGap={1} alignItems='center'>
                    <ShWordpressButton onClick={() => handleZoomMeet('update')}
                        disabled={zoomMeetUpdateStatus === 'pending' || zoomMeetDeleteStatus === 'pending'}>Update</ShWordpressButton>
                    <ShButton color="error" variant="contained" disableElevation
                        disabled={zoomMeetUpdateStatus === 'pending' || zoomMeetDeleteStatus === 'pending'}
                        onClick={() => handleZoomMeet('delete')}>Delete</ShButton>
                </Stack>
            </> : <>
                {zoomMeet?.zoom_meeting_id === undefined && <ShWordpressButton size="small" color='success' variant="contained"
                    disabled={zoomMeetDeleteStatus === 'pending' ||
                        zoomMeetCreationStatus === 'pending'} onClick={() => handleZoomMeet('create')}>
                    <Typography variant="body2">Create Zoom Meet</Typography></ShWordpressButton>}
            </>
            }
        </Stack>
    </>);
}