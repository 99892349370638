import { AdminJobFeaturesTypes, AdminJobReviewTypes, AdminJobTypes } from "Modules/Core/JobsTs/JobsModel";
import { ITableHeadersBase } from "shared/SharedModels";

export const AdminJobsTableHeaders: (ITableHeadersBase<AdminJobTypes>)[] = [
  { columName: "id", label: "ID" },
  { columName: "hiring_coach_name", label: "Hiring Coach" },
  { columName: "submitted", label: "Submitted" },
  { columName: "name", label: "Job" },
  { columName: "company_name", label: "Company | SDFC Link" },
  { columName: "location", label: "Location" },
  { columName: "review_state", label: "Status" },
];

export const AdminJobsTableCellOrder: string[] = [
  "id",
  "hiring_coach_name",
  "submitted",
  "name",
  "company_name",
  "location",
  "review_state",
]

export const AdminJobReviewTableHeaders: (ITableHeadersBase<AdminJobReviewTypes>)[] = [
  { columName: "name", label: "Name" },
  { columName: "link", label: "Link" },
  { columName: "html", label: "HTML" },
]

export const AdminJobFeaturesTableHeaders: (ITableHeadersBase<AdminJobFeaturesTypes>)[] = [
  { columName: "name", label: "Name" },
  { columName: "status", label: "Status" },
]

export const AdminJobReviewCellOrder: string[] = ["name", "link", "html"];
export const AdminJobFeaturesCellOrder: string[] = ["name", "status"];