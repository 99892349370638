import { Box, Stack, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { PaperVariant } from "shared/SharedStyles/styleConstants";

export const ErrorPage = () => {

    return (<>
        <Stack height='100vh' justifyContent='center' alignItems='center' padding={2}>
            <Paper variant={PaperVariant}>
                <Stack alignItems='center' justifyContent='center' rowGap={6} padding={5}>
                    <Box maxWidth={250}>
                        <ShLogo />
                    </Box>
                    <Box textAlign='center'>
                        <Typography variant="h1">404</Typography>
                        <Typography variant="subtitle1">We couldn't find the page you were looking for.</Typography>
                    </Box>
                    <ShButton variant="contained" color="primary" onClick={() => window.history.back()}>Back</ShButton>
                </Stack>
            </Paper>
        </Stack>
    </>);
};