import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Avatar,
    Box,
    Chip,
    Collapse,
    LinearProgress,
    Stack,
    TableCell,
    TableHead,
    Tooltip,
    Typography,
} from '@mui/material';
import TableBody from "@mui/material/TableBody";
import TableSortLabel from '@mui/material/TableSortLabel';
import { ApplicantsHeaders } from 'Modules/Core/Applicants/ApplicantsConstants';
import { ApplicantComment } from 'Modules/Core/Applicants/ApplicantsList/ApplicantComment';
import { ApplicantRating } from 'Modules/Core/Applicants/ApplicantsList/ApplicantRating';
import { ApplicantSummary } from 'Modules/Core/Applicants/ApplicantsList/ApplicantSummary';
import { StagesToggler } from 'Modules/Core/Applicants/ApplicantsList/StagesToggler';
import { IApplicantRowModel, IApplicantsTable } from 'Modules/Core/Applicants/ApplicantsModel';
import { stringToColor } from 'Modules/Core/Dashboard/Dashboard.styled';
import moment from "moment";
import { CollapsibleTableRow, RowSelectCell, ShTable, ShTableContainer, StyledTableRow, ThemeColorDivider } from 'shared/SharedStyles/ShDataDisplay';
import { ShCheckbox } from 'shared/SharedStyles/ShInputs';
import { getApplicantChipInfo } from '../ApplicantHelpers';

export const renderMatchChip = (matchType: string) => {
    const { chipColor, chipLabel, chipVariant, iconComponent } = getApplicantChipInfo(matchType);
    return <Chip size='small' label={chipLabel} color={chipColor} variant={chipVariant} icon={iconComponent} />;
};

// Table Row component
const Row = ({ applicant, selectedApplicants, selectApplicant, indx, applicantsSummary, getApplicantSummary,
    goToApplicantProfile, changeStage, isAtsPurchased }: IApplicantRowModel) => {

    return (<>
        <StyledTableRow onClick={e => getApplicantSummary(applicant.candidateEmployerJobId)} cursor='pointer'>
            <RowSelectCell onClick={(e) => e.stopPropagation()}>
                <ShCheckbox id={'chk_' + indx} checked={selectedApplicants?.includes(applicant.candidateEmployerJobId)}
                    size='small' onChange={() => selectApplicant(applicant.candidateEmployerJobId)} />
            </RowSelectCell>
            <TableCell>
                <Stack direction="row" spacing={{ lg: 3, md: 2, sm: 1, xs: 0.5 }} alignItems="center">

                    <Avatar style={{ backgroundColor: stringToColor(`${applicant.fullName}`) }}
                        className='avatar' alt={applicant.fullName} src={`user-avatar-to-be-loaded-here`} />
                    <Stack>
                        <Typography variant="body2" fontWeight='bold'>{applicant.fullName}</Typography>
                        <Typography variant="caption">{applicant.email}</Typography>
                    </Stack>
                    <Tooltip title="View Applicant's details" onClick={e => e.stopPropagation()}>
                        <Box component='span' onClick={(e) => { e.stopPropagation(); goToApplicantProfile(applicant) }}>
                            <ContactPageIcon fontSize='small' color='primary' className='on-hover' />
                        </Box>
                    </Tooltip>
                </Stack>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{moment(applicant.applicationCompletedAt).format("MMM DD, YYYY")}</Typography>
            </TableCell>
            <TableCell>
                <ApplicantRating className='dsk-only-inline-flex' applicant={applicant} usedIn='list' />
            </TableCell>
            {/* Show this cell only for non ats jobs */}
            {!isAtsPurchased &&
                <TableCell>
                    <Typography component={'div'} variant="body2">{renderMatchChip(applicant.matchType)}</Typography>
                </TableCell>}
            <TableCell>
                <StagesToggler applicant={applicant} changeStage={changeStage} />
            </TableCell>
            <TableCell>
                <ApplicantComment applicantId={applicant.candidateEmployerJobId} />
            </TableCell>
        </StyledTableRow>
        <CollapsibleTableRow>
            <TableCell colSpan={6}>
                {applicantsSummary[applicant.candidateEmployerJobId]?.getSummaryStatus === 'pending' && <LinearProgress />}
                {applicantsSummary[applicant.candidateEmployerJobId]?.getSummaryStatus === 'success' &&
                    <Collapse in={applicant.isExpanded} timeout="auto" unmountOnExit>
                        <ApplicantSummary changeStage={changeStage} applicant={applicant} key={applicant.candidateEmployerJobId}
                            goToApplicantProfile={goToApplicantProfile} isAtsPurchased={isAtsPurchased}
                            summary={applicantsSummary[applicant.candidateEmployerJobId]}
                            applicantId={applicant.candidateEmployerJobId} />
                        <ThemeColorDivider />
                    </Collapse>
                }
            </TableCell>
        </CollapsibleTableRow>
    </>);
}

export const ApplicantsTableView = ({ applicantsList, applicantsSummary, isAtsPurchased, selectedApplicants,
    selectApplicant, sortList, sortParams, getApplicantSummary, goToApplicantProfile, changeStage }: IApplicantsTable) => {

    // TODO Implement a auto-reject based on threshold 
    // useEffect(() => {
    //     const rejectWeakFitCandidates = () => {
    //         applicantsList.forEach(applicant => {
    //             if (applicant.matchType === 'none' && applicant.jobStage !=='rejected') {
    //                 changeStage('rejected', applicant.candidateEmployerJobId);
    //             }
    //         });
    //     };

    //     rejectWeakFitCandidates();
    // }, [applicantsList, changeStage]);

    return (<>
        <ShTableContainer>
            <ShTable size="small">
                <TableHead>
                    <StyledTableRow cursor='pointer'>
                        <TableCell className='select-header'>
                        </TableCell>
                        {/* Sortable constant table headers */}
                        {ApplicantsHeaders.map((headCell) => (
                            <TableCell key={headCell.columName}
                                sortDirection={sortParams.orderBy === headCell.columName ? sortParams.order : false}>
                                {headCell.columName === 'rating' ? ( // Apply tooltip and sorting label for rating col
                                    <Typography variant="body2" display='flex' alignItems='center'>
                                        <TableSortLabel
                                            active={sortParams.orderBy === headCell.columName}
                                            direction={sortParams.orderBy === headCell.columName ? sortParams.order : 'asc'}
                                            onClick={() => { sortList(headCell.columName, sortParams.order === 'asc' ? 'desc' : 'asc') }}
                                        >
                                            {headCell.label}
                                            &nbsp;
                                            <Tooltip title="Recruiters can rate their candidates based on interviews, qualifications, etc."
                                                placement="top"><InfoOutlinedIcon fontSize="small" color='disabled' />
                                            </Tooltip>
                                        </TableSortLabel>
                                    </Typography>
                                ) : (
                                    <TableSortLabel
                                        active={sortParams.orderBy === headCell.columName}
                                        direction={sortParams.orderBy === headCell.columName ? sortParams.order : 'asc'}
                                        onClick={() => { sortList(headCell.columName, sortParams.order === 'asc' ? 'desc' : 'asc') }}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                        {/* Show this column only for non ats jobs */}
                        {!isAtsPurchased &&
                            <TableCell>
                                <Stack direction='row' alignItems='center'>
                                    <Typography variant="body2">
                                        Candidate Fit
                                        &nbsp;
                                    </Typography>
                                    <Tooltip title="This is your candidates overall match with your posted job." placement="right">
                                        <InfoOutlinedIcon fontSize="small" color='disabled' />
                                    </Tooltip>
                                </Stack>
                            </TableCell>}
                        <TableCell>Stage</TableCell>
                        <TableCell></TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {applicantsList.map((aplcnt, index) => (
                        // Row component holds both applicant and collapsible summary rows.
                        <Row key={index} indx={index} applicant={aplcnt} goToApplicantProfile={goToApplicantProfile} applicantsSummary={applicantsSummary}
                            selectedApplicants={selectedApplicants} getApplicantSummary={getApplicantSummary} isAtsPurchased={isAtsPurchased}
                            selectApplicant={(applcntId: number) => selectApplicant(applcntId)} changeStage={changeStage} />
                    ))}
                </TableBody>
            </ShTable>
        </ShTableContainer>
    </>);
};