import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FeedIcon from '@mui/icons-material/Feed';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import VerifiedIcon from '@mui/icons-material/Verified';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Box, CardContent, Chip, Grid, Grow, Stack, Typography, darken, lighten } from '@mui/material';
import { IsMdScreen, IsSmScreen } from 'helpers/hooks';
import { Link as RouterLink } from 'react-router-dom';
import { ShButton, ShWordpressButton } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryWordpressThemeColor, ShGreen, WhiteColor } from 'shared/SharedStyles/styleConstants';
import { getResourcesRedirect } from 'shared/utils';
import { ResourceTypography, TemplateHomesStack } from './Resources.styled';
import { EXPLORE_SMOOTH_HIRING, EXPLORE_SMOOTH_HIRING_DETAILS, HIRE_BEST_CANDIDATES, HIRE_BEST_CANDIDATES_DETAILS } from './ResourcesConstants';
import { Helmet } from 'react-helmet';

export const ResourcesHome = () => {

    const isSmScreen = IsSmScreen();
    const isMdScreen = IsMdScreen();

    return (
        <>
            <Helmet>
                <meta name="google-site-verification" content="YXtpRnHiDI-gqxrKJSOnrCJDLQqZYcCmplqpHHtzKYQ" />
                {/* Google Tag Manager */}
                <script>
                    {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-N46J9TNH');
                    `}
                </script>
                <title>Hiring Resources & Guides | SmoothHiring</title>
                <meta name="description" content="Access top resources, guides, and best practices for efficient recruitment and hiring strategies on SmoothHiring. Boost your hiring success today"/>
            </Helmet>
            <ShContainer maxWidth='lg' margin="auto">
                <Stack paddingTop={4} paddingBottom={2}>
                    <ShPaper borderRadius={15} elevation={6} >
                        <TemplateHomesStack backgroundColor={WhiteColor}>
                            <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                                <Stack justifyContent='center' padding={isSmScreen ? 0 : 2}>
                                    <Typography textAlign="center" gutterBottom variant="body2" fontWeight={700}
                                        color={PrimaryWordpressThemeColor}>
                                        Free HR Resources | Crafted by Industry Experts
                                    </Typography>
                                    {isSmScreen ?
                                        <Typography component="h1" textAlign="center" gutterBottom fontSize={15} fontWeight={700} >
                                            Recruiting Resources Library
                                        </Typography>
                                        :
                                        <Typography component="h1" textAlign="center" gutterBottom fontSize={25} fontWeight={700}>
                                            Recruiting Resources Library
                                        </Typography>}
                                    {isSmScreen ?
                                        <Typography textAlign="center" fontSize={13}>
                                            These tools simplify the creation of compelling job descriptions,
                                            freeing you to focus on what counts: finding top talent and forming a robust team.
                                            Our resources ensure your job postings stand out, attract qualified candidates
                                            and streamline your hiring process for greater efficiency and effectiveness.</Typography>
                                        :
                                        <Typography textAlign="center" fontSize={15}>
                                            These tools simplify the creation of compelling job descriptions,
                                            freeing you to focus on what counts: finding top talent and forming a robust team.
                                            Our resources ensure your job postings stand out, attract qualified candidates
                                            and streamline your hiring process for greater efficiency and effectiveness.</Typography>}
                                    <Stack paddingTop={3} justifyContent='center' paddingBottom={1}
                                        direction={isSmScreen ? 'column' : 'row'} spacing={1}>
                                        <Chip icon={<VerifiedIcon />} label="Save time and Effort" color="primary" variant='outlined' />
                                        <Chip icon={<VerifiedIcon />} label="Reduce Hiring Costs" color="primary" variant='outlined' />
                                        <Chip icon={<VerifiedIcon />} label="Hire Effortlessly" color="primary" variant='outlined' />
                                    </Stack>
                                </Stack>
                            </Grow>
                        </TemplateHomesStack>
                    </ShPaper>
                </Stack>
                <Typography fontWeight={'bold'} padding={2} variant="h6">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <AutoAwesomeIcon color='primary' /> &nbsp;&nbsp; AI Generation Tools
                    </Box>
                </Typography>
                <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2} padding={2}>
                    <TemplateHomesStack marginBottom={4} marginTop={4}>
                        <Stack alignItems='center' textAlign='center' color={darken(PrimaryWordpressThemeColor, 0.3)} spacing={2}>
                            <Typography fontWeight='bold' variant="h5" noWrap>
                                Interview Kit Generator
                            </Typography>
                            <Typography variant='body2' maxWidth={'300px'}>
                                Create interview kits with questions & answers, designed to help you conduct organized interviews.
                            </Typography>
                            <ShButton size='small' variant='contained' endIcon={<NearMeIcon />}
                                component={RouterLink} to={getResourcesRedirect('aiInterviewKit')}>
                                Generate Interview Kit
                            </ShButton>
                        </Stack>
                    </TemplateHomesStack>
                    <TemplateHomesStack marginBottom={4} marginTop={4} backgroundColor={lighten(ShGreen, 0.7)}>
                        <Stack alignItems='center' textAlign='center' color={darken(ShGreen, 0.4)} spacing={2}>
                            <Typography fontWeight='bold' variant="h5" noWrap>
                                Job Description Generator
                            </Typography>
                            <Typography variant='body2' maxWidth={'300px'}>
                                Create customizable and optimized job descriptions quickly, designed to attract top talent.
                            </Typography>
                            <ShWordpressButton size='small' variant='contained' color='success' endIcon={<NearMeIcon />}
                                component={RouterLink} to={getResourcesRedirect('aiJobDescription')}>
                                Generate Job Descriptions
                            </ShWordpressButton>
                        </Stack>
                    </TemplateHomesStack>
                </Stack>
                <Typography fontWeight={'bold'} variant="h6" paddingTop={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <WysiwygIcon color='primary' /> &nbsp;&nbsp; HR Templates
                    </Box>
                </Typography>
                <Grid container spacing={2}>
                <Grid item xs={12} md={5} display="flex" flexDirection="column">
                    <TemplateHomesStack marginTop={4} color={PrimaryWordpressThemeColor} display="flex"
                        flexDirection="column" flexGrow={1}>
                            <Stack alignItems="center" textAlign="center" color={darken(PrimaryWordpressThemeColor, 0.3)}
                                spacing={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h5" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Job Description Templates
                                </Typography>
                                <Typography variant="body2" maxWidth={'300px'}>
                                    Hire faster with our 500+ hiring templates, primed and ready to go.
                                </Typography>
                                <ShButton size="small" variant="contained" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getResourcesRedirect('jobTemplatesHome')}>
                                    See Job Description Templates
                                </ShButton>
                            </Stack>
                        </TemplateHomesStack>
                    </Grid>
                    <Grid item xs={12} md={5} display="flex" flexDirection="column">
                        <TemplateHomesStack marginTop={4} color={PrimaryWordpressThemeColor} flexGrow={1}>
                            <Stack alignItems="center" textAlign="center" color={darken(PrimaryWordpressThemeColor, 0.3)}
                                spacing={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h5" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Offer Letter Templates
                                </Typography>
                                <Typography variant="body2" maxWidth={'300px'}>
                                    Streamline your hiring process with our collection of offer letter templates.
                                </Typography>
                                <ShButton size="small" variant="contained" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getResourcesRedirect('offerLetterTemplates')}>
                                    Create Offer Template
                                </ShButton>
                            </Stack>
                        </TemplateHomesStack>
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" flexDirection="column">
                        <TemplateHomesStack marginTop={4} color={PrimaryWordpressThemeColor}
                            backgroundColor={lighten(ShGreen, 0.7)} flexGrow={1}>
                            <Stack alignItems="center" textAlign="center" color={darken(ShGreen, 0.3)} spacing={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h5" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Policy Templates
                                </Typography>
                                <Typography variant="body2" maxWidth={'300px'}>
                                    Streamline your policy creation with our library of policy templates.
                                </Typography>
                                <ShWordpressButton size="small" variant="contained" color="success" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getResourcesRedirect('policyTemplatesHome')}>
                                    View Policy Templates
                                </ShWordpressButton>
                            </Stack>
                        </TemplateHomesStack>
                    </Grid>
                </Grid>
                <Grow in={true} timeout={3000} mountOnEnter unmountOnExit>
                    <Stack direction={isSmScreen ? 'column' : 'row'} paddingTop={6} spacing={2}
                        justifyContent="center" alignItems="center">
                        <ShPaper variant="outlined" borderRadius={20}>
                            <CardContent>
                                <Stack marginBottom={2} paddingLeft={2}>
                                    <FeedIcon color='info' fontSize='medium' />
                                </Stack>
                                <Typography variant="h6" marginBottom={2} paddingLeft={2}>{EXPLORE_SMOOTH_HIRING}</Typography>
                                <Typography variant="body1" paddingLeft={2}>{EXPLORE_SMOOTH_HIRING_DETAILS}</Typography>
                                <Stack direction={isSmScreen ? 'column' : 'row'} paddingLeft={2} paddingTop={3} spacing={2}>
                                    <ShButton variant="text" color="primary" size='medium'
                                        href='https://calendly.com/admin-1ue9/30min?month=2024-05'> Schedule a free Demo {'>'}
                                    </ShButton>
                                </Stack>
                            </CardContent>
                        </ShPaper>

                        <ShPaper variant="outlined" borderRadius={20} style={{ marginTop: isSmScreen ? '20px' : 0 }}>
                            <CardContent>
                                <Stack marginBottom={2} paddingLeft={2}>
                                    <PersonSearchIcon color='info' fontSize='medium' />
                                </Stack>
                                <Typography variant="h6" paddingLeft={2} marginBottom={2}>{HIRE_BEST_CANDIDATES}</Typography>
                                <Typography variant="body1" paddingLeft={2}>{HIRE_BEST_CANDIDATES_DETAILS}</Typography>
                                <Stack direction={'row'} paddingLeft={2} paddingTop={3}>
                                    <ShButton href='https://app.smoothhiring.com' color="primary"> Learn more
                                        <KeyboardArrowRightOutlinedIcon /></ShButton>
                                </Stack>
                            </CardContent>
                        </ShPaper>
                    </Stack>
                </Grow>
            </ShContainer>

            <ShContainer margin="auto" maxWidth="lg">
                <TemplateHomesStack color="white" spacing={2} marginBottom={10} marginTop={10}
                    backgroundColor={PrimaryWordpressThemeColor}>
                    <ResourceTypography gutterBottom variant="h6" fontWeight="bold">
                        Need to Advertise your Job?
                    </ResourceTypography>
                    <ResourceTypography gutterBottom variant="subtitle1">
                        LinkedIn, Talent.com, career page - place your job on multiple platforms for FREE with just one click.
                    </ResourceTypography>
                    <ShWordpressButton href="https://app.smoothhiring.com/employers/new" size="large" color="primary"
                        variant="contained" endIcon={<NearMeIcon />}>
                        <Typography fontWeight="bold">Post a Free Job &nbsp;</Typography>
                    </ShWordpressButton>
                </TemplateHomesStack>
            </ShContainer>
        </>
    );
};

export default ResourcesHome;
