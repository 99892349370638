import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Drawer,
    Grid, List, MenuItem, Popover, Stack, Typography,
} from '@mui/material';
import { IsMdScreen } from 'helpers/hooks';
import React, { useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { ShLogo } from 'shared/SharedComponents/ShLogo';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { getResourcesRedirect } from 'shared/utils';
import { ShListItem } from './Resources.styled';

export const MegaMenu = () => {
    const [resourcesAnchorEl, setResourcesAnchorEl] = useState<null | HTMLElement>(null);
    const [productAnchorEl, setProductAnchorEl] = useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [resourcesExpanded, setResourcesExpanded] = useState<boolean>(false);
    const [productExpanded, setProductExpanded] = useState<boolean>(false);
    const isMobile = IsMdScreen();

    const handleResourcesPopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
        setResourcesAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleResourcesPopoverClose = () => {
        setResourcesAnchorEl(null);
    };

    const handleProductPopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
        setProductAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleProductPopoverClose = () => {
        setProductAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const resourcesOpen = Boolean(resourcesAnchorEl);
    const productOpen = Boolean(productAnchorEl);

    const MobileMenu = (
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
            <List component={ShPaper}>
                <Box marginLeft={2} height='50px' width='150px' >
                    <ShLogo />
                </Box>
                <ShListItem to={getResourcesRedirect('resourcesHome')} onClick={handleDrawerToggle} primary={"Home"} />
                <Accordion elevation={0} expanded={resourcesExpanded} onChange={() => setResourcesExpanded(!resourcesExpanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Resources</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List disablePadding  >
                            <ShListItem to={getResourcesRedirect('aiInterviewKit')} onClick={handleDrawerToggle} primary="Interview Kit Generator" />
                            <ShListItem to={getResourcesRedirect('jobTemplatesHome')} onClick={handleDrawerToggle} primary="Description Templates" />
                            <ShListItem to={getResourcesRedirect('offerLetterTemplates')} onClick={handleDrawerToggle} primary="Offer Letter Templates" />
                            {/* <ShListItem to={getResourcesRedirect('emailTemplates')} onClick={handleDrawerToggle} primary="Email Templates"/> */}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} expanded={productExpanded} onChange={() => setProductExpanded(!productExpanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Product</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List disablePadding style={{ textDecoration: 'none' }}>
                            <ShListItem to='https://smoothhiring.com/core-features/' onClick={handleDrawerToggle} primary="All Features" />
                            <ShListItem to="https://smoothhiring.com/job-distribution/" onClick={handleDrawerToggle} primary="Applicant Tracking System" />
                            <ShListItem to="https://smoothhiring.com/applicant-tracking/" onClick={handleDrawerToggle} primary="Applicant Tracking System" />
                            <ShListItem to="https://smoothhiring.com/predictive-analytics/" onClick={handleDrawerToggle} primary="Predictive Analytics" />
                            <ShListItem to="https://smoothhiring.com/onboarding/" onClick={handleDrawerToggle} primary="Onboarding" />
                            <ShListItem to="https://smoothhiring.com/careers-pages/" onClick={handleDrawerToggle} primary="Careers Page" />
                        </List>
                    </AccordionDetails>
                </Accordion>
                <ShListItem to='https://smoothhiring.com/pricing/' onClick={handleDrawerToggle} primary="Pricing" />
                <ShListItem to="https://app.smoothhiring.com/accounts/login" onClick={handleDrawerToggle} primary="Log In" />
                <ShListItem to="https://app.smoothhiring.com/employers/new" onClick={handleDrawerToggle} primary="Sign Up" />

            </List>
        </Drawer>
    );

    const DesktopMenu = (
        <Stack direction="row" spacing={2} alignItems="center">
            <ShButton size='small' component={RouterLink} to={getResourcesRedirect('resourcesHome')}
                variant="text" onClick={handleResourcesPopoverClose}>
                <Typography color='black' fontSize={18}>
                    Home
                </Typography>
            </ShButton>

            {/* Pricing */}
            <ShButton size='small' href="https://smoothhiring.com/pricing/" variant="text">
                <Typography color='black' fontSize={18}>
                    Pricing
                </Typography>
            </ShButton>

            {/* Product */}
            <Stack>
                <ShButton size='small' aria-owns={productOpen ? 'product-popover' : undefined} aria-haspopup="true" onClick={handleProductPopoverToggle} variant="text" endIcon={productOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                    <Typography color='black' fontSize={18}>
                        Product
                    </Typography>
                </ShButton>
                <Popover id="product-popover" anchorEl={productAnchorEl} open={productOpen} onClose={handleProductPopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    disableRestoreFocus>
                    <Grid container spacing={15} padding={2}>
                        <Grid item xs={6}>
                            <MenuItem component={Link} to="https://smoothhiring.com/core-features/" onClick={handleProductPopoverClose}>
                                All Features
                            </MenuItem>
                            <MenuItem component={Link} to="https://smoothhiring.com/job-distribution/" onClick={handleProductPopoverClose}>
                                Automatic Job Distribution
                            </MenuItem>
                            <MenuItem component={Link} to="https://smoothhiring.com/applicant-tracking/" onClick={handleProductPopoverClose}>
                                Applicant Tracking System
                            </MenuItem>
                        </Grid>
                        <Grid item xs={6}>
                            <MenuItem component={Link} to="https://smoothhiring.com/predictive-analytics/" onClick={handleProductPopoverClose}>
                                Predictive Analytics
                            </MenuItem>
                            <MenuItem component={Link} to="https://smoothhiring.com/onboarding/" onClick={handleProductPopoverClose}>
                                Onboarding
                            </MenuItem>
                            <MenuItem component={Link} to="https://smoothhiring.com/careers-pages/" onClick={handleProductPopoverClose}>
                                Careers Page
                            </MenuItem>
                        </Grid>
                    </Grid>
                </Popover>
            </Stack>

            {/* Resources */}
            <Stack>
                <ShButton size='small' aria-owns={resourcesOpen ? 'resources-popover' : undefined} aria-haspopup="true" onClick={handleResourcesPopoverToggle} variant="text" endIcon={resourcesOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                    <Typography color='black' fontSize={18}>
                        Resources
                    </Typography>
                </ShButton>
                <Popover id="resources-popover" anchorEl={resourcesAnchorEl} open={resourcesOpen} onClose={handleResourcesPopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    disableRestoreFocus>
                    <Grid container spacing={6} padding={2}>
                        <Grid item xs={6}>
                            <Typography fontWeight={'bold'} paddingLeft={2} variant="h6">AI Tools</Typography>
                            <MenuItem component={Link} to={getResourcesRedirect('aiJobDescription')} onClick={handleResourcesPopoverClose}>
                                Job Description Generator
                            </MenuItem>
                            <MenuItem component={Link} to={getResourcesRedirect('aiInterviewKit')} onClick={handleResourcesPopoverClose}>
                                Interview Kit Generator
                            </MenuItem>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={'bold'} paddingLeft={2} variant="h6">Templates</Typography>
                            <MenuItem component={Link} to={getResourcesRedirect('jobTemplatesHome')} onClick={handleResourcesPopoverClose}>
                                Description Templates
                            </MenuItem>
                            <MenuItem component={Link} to={getResourcesRedirect('offerLetterTemplates')} onClick={handleResourcesPopoverClose}>
                                Offer Letter Templates
                            </MenuItem>
                            <MenuItem component={Link} to={getResourcesRedirect('emailTemplates')} onClick={handleResourcesPopoverClose}>
                                Email Templates
                            </MenuItem>
                        </Grid>
                    </Grid>
                </Popover>
            </Stack>

        </Stack>
    );

    return (
        <>
            {isMobile ? (
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <ShButton color='inherit' aria-label="menu" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </ShButton>
                    {MobileMenu}
                </Box>
            ) : (
                DesktopMenu
            )}
        </>
    );
};
