import dayjs from "dayjs";

/*
* Creates to and from times based on an intervl
*/
export const generateTimeIntervals = (stepMinutes:number) => {
    const intervals = [];
    let start = dayjs().startOf('day');
    const end = dayjs().endOf('day');
    
    while (start <= end) {
        const startTime = start.format('h:mm A');
        const endTime = start.add(stepMinutes, 'minute').format('h:mm A');
        intervals.push(`${startTime} - ${endTime}`);
        start = start.add(stepMinutes, 'minute');
    }
    
    return intervals;
    };

// Get the  interval to the current time
export const getNoonInterval = (intervals: string[]) => {
    const noon = dayjs().startOf('day').add(12, 'hour').format('h:mm A');
    const closestInterval = intervals.find(interval => interval.startsWith(noon)) || intervals[0];
    return closestInterval;
    };