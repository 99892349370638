import { Autocomplete, Box, Container, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "helpers/hooks"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { AutocompleteLoaders, AutocompletePopoverProps, ShButton } from "shared/SharedStyles/ShInputs"
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants"
import { LovNoMatchesText } from "shared/constants"
import { getIndustries, getJobTitles } from "store/slices/meta-data/jobs-meta-slice"
import { CandidateLoginRedirect } from "../AuthV2/AuthConstants"
import { ICompareJobPayload } from "./CandidateModel"

export const CandidateCareerTools = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { industries, getIndustriesStatus, getJobTitlesStatus, jobTitles } = useAppSelector(state => state.metadata.jobsMeta);

    const [jobComparePayload, setJobComparePayload] = useState<ICompareJobPayload>({
        compareJobId: 0,
        compareJobIndustryId: 0,
        currentJobId: 0,
        currentJobIndustryId: 0
    })

    const loadIndustries = () => {
        // Load industries only once when the drop down is toggled for the first time.
        if (industries?.length === 0) {
            dispatch(getIndustries());
        }
    };

    const loadJobTitles = () => {
        // Load industries only once when the drop down is toggled for the first time.
        if (jobTitles?.length === 0) {
            dispatch(getJobTitles());
        }
    };

    return (<>
        <Container maxWidth='lg'>
            <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                <Typography variant='h6' color={PrimaryThemeColor} gutterBottom>Career tools</Typography>
                <Paper variant="outlined">
                    <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                        <Grid container spacing={{ xs: 1, sm: 1, md: 3, lg: 4 }} alignItems='center' marginBottom={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Stack rowGap={3}>
                                    <Stack rowGap={2}>
                                        <Typography variant="body2" fontWeight={600} gutterBottom>What is your current job ?</Typography>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <Typography>Industry</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9} lg={9}>
                                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='curIndustry'
                                                    ListboxProps={AutocompletePopoverProps} options={industries} onOpen={loadIndustries}
                                                    getOptionLabel={(option) => option.industry_translation?.name || ''}
                                                    value={industries?.find(industry => industry.id === jobComparePayload.currentJobIndustryId) ?? null}
                                                    renderInput={(params) => <TextField required {...params} label='Industry'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (<>{getIndustriesStatus === 'pending' ?
                                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}</>)
                                                        }} />}
                                                    onChange={(e, newValue) =>
                                                        setJobComparePayload({ ...jobComparePayload, currentJobIndustryId: newValue?.id ?? 0 })} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <Typography>Job</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9} lg={9}>
                                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='curJobTitle'
                                                    ListboxProps={AutocompletePopoverProps} options={jobTitles} onOpen={loadJobTitles}
                                                    getOptionLabel={(option) => option?.title || ''}
                                                    groupBy={(option) => option.industry}
                                                    value={jobTitles?.find(jobTitle => jobTitle.id === jobComparePayload.currentJobId) ?? null}
                                                    renderInput={(params) => <TextField required {...params} label='Job title'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (<>{getJobTitlesStatus === 'pending' ?
                                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}</>)
                                                        }} />}
                                                    onChange={(e, newValue) =>
                                                        setJobComparePayload({ ...jobComparePayload, currentJobId: newValue?.id ?? 0 })} />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Divider />
                                    <Stack rowGap={2}>
                                        <Typography variant="body2" fontWeight={600} gutterBottom>What job do you want to compare yourself with?</Typography>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <Typography>Industry</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9} lg={9}>
                                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='compareIndustry'
                                                    ListboxProps={AutocompletePopoverProps} options={industries} onOpen={loadIndustries}
                                                    getOptionLabel={(option) => option.industry_translation?.name || ''}
                                                    value={industries?.find(industry => industry.id === jobComparePayload.compareJobIndustryId) ?? null}
                                                    renderInput={(params) => <TextField required {...params} label='Industry'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (<>{getIndustriesStatus === 'pending' ?
                                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}</>)
                                                        }} />}
                                                    onChange={(e, newValue) =>
                                                        setJobComparePayload({ ...jobComparePayload, compareJobIndustryId: newValue?.id ?? 0 })} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <Typography>Job</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9} lg={9}>
                                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='compareJobTitle'
                                                    ListboxProps={AutocompletePopoverProps} options={jobTitles} onOpen={loadJobTitles}
                                                    getOptionLabel={(option) => option?.title || ''}
                                                    groupBy={(option) => option.industry}
                                                    value={jobTitles?.find(jobTitle => jobTitle.id === jobComparePayload.compareJobId) ?? null}
                                                    renderInput={(params) => <TextField required {...params} label='Job title'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (<>{getJobTitlesStatus === 'pending' ?
                                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}</>)
                                                        }} />}
                                                    onChange={(e, newValue) =>
                                                        setJobComparePayload({ ...jobComparePayload, compareJobId: newValue?.id ?? 0 })} />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Paper variant="outlined">
                                    <Stack rowGap={2} padding={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
                                        <Stack rowGap={2}>
                                            <Box>
                                                <Typography variant="body1" fontSize='xx-large' fontWeight={500}>42%</Typography>
                                                <Typography>
                                                    &nbsp;of people fit their current role
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1" fontSize='xx-large' fontWeight={500}>58%</Typography>
                                                <Typography>
                                                    &nbsp;fit another role better
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Stack alignItems='center' justifyContent='center'>
                                            <Typography variant="body1" fontWeight={500}>See which job fits you better:</Typography>
                                            <Stack direction='row' alignItems='center' columnGap={1}>
                                                <Typography>A</Typography>
                                                <Typography ml={2}>Your current job</Typography>
                                            </Stack>
                                            <Typography variant="caption">Vs</Typography>
                                            <Stack direction='row' alignItems='center' columnGap={1}>
                                                <Typography>B</Typography>
                                                <Typography ml={2}>New Job</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Grid>
                        </Grid>
                        <ShButton variant="outlined" onClick={() => navigate(CandidateLoginRedirect)}>Back</ShButton>
                    </Box>
                </Paper>
            </Box>
        </Container>
    </>)
}