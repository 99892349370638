import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IApplicant, IApplicantProfileState, IApplicantRatingUpdate, IApplicantStageParams, IGetApplicantDetailsParams, ISuggestedQuestions, IUpdateApplicantPassword, IUpdateApplicantProfile } from 'Modules/Core/Applicants/ApplicantsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialApplicantProfileState: IApplicantProfileState = {};

export const getApplicantDetails = createAsyncThunk<IBaseResponse<IApplicant>, IGetApplicantDetailsParams, { rejectValue: IBaseResponse }>(
    'getApplicantDetails',
    async ({ jobId, applicationId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_JOBS}/${jobId}/applicants/${applicationId}`)
            .then((response: AxiosResponse<IBaseResponse<IApplicant>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getFullFingerPrintImage = createAsyncThunk<string, { applicantId: number }, { rejectValue: IBaseResponse }>(
    'getFullFingerPrintImage',
    async ({ applicantId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/fingerprints/full`)
            .then((response: AxiosResponse<string>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getSuggestedQuestions = createAsyncThunk<IBaseResponse<ISuggestedQuestions>, { applicantId: number }, { rejectValue: IBaseResponse }>(
    'getSuggestedQuestions',
    async ({ applicantId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/questions`)
            .then((response: AxiosResponse<IBaseResponse<ISuggestedQuestions>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getApplicantResume = createAsyncThunk<{ url: string }, { applicantId: number }, { rejectValue: IBaseResponse }>(
    'getApplicantResume',
    async ({ applicantId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/resume`)
            .then((response: AxiosResponse<{ url: string }>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateApplicantProfile = createAsyncThunk<IBaseResponse<IUpdateApplicantProfile>, IUpdateApplicantProfile, {
    rejectValue: IBaseResponse
}>(
    "updateApplicantProfile",
    async ({ applicantId, first_name, last_name, middle_name }: IUpdateApplicantProfile, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.UPDATE_APPLICANT_PROFILE}/${applicantId}`, { first_name, last_name, middle_name })
            .then((response) => response.data)
            .catch((error) => {
                throw rejectWithValue(error);
            });
    }
);

export const updateApplicantEmail = createAsyncThunk<IBaseResponse, {
    newEmail: string,
    otp: number
}, {
    rejectValue: IBaseResponse
}>(
    "updateApplicantProfile",
    async ({ newEmail, otp }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.UPDATE_APPLICANT_PROFILE}`, { newEmail, otp })
            .then((response) => response.data)
            .catch((error) => {
                throw rejectWithValue(error);
            });
    }
);

export const updateApplicantPassword = createAsyncThunk<IBaseResponse, IUpdateApplicantPassword, {
    rejectValue: IBaseResponse
}>(
    "updateApplicantPassword",
    async ({ confirmPassword, newPassword, oldPassword }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.UPDATE_APPLICANT_PASSWORD}`, { confirmPassword, newPassword, oldPassword })
            .then((response) => response.data)
            .catch((error) => {
                throw rejectWithValue(error);
            });
    }
);

export const changeApplicantStageInProfile = createAsyncThunk<IBaseResponse, IApplicantStageParams, { rejectValue: IBaseResponse }>(
    'changeApplicantStageInProfile',
    async ({ applicantId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/stage`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateApplicantRatingInProfile = createAsyncThunk<IBaseResponse, IApplicantRatingUpdate, { rejectValue: IBaseResponse }>(
    'updateApplicantRatingInProfile',
    async ({ applicantId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/rating`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const applicantProfileSlice = createSlice({
    name: 'applicantProfile',
    initialState: initialApplicantProfileState,
    reducers: {
        resetGetApplicantResume: (state) => {
            state.getApplicantResumeStatus = 'idle';
            state.getApplicantResumeResponse = '';
            state.resumeS3Url = undefined;
        },
        resetUpdateApplicantProfile: (state) => {
            state.updateApplicantProfileApiStatus = 'idle';
            state.updateApplicantProfileApiResponse = '';
        },
        resetGetApplicantDetails: (state) => {
            state.getApplicantDetailsStatus = 'idle';
            state.getApplicantDetailsResponse = '';
        },
        resetChangeApplicantStageInProfile: (state) => {
            if (state.applicantDetails) {
                state.applicantDetails.changeStateStatus = 'idle';
                state.applicantDetails.changeStateResponse = '';
            }
        },
        resetUpdateApplicantRatingInProfile: (state) => {
            if (state.applicantDetails) {
                state.applicantDetails.updateRatingStatus = 'idle';
                state.applicantDetails.updateRatingResponse = '';
            }
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplicantProfileState;
        });
        // get applicant details
        builder.addCase(getApplicantDetails.pending, (state) => {
            state.getApplicantDetailsStatus = 'pending';
        });
        builder.addCase(getApplicantDetails.fulfilled, (state, action) => {
            state.getApplicantDetailsStatus = 'success';
            state.applicantDetails = action.payload.data;
            if (state.applicantDetails) {
                state.applicantDetails.fullName = `${state.applicantDetails?.firstName ?? ''} ${state.applicantDetails?.lastName ?? ''}`;
            }
        });
        builder.addCase(getApplicantDetails.rejected, (state, action) => {
            state.getApplicantDetailsStatus = 'failed';
            state.getApplicantDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get fingerprint image
        builder.addCase(getFullFingerPrintImage.pending, (state) => {
            state.getFullFingerPrintImageStatus = 'pending';
        });
        builder.addCase(getFullFingerPrintImage.fulfilled, (state, action) => {
            state.getFullFingerPrintImageStatus = 'success';
            state.fullFingerPrintImage = action.payload;
        });
        builder.addCase(getFullFingerPrintImage.rejected, (state, action) => {
            state.getFullFingerPrintImageStatus = 'failed';
            state.getFullFingerPrintImageResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get suggested questions
        builder.addCase(getSuggestedQuestions.pending, (state) => {
            state.getSuggestedQuestionsStatus = 'pending';
        });
        builder.addCase(getSuggestedQuestions.fulfilled, (state, action) => {
            state.getSuggestedQuestionsStatus = 'success';
            state.suggestedQuestions = action.payload?.data;
        });
        builder.addCase(getSuggestedQuestions.rejected, (state, action) => {
            state.getSuggestedQuestionsStatus = 'failed';
            state.getSuggestedQuestionsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // get applicant resume
        builder.addCase(getApplicantResume.pending, (state) => {
            state.getApplicantResumeStatus = 'pending';
        });
        builder.addCase(getApplicantResume.fulfilled, (state, action) => {
            state.getApplicantResumeStatus = 'success';
            state.resumeS3Url = action.payload?.url;
        });
        builder.addCase(getApplicantResume.rejected, (state, action) => {
            state.getApplicantResumeStatus = 'failed';
            state.getApplicantResumeResponse = action.payload?.message;
        });

        // update applicant profile
        builder.addCase(updateApplicantProfile.pending, (state) => {
            state.updateApplicantProfileApiStatus = "pending";
        });
        builder.addCase(updateApplicantProfile.fulfilled, (state, action) => {
            state.updateApplicantProfileApiStatus = "success";
            state.updateApplicantProfileApiResponse = action.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateApplicantProfile.rejected, (state) => {
            state.updateApplicantProfileApiStatus = "failed";
        });

        // update applicant password
        builder.addCase(updateApplicantPassword.pending, (state) => {
            state.updatePasswordApiStatus = "pending";
        });
        builder.addCase(updateApplicantPassword.fulfilled, (state, action) => {
            state.updatePasswordApiStatus = "success";
            state.updatePasswordApiResponse = action.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateApplicantPassword.rejected, (state) => {
            state.updatePasswordApiStatus = "failed";
        });
        // change applicant stage
        builder.addCase(changeApplicantStageInProfile.pending, (state, action) => {
            if (state.applicantDetails) {
                state.applicantDetails.changeStateStatus = 'pending';
            }
        });
        builder.addCase(changeApplicantStageInProfile.fulfilled, (state, action) => {
            if (state.applicantDetails) {
                state.applicantDetails.changeStateStatus = 'success';
                state.applicantDetails.changeStateResponse = action?.payload?.message;
                state.applicantDetails.jobStage = action.meta.arg.payload.stage;
            }
        });
        builder.addCase(changeApplicantStageInProfile.rejected, (state, action) => {
            if (state.applicantDetails) {
                state.applicantDetails.changeStateStatus = 'failed';
                state.applicantDetails.changeStateResponse = action?.payload?.message;
            }
        });
        // change applicant rating
        builder.addCase(updateApplicantRatingInProfile.pending, (state, action) => {
            if (state.applicantDetails) {
                state.applicantDetails.updateRatingStatus = 'pending';
            }
        });
        builder.addCase(updateApplicantRatingInProfile.fulfilled, (state, action) => {
            if (state.applicantDetails) {
                state.applicantDetails.updateRatingStatus = 'success';
                state.applicantDetails.updateRatingResponse = action?.payload?.message;
                state.applicantDetails.rating = action.meta.arg.payload.rating;
            }
        });
        builder.addCase(updateApplicantRatingInProfile.rejected, (state, action) => {
            if (state.applicantDetails) {
                state.applicantDetails.updateRatingStatus = 'failed';
                state.applicantDetails.updateRatingResponse = action?.payload?.message;
            }
        });
    }
});

export const { resetGetApplicantResume, resetUpdateApplicantProfile, resetGetApplicantDetails,
    resetChangeApplicantStageInProfile, resetUpdateApplicantRatingInProfile } = applicantProfileSlice.actions;
export default applicantProfileSlice;
