import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Badge, Box, Collapse, List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import { ShDashboardIcon } from "assets/Icons";
import { useAppSelector } from "helpers/hooks";
import { AdminNavLink, AdminSidebarNavLink } from "Modules/Core/Layouts/AdminLayout/AdminLayout.styled";
import { IDashboardMenuItemProps } from "Modules/Core/Layouts/AdminLayout/AdminLayoutModel";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ShIconWrapper, SideNavLinksStack } from "shared/SharedStyles/ShLayouts";
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";

interface ILabelWithDynamicTooltip {
  icon: React.ReactNode;
  label: string;
  isExpanded?: boolean;
  removeExpandIcon?: boolean;
}

export const DashboardMenuItem = ({ menus, isMiniDrawer, setIsMiniDrawer }: IDashboardMenuItemProps) => {
  const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);
  const location = useLocation();
  const { pendingJobs } = useAppSelector((state) => state.admin.jobReviews);

  useEffect(() => {
    if (isMiniDrawer) setOpenSubmenu(null);
  }, [isMiniDrawer]);

  const toggleSubmenu = (index: number | null) => {
    if (setIsMiniDrawer && isMiniDrawer) setIsMiniDrawer(false);
    if (openSubmenu === index) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(index);
    }
  };

  const LabelWithDynamicTooltip = ({ icon, label, isExpanded, removeExpandIcon }: ILabelWithDynamicTooltip) => {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
        {isMiniDrawer ? (
          <Tooltip placement='right-end' title={label} arrow>
            <ShIconWrapper>{icon}</ShIconWrapper>
          </Tooltip>
        ) : (
          <>
            <Stack direction="row" alignItems="center">
              <ShIconWrapper>{icon}</ShIconWrapper>
              <Typography variant='body2'>{label}</Typography>
            </Stack>
            {!removeExpandIcon && (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
          </>
        )
        }
      </Stack>
    );
  };

  return (
    <List>
      {menus.map((item, index) => (
        <div key={index}>
          {item.items?.length ? (
            <SideNavLinksStack>
              <ListItem className="dashboard-list-item" onClick={() => { toggleSubmenu(index); }}>
                <LabelWithDynamicTooltip icon={item.icon} label={item.title ?? ""} isExpanded={openSubmenu === index} />
              </ListItem>
            </SideNavLinksStack>
          ) : (
            // to remove expanded icon and add link to the dashboard menu
            <AdminNavLink>
              <NavLink to={item.to ?? "#"} aria-label={item.title}
                className={({ isActive }) => isActive ? "active" : ""}>
                <ListItem className="dashboard-list-item">
                  <LabelWithDynamicTooltip
                    icon={<ShDashboardIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />}
                    label="Dashboard" removeExpandIcon={true} />
                </ListItem>
              </NavLink>
            </AdminNavLink>
          )}
          {/* to show the collapsible menus */}
          <Collapse in={openSubmenu === index} timeout="auto" unmountOnExit key={index}>
            <List component="div" disablePadding>
              {item.items?.map((subItem, subIndex) => (
                <AdminNavLink key={subIndex}>
                  <AdminSidebarNavLink to={subItem?.to ?? '#'}
                    className={location?.pathname === subItem?.to ? 'active-link' : ''}
                  >
                    {subItem.title?.replace(/ +/g, "").toUpperCase() === "PENDINGJOBS" ? (
                      <Box px={3.5}>
                        {pendingJobs.length > 0 ? <Badge badgeContent={pendingJobs.length} color="primary" /> : <></>}
                      </Box>
                    ) : (
                      <Box px={3.5} />
                    )}
                    <Typography variant="body2">{subItem.title}</Typography>
                  </AdminSidebarNavLink>
                </AdminNavLink>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
};
