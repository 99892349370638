import { Box, Card, styled } from "@mui/material";

export const CareerPagesBorderRadius = 10;

export const JobCard = styled(Card)(({ theme }) => ({
    margin: 1,
    padding: 15,
    borderRadius: 20,
    '& .MuiCardActions-root': {
        justifyContent: 'flex-end'
    }
}));

export const JobDetailsBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));
