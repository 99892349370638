import { Box, Link, Stack, Tooltip, Typography } from '@mui/material';
import { ChartsTooltip, ChartsXAxis, ChartsYAxis, LinePlot, ResponsiveChartContainer } from "@mui/x-charts";
import { InviteApplicants } from 'Modules/Core/Applicants/InviteApplicants';
import { JobSummaryStack } from 'Modules/Core/JobsTs/JobsListTs/Jobs.styled';
import { IJob, IJobSummary } from 'Modules/Core/JobsTs/JobsModel';
import { DistortionIcon, FitIcon, IncompleteIcon, StrongFitIcon, WeakFitIcon } from 'assets/Icons';
import { addDays, compareAsc, format, parseISO } from 'date-fns';
import dayjs from "dayjs";
import { IsMdScreen, IsXsScreen, useAppDispatch } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ISummaryComponentBase } from 'shared/SharedModels';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from 'store/slices/app/breadcrumb-slice';
import { JobType } from 'store/slices/employer/jobs/jobs-slice-model';
import { JobActions } from './JobActions';

export const JobSummary = ({ summary, jobsType, job }: (ISummaryComponentBase<IJobSummary> & { jobsType: JobType, job: IJob })) => {

    const dispatch = useAppDispatch();
    const isXsScreen = IsXsScreen();
    const isMdScreen = IsMdScreen();
    const [isInviteApplicantsOpen, setIsInviteApplicantsOpen] = useState<boolean>(false);
    const [chartData, setChartData] = useState<{ x: Date[], y: number[] }>({ x: [], y: [] });

    const gotoIncompleteApplicants = () => {
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({ labelReg: { [job.id]: `${job.name}` } }));
    };

    const getApplicantAppliedDateRange = (firstApplicantDate: string) => {
        const startDate = parseISO(firstApplicantDate);
        const endDate = addDays(startDate, 29);
        return { startDate, endDate };
    };

    useEffect(() => {
        if (summary?.applicantList && summary?.applicantList.length > 0) {
            const sortedApplicants = [...summary.applicantList]?.sort((a, b) => compareAsc(parseISO(a.applicationCompletedAt), parseISO(b.applicationCompletedAt)));
            const firstApplicantDate = sortedApplicants[0].applicationCompletedAt;

            const { startDate, endDate } = getApplicantAppliedDateRange(firstApplicantDate);
            const dateCounts: { [key: string]: number } = {};

            for (let d = startDate; d <= endDate; d = addDays(d, 1)) {
                const dateStr = format(d, 'yyyy-MM-dd');
                dateCounts[dateStr] = 0;
            }
            summary?.applicantList.forEach(applicant => {
                const appliedDate = format(parseISO(applicant.applicationCompletedAt), 'yyyy-MM-dd');
                if (dateCounts[appliedDate] !== undefined) {
                    dateCounts[appliedDate] += 1;
                }
            });

            const x = Object.keys(dateCounts).map(date => new Date(date));
            const y = Object.values(dateCounts);
            setChartData({ x, y });
        }
    }, [summary]);

    return (<>
        <InviteApplicants isInviteApplicantsOpen={isInviteApplicantsOpen} job={job}
            setIsInviteApplicantsOpen={setIsInviteApplicantsOpen} />
        {/* {summary.matchTypeCounts?.find(mtc => mtc.count !== 0) &&
           } */}
        <JobSummaryStack>
            {jobsType !== 'draft' && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Stack paddingX={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}>
                        <Box className='job-detail'>
                            <Typography variant='subtitle2' className='label' whiteSpace='nowrap'>Total Applicants</Typography>
                            <Typography className='value' fontWeight={600}>{summary.totalApplicantsCount}</Typography>
                        </Box>
                        <Box className='job-detail'>
                            <StrongFitIcon />
                            <Typography variant='subtitle2' className='label'>Strong Fit</Typography>
                            <Typography className='value'>
                                {summary.matchTypeCounts?.find(mtc => mtc.matchType === 'STRONG')?.count || 0}
                            </Typography>
                        </Box>
                        <Box className='job-detail'>
                            <FitIcon />
                            <Typography variant='subtitle2' className='label'>Good Fit</Typography>
                            <Typography className='value'>
                                {summary.matchTypeCounts?.find(mtc => mtc.matchType === 'MATCH' || mtc.matchType === 'GOOD' )?.count || 0}
                            </Typography>
                        </Box>
                        <Box className='job-detail'>
                            <FitIcon />
                            <Typography variant='subtitle2' className='label'>Fair Fit</Typography>
                            <Typography className='value'>
                                {summary.matchTypeCounts?.find(mtc => mtc.matchType === 'FAIR')?.count || 0}
                            </Typography>
                        </Box>
                        <Box className='job-detail'>
                            <WeakFitIcon />
                            <Typography variant='subtitle2' className='label'>Weak Fit</Typography>
                            <Typography className='value'>
                                {summary.matchTypeCounts?.find(mtc => mtc.matchType === 'NONE' || mtc.matchType === 'WEAK')?.count || 0}
                            </Typography>
                        </Box>
                        <Box className='job-detail toggle-in-dark'>
                            <DistortionIcon />
                            <Typography variant='subtitle2' className='label'>Distortion</Typography>
                            <Typography className='value'>
                                {summary.matchTypeCounts?.find(mtc => mtc.matchType === 'DISTORTION')?.count || 0}
                            </Typography>
                        </Box>
                        <Box className='job-detail toggle-in-dark'>
                            <IncompleteIcon />
                            <Typography variant='subtitle2' className='label'>Incomplete</Typography>
                            <Typography className='value'>
                                <Tooltip title="View incomplete applicants list" >
                                    <Link component={RouterLink} to={`/employer/incomplete-applicants/${job.id}`}
                                        onClick={gotoIncompleteApplicants}>
                                        {summary?.inCompleteApplicantsCount || 0}</Link>
                                </Tooltip>
                            </Typography>
                        </Box>
                    </Stack>
                    {!isXsScreen && summary?.applicantList && summary?.applicantList.length > 0 &&
                        <Box width={{ sm: '65%', md: '73%', lg: '55%', xl: '60%' }} >
                            <Typography variant="subtitle1" align="center" gutterBottom>
                                Job Posting Performance
                            </Typography>
                            <ResponsiveChartContainer
                                series={[{
                                    type: 'line',
                                    color: ShGreen,
                                    data: chartData.y,
                                    valueFormatter: (value) => `${value} Candidates`, 
                                    label: "Number of Candidates Applied: "
                                }]}
                                xAxis={[{
                                    data: chartData.x.map((date) => format(new Date(date), 'yyyy-MM-dd')),
                                    scaleType: 'band',
                                    id: 'x-axis-id',
                                }]}
                                height={200}>
                                <LinePlot />
                                <ChartsYAxis labelFontSize={12} label="Number of Candidates" position="left" />
                                <ChartsXAxis valueFormatter={(date: string) => dayjs(date).format("MMM D")} />
                                <ChartsTooltip formatter={(params: string) => `${params.valueOf} # Candidates Applied`} />
                             </ResponsiveChartContainer>
                        </Box>
                    }
                    {!isMdScreen &&
                        <Stack paddingX={{ xs: 3, sm: 2, md: 3, lg: 1, xl: 5 }}>
                            <JobActions key={job.id} job={job} jobsType={jobsType} actionMenuType='widget' />
                        </Stack>
                    }
                </Stack>
            </>}
        </JobSummaryStack>
    </>);
};