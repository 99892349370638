import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISurveyQuestion, ISurveyState } from "Modules/Core/Survey/SurveyModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";

const initialSurveySliceState: ISurveyState = {};

export const getSurveyQuestions = createAsyncThunk<IBaseResponse<ISurveyQuestion[]>,
    void, { rejectValue: IBaseResponse }>(
        "getSurveyQuestions",
        async (_, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.SURVEY_QUESTIONS}`)
                .then((response: AxiosResponse<IBaseResponse<ISurveyQuestion[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const updateSurveyAnswer = createAsyncThunk<IBaseResponse<ISurveyQuestion>, {
    payload: { question_id: number, answer_value: number }
}, { rejectValue: IBaseResponse }>(
    "updateSurveyAnswer",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.SURVEY_ANSWERS}`, payload)
            .then((response: AxiosResponse<IBaseResponse<ISurveyQuestion>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const completeJobApplication = createAsyncThunk<{ message: string }, number, { rejectValue: IBaseResponse }>(
    "completeJobApplication",
    async (jobApplicationId: number, { rejectWithValue }) => {
        return httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOB_APPLY_COMPLETE}/${jobApplicationId}/complete`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw rejectWithValue(error.response.data)
            })
    }
);

const surveySlice = createSlice({
    name: "survey",
    initialState: initialSurveySliceState,
    reducers: {
        resetGetSurveyQuestions: (state) => {
            state.getSurveyQuestionsStatus = 'idle';
            state.getSurveyQuestionsResponse = '';
        },
        resetCompleteJobApplication: (state) => {
            state.completeJobApplicationStatus = 'idle';
            state.completeJobApplicationResponse = ''
        },
        resetSurvey: () => initialSurveySliceState
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialSurveySliceState;
        });
        // get job requirements
        builder.addCase(getSurveyQuestions.pending, (state) => {
            state.getSurveyQuestionsStatus = 'pending';
        });
        builder.addCase(getSurveyQuestions.fulfilled, (state, action) => {
            state.getSurveyQuestionsStatus = 'success';
            state.surveyQuestions = action.payload?.data?.map((q, i) => { q.scrollId = i + 1; return q });
            // If no "answer" is null then Survey is complete.
            state.isSurveyCompleted = action.payload?.data
                ?.filter(question => question.answer === null)?.length === 0;
        });
        builder.addCase(getSurveyQuestions.rejected, (state, action) => {
            state.getSurveyQuestionsStatus = 'failed';
            state.getSurveyQuestionsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update job requirements
        builder.addCase(updateSurveyAnswer.pending, (state, action) => {
            // Update api status on survey question level
            const updateIndex = state.surveyQuestions
                ?.findIndex(sQ => sQ.id === action.meta.arg.payload.question_id);
            if (updateIndex !== undefined && updateIndex >= 0 && state.surveyQuestions) {
                state.surveyQuestions[updateIndex].updateSurveyAnswerStatus = 'pending';
                state.isSurveyQuestionBeingAnswered = true;
                state.surveyQuestions[updateIndex].updateSurveyAnswerId = action.meta.arg.payload.answer_value;
            }
        });
        builder.addCase(updateSurveyAnswer.fulfilled, (state, action) => {
            // Update api status on survey question level
            const updateIndex = state.surveyQuestions
                ?.findIndex(sQ => sQ.id === action.meta.arg.payload.question_id);
            if (updateIndex !== undefined && updateIndex >= 0 && state.surveyQuestions) {
                state.surveyQuestions[updateIndex].updateSurveyAnswerStatus = 'success';
                state.isSurveyQuestionBeingAnswered = false;
            }
            // Update candidate answer in "surveyQuestions"
            state.surveyQuestions?.forEach(question => {
                if (question.id === action.payload.data.assessmentquestion_id) {
                    question.answer = action.payload.data?.answer_value;
                }
            });
            // Update 'isSurveyCompleted' as candidate answers.
            // If no "answer" is null then Survey is complete.
            state.isSurveyCompleted = state.surveyQuestions
                ?.filter(question => question.answer === null)?.length === 0;
        });
        builder.addCase(updateSurveyAnswer.rejected, (state, action) => {
            // Update api status on survey question level
            const updateIndex = state.surveyQuestions
                ?.findIndex(sQ => sQ.id === action.meta.arg.payload.question_id);
            if (updateIndex !== undefined && updateIndex >= 0 && state.surveyQuestions) {
                state.surveyQuestions[updateIndex].updateSurveyAnswerStatus = 'failed';
                state.isSurveyQuestionBeingAnswered = false;
                state.surveyQuestions[updateIndex].updateSurveyAnswerResponse =
                    action?.payload?.message ?? DefaultAPIErrorMsg;
            }
        });

        // complete job application
        builder.addCase(completeJobApplication.pending, (state) => {
            state.completeJobApplicationStatus = 'pending';
        });
        builder.addCase(completeJobApplication.fulfilled, (state, action) => {
            state.completeJobApplicationStatus = 'success';
            state.completeJobApplicationResponse = action?.payload?.message;
        });
        builder.addCase(completeJobApplication.rejected, (state, action) => {
            state.completeJobApplicationStatus = 'failed';
            state.completeJobApplicationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetSurveyQuestions, resetCompleteJobApplication, resetSurvey } = surveySlice.actions;
export default surveySlice;
