import { Container, Paper, Stack, Typography } from "@mui/material";
import { Login } from 'Modules/Core/AuthV2/Login';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { getWelcomeText } from "shared/utils";
import { resetAuth, resetCheckIfAccountExists } from 'store/slices/auth-v2/auth-v2-slice';
import { SocialProvidersMap } from "../AuthV2/AuthConstants";
import { SocialLogin } from "../AuthV2/SocialLogin";
import { IApplyJobLogin } from "./ApplyJobModel";

export const ApplyJobLogin = ({ setCurrentJobApplyStep, email, navigatedFrom }: IApplyJobLogin) => {

    const dispatch = useAppDispatch();
    const { checkIfAccountExistsResponse } = useAppSelector((state) => state.auth.auth);
    const [isShowForgotPassword, setIsShowForgotPassword] = useState<boolean>(false);
    const [isShowSocialLogin, setIsShowSocialLogin] = useState<boolean>(false);

    useEffect(() => {
        if (checkIfAccountExistsResponse?.signup_source) {
            setIsShowSocialLogin(true);
        }
    }, [checkIfAccountExistsResponse?.signup_source]);

    const handleBackInApplyJobLogin = () => {
        setCurrentJobApplyStep(navigatedFrom);
        dispatch(resetAuth());
        dispatch(resetCheckIfAccountExists());
    }

    return (<>
        <Container maxWidth='xs'>
            <Paper variant='outlined'>
                <Stack rowGap={3} alignSelf='center' alignItems='center' padding={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    {checkIfAccountExistsResponse?.firstname && !isShowForgotPassword && <>
                        <Typography variant="body2">{getWelcomeText(checkIfAccountExistsResponse?.firstname)}</Typography>
                    </>}
                    {!isShowForgotPassword && <>
                        {isShowSocialLogin ? <>
                            <Typography variant="body2">
                                The email provider partners with {checkIfAccountExistsResponse?.signup_source ?
                                    SocialProvidersMap[checkIfAccountExistsResponse?.signup_source] :
                                    checkIfAccountExistsResponse?.signup_source}
                            </Typography>
                            {checkIfAccountExistsResponse?.email &&
                                <Typography variant="body2">
                                    Continue as <strong>{checkIfAccountExistsResponse?.email}</strong>
                                </Typography>}
                        </> :
                            <Typography variant="caption">Please login to continue</Typography>}
                    </>}
                    <Stack rowGap={3} alignItems='center'>
                        {isShowSocialLogin ? <>
                            <SocialLogin buttonType="button" usedIn='candidate_apply_job'
                                loginProvider={checkIfAccountExistsResponse?.signup_source} />
                        </> :
                            <Login emailAutoFill={email} usedIn="candidate_apply_job" isShowSocialLogin={false}
                                getIsForgotPassword={(e) => setIsShowForgotPassword(e)} />}
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' width='100%' alignItems='center'>
                        <ShButton onClick={handleBackInApplyJobLogin}>Back</ShButton>
                    </Stack>
                </Stack>
            </Paper>
        </Container>
    </>)
}