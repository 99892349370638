import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { ECandidateFlow } from "Modules/Core/ApplyJob/ApplyJobConstants";
import { CandidateLoginRedirect } from "Modules/Core/AuthV2/AuthConstants";
import { TCandidateFlow } from "Modules/Core/Candidate/CandidateModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { Outlet, Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShBackdrop } from "shared/SharedStyles/ShFeedback";
import { ShMuiLink } from "shared/SharedStyles/ShNavigation";
import { PaperVariant } from "shared/SharedStyles/styleConstants";
import { getJobRequirements } from "store/slices/candidate/apply-job/apply-job-questionnaire.slice";
import { getSurveyQuestions, resetGetSurveyQuestions } from "store/slices/candidate/survey/survey-slice";
import { getJobDescriptionByCode } from "store/slices/employer/jobs/job-info-slice";
import { getCandidateAssessmentMetadata } from "store/slices/meta-data/assessment-meta-slice";

export const CandidateApplicationWrapper = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentPath = useLocation().pathname;
    const { jobCode } = useParams();
    const { isAccountLoggedIn, loggedInPage, currentAppContext, accountAccess } = useAppSelector(state => state.auth.auth);
    const { getCandidateAssessmentMetaStatus } = useAppSelector((state) => state.metadata.assessmentMeta);
    const { getJobApplicationRequirementsStatus } = useAppSelector((state) => state.candidate.applyJobQuestionnaire);
    const { getSurveyQuestionsStatus, isSurveyCompleted, surveyQuestions } = useAppSelector((state) => state.candidate.survey);
    const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const { candidateAssessmentMetadata } = useAppSelector((state) => state.metadata.assessmentMeta);
    const { jobDetailsByCode } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const [candidateLoginType, setCandidateLoginType] = useState<TCandidateFlow | undefined>(undefined);

    /*
    * Navigate directly to candidate/home if candidate logged in from employer page.
    * Candidate assessment and survey checks are bypassed in this scenario.
    */

    // Get job description on load if not logged in from employer page.
    useEffect(() => {
        if (loggedInPage !== 'employer' || currentAppContext !== 'candidate_apply_job') {
            if (!jobDetailsByCode && jobCode) {
                dispatch(getJobDescriptionByCode({ jobCode: jobCode }));
            }
        }
    }, [currentAppContext, dispatch, jobCode, jobDetailsByCode, loggedInPage]);

    // set redirect type based on the url
    useEffect(() => {
        if (currentPath.includes(ECandidateFlow.ApplyJob)) {
            setCandidateLoginType('apply_job');
        } else {
            setCandidateLoginType('survey');
        }
    }, [currentPath]);

    // block assessment for ats_purchased
    useEffect(() => {
        if (currentPath.includes('assessment') && jobDetailsByCode?.ats_purchased) {
            navigate(`/candidate/apply-job/${jobCode}/questionnaire`);
        }
    }, [currentPath, jobCode, jobDetailsByCode?.ats_purchased, navigate]);

    useEffect(() => {
        if (isAccountLoggedIn && (accountAccess.role ? accountAccess.role === 'candidate' : true) &&
            (loggedInPage !== 'employer' || currentAppContext === 'candidate_apply_job') &&
            (candidateDetails?.resumeComplete || currentAppContext === 'candidate_survey')) {
            if (!candidateAssessmentMetadata) {
                dispatch(getCandidateAssessmentMetadata());
            }
        }
    }, [accountAccess.role, candidateAssessmentMetadata, candidateDetails?.resumeComplete,
        currentAppContext, dispatch, isAccountLoggedIn, loggedInPage]);

    useEffect(() => {
        if (isAccountLoggedIn && (accountAccess.role ? accountAccess.role === 'candidate' : true) &&
            (loggedInPage !== 'employer' || currentAppContext === 'candidate_apply_job') &&
            (candidateDetails?.resumeComplete || currentAppContext === 'candidate_survey')) {
            if (!surveyQuestions) {
                dispatch(getSurveyQuestions());
            }
        }
    }, [accountAccess.role, candidateDetails?.resumeComplete, currentAppContext,
        dispatch, isAccountLoggedIn, loggedInPage, surveyQuestions]);

    // get job application requirements if candidate is applying for a job
    useEffect(() => {
        if (isAccountLoggedIn && (accountAccess.role ? accountAccess.role === 'candidate' : true) &&
            jobDetailsByCode?.id && candidateLoginType === 'apply_job' &&
            (loggedInPage !== 'employer' || currentAppContext === 'candidate_apply_job') && candidateDetails?.resumeComplete) {
            dispatch(getJobRequirements({ jobId: jobDetailsByCode?.id }));
        }
    }, [accountAccess.role, candidateDetails?.resumeComplete, candidateLoginType, currentAppContext,
        dispatch, isAccountLoggedIn, jobDetailsByCode?.id, loggedInPage]);

    // if survey flow
    useEffect(() => {
        if (isAccountLoggedIn && (accountAccess.role ? accountAccess.role === 'candidate' : true) &&
            candidateLoginType === 'survey' && loggedInPage !== 'employer') {
            if (!isSurveyCompleted) {
                navigate('/candidate/survey');
            }
            dispatch(resetGetSurveyQuestions());
        }
    }, [accountAccess.role, candidateLoginType, dispatch, isAccountLoggedIn, isSurveyCompleted, loggedInPage, navigate]);

    return (<>
        {(getSurveyQuestionsStatus === 'pending' || getCandidateAssessmentMetaStatus === 'pending' ||
            getJobApplicationRequirementsStatus === 'pending') ?
            <ShBackdrop open={true}>
                <CircularProgress color="inherit" />
                <Stack rowGap={1}>
                    {getJobApplicationRequirementsStatus === 'pending' &&
                        <Typography variant="body2">Loading questionnaire...</Typography>
                    }
                </Stack>
            </ShBackdrop> :
            isSurveyCompleted && !candidateDetails?.jobApplicationId ? <>
                <Stack height='100%' justifyContent='center' alignItems='center' padding={2}>
                    <Paper variant={PaperVariant}>
                        <Stack alignItems='center' justifyContent='center' rowGap={6} padding={5}>
                            <Box maxWidth={250}>
                                <ShLogo />
                            </Box>
                            <Box textAlign='center'>
                                <Typography variant="body1" gutterBottom>Your Survey is successfully completed.</Typography>
                                <ShMuiLink component={RouterLink} to={CandidateLoginRedirect}
                                    noUnderline underLineOnHover>Go to Dashboard</ShMuiLink>
                            </Box>
                        </Stack>
                    </Paper>
                </Stack>
            </> :
                <Outlet />
        }
    </>)
}