import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import { ShMyInterviewsIcon } from "assets/Icons";
import { IShFeatureTile } from 'shared/SharedModels';
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";

export const DashboardFeatureTiles: IShFeatureTile[] = [
    {
        tileLabel: 'Create a Job',
        featureRedirectUrl: '/employer/job/new',
        tileIcon: <AddIcon color='primary' />,
        caption: 'Quickly create customized job listings to Attract Top Candidates.',
        featureName: 'Create Job'
    },
    {
        tileLabel: 'Active Jobs',
        featureRedirectUrl: '/employer/jobs',
        tileIcon: <WorkIcon color='primary' />,
        caption: 'View all active jobs. Track application status',
        featureName: 'View Jobs'
    },
    {
        tileLabel: 'Interviews',
        featureRedirectUrl: '/employer/interviews',
        tileIcon: <ShMyInterviewsIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />,
        caption: 'View upcoming interviews. Manage interview schedules.',
        featureName: 'Interviews'
    },
    {
        tileLabel: 'Calendar',
        featureRedirectUrl: '/employer/calendar',
        tileIcon: <CalendarMonthIcon color='primary' />,
        caption: 'View all scheduled events. Track important dates.',
        featureName: 'Calendar'
    },
    {
        tileLabel: 'Resources',
        featureRedirectUrl: '/employer/resources',
        tileIcon: <LibraryBooksIcon color='primary' />,
        caption: 'AI Powered Tools, Offer Letter Templates, Interview Kits',
        featureName: 'Resources'
    },
    {
        tileLabel: 'Settings',
        featureRedirectUrl: '/employer/settings/account-information',
        tileIcon: <SettingsIcon color='primary' />,
        caption: 'Manage account preferences. Update personal info.',
        featureName: 'Settings'
    },
    {
        tileLabel: 'Help & More',
        featureRedirectUrl: '/employer/help',
        tileIcon: <HelpIcon color='primary' />,
        caption: 'Find user guides and FAQs. Get in touch with customer service.',
        featureName: 'Help'
    },
];

export const DashboardListSize = 20;
export const MaxDashboardApplicantSize = 999999;