import { Box, Stack, Typography } from "@mui/material";
import { ApplicantComment } from "Modules/Core/Applicants/ApplicantsList/ApplicantComment";
import { useAppSelector } from "helpers/hooks";
import moment from "moment";
import { UnderlinedText } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";

export const ApplicantComments = () => {

    const { comments } = useAppSelector((state) => state.employer.applicants.applicantComments);
    const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { accountId } = useAppSelector((state) => state.auth.auth);

    return (<>
        <Stack rowGap={2}>
            {comments?.length === 0 && <ShAlert severity="info">No Comments found</ShAlert>}
            {comments?.map(comment => (
                <Stack key={comment.id} justifyContent='center'>
                    <UnderlinedText variant='subtitle2' fontWeight='bold'
                        gutterBottom width='fit-content'>Comments</UnderlinedText>
                    <Stack flexDirection='row' alignItems='center' columnGap={1} marginBottom={0.5}>
                        <Typography variant='body2' whiteSpace='nowrap'>Commented by :</Typography>
                        <Typography variant='body2' fontWeight='bold'>{comment.user_name}</Typography>
                    </Stack>
                    <Stack flexDirection='row' alignItems='center' columnGap={1} marginBottom={0.5}>
                        <Typography variant='body2' whiteSpace='nowrap'>Comment :</Typography>
                        <Typography variant='body2' style = {{wordBreak:'break-word'}}>{comment.body}</Typography>
                    </Stack>
                    <Stack flexDirection='row' alignItems='center' columnGap={1} marginBottom={0.5}>
                        <Typography variant='body2' whiteSpace='nowrap'>Commented on:</Typography>
                        <Typography variant='body2'>{moment(comment.updated_at).format("MMM DD, YYYY")}</Typography>
                    </Stack>
                </Stack>
            ))}
            {applicantDetails &&
                <Box width='fit-content'>
                    <ApplicantComment applicantId={applicantDetails.candidateEmployerJobId}
                        isAddition={comments?.find(comment => comment.user_id === accountId) === undefined}
                        label={comments?.find(comment => comment.user_id === accountId) ? "Edit Comment" : "Add Comment"} />
                </Box>}
        </Stack>
    </>);
};