import { combineReducers } from "@reduxjs/toolkit";
import jobReviewsSlice from "store/slices/admin/jobs-reviews-slice";
import adminNotificationSlice from "store/slices/admin/notifications-slice";
import adminCandidateSlice from "./candidate/admin-candidate-slice";
import adminEmployerSlice from "./employer/admin-employer-slice";

const adminReducer = combineReducers({
    jobReviews: jobReviewsSlice.reducer,
    adminNotifications: adminNotificationSlice.reducer,
    adminEmployer: adminEmployerSlice.reducer,
    adminCandidate: adminCandidateSlice.reducer
});

export default adminReducer;