import ClearIcon from '@mui/icons-material/Clear';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import {
    Box, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle,
    FormControl,
    IconButton, InputLabel, MenuItem, Select, Stack, TextField, ToggleButton, Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { InterviewPreviewDialog } from 'Modules/Core/Interviews/InterviewPreviewDialog';
import { InterviewDialogStyled, MenuProps } from "Modules/Core/Interviews/Interviews.styled";
import { CreateInterviewPayload, CreateZoomMeetPayload, InterviewModeIconMap, InterviewModes, VideoInterviewTypes } from "Modules/Core/Interviews/InterviewsConstants";
import { ICreateInterviewPayload, ICreateZoomMeetPayload, InterviewType, VideoInterviewType } from 'Modules/Core/Interviews/InterviewsModel';
import { useNotification } from 'Modules/Core/Notification';
import dayjs, { Dayjs } from 'dayjs';
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ApiState } from 'shared/SharedModels';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShTextField, ShToggleButtonGroup } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { resetZoomCreation, resetZoomDelete, resetZoomUpdate } from 'store/slices/employer/applicants/applicant-actions-slice';
import { changeApplicantStageInProfile } from 'store/slices/employer/applicants/applicant-profile-slice';
import { createInterviewSchedule, resetCreateInterview, resetUpdateInterviewSchedule, updateInterviewSchedule, updateInterviewStatus } from 'store/slices/employer/interviews/interviews-actions-slice';
import { getInterviewDetails, resetGetInterviewDetails } from 'store/slices/employer/interviews/interviews-details-slice';
import { closeInterviewDialog } from 'store/slices/employer/interviews/interviews-shared-slice';
import { generateTimeIntervals, getNoonInterval } from './InterviewsUtilities';
import { ZoomMeet } from './ZoomMeet';

export const InterviewDialog = () => {

    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const { zoomMeetCreationStatus, zoomMeetCreationResponse, zoomMeetResponse, zoomMeetUpdateResponse,
        zoomMeetUpdateStatus, zoomMeetDeleteStatus, zoomMeetDeleteResponse
    } = useAppSelector((state) => state.employer.applicants.applicantActions);
    const { createInterviewScheduleStatus, createInterviewScheduleResponse, updateInterviewScheduleStatus,
        updateInterviewScheduleResponse
    } = useAppSelector((state) => state.employer.interviews.interviewsActions);
    const { interviewDetails, getInterviewDetailsStatus } = useAppSelector((state) => state.employer.interviews.interviewsDetails);
    const { applicantInfo, candidateName, interviewId, isInterviewDialogOpen,
        jobId, candidateEmployerJobId, stage } = useAppSelector((state) => state.employer.interviews.interviewsShared);
    const [_interviewDetails, _setInterviewDetails] = useState<ICreateInterviewPayload>({ ...CreateInterviewPayload });
    const [zoomMeet, setZoomMeet] = useState<ICreateZoomMeetPayload>({ ...CreateZoomMeetPayload });
    const [videoInterviewType, setVideoInterviewType] = useState<VideoInterviewType | ''>('');
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [intervalState, setIntervalState] = useState<number>(30);
    const [customIntervalState, setCustomIntervalState] = useState<number>(90);
    const [timeIntervals, setTimeIntervals] = useState(generateTimeIntervals(intervalState));
    const [selectedTime, setSelectedTime] = useState<string>(getNoonInterval(timeIntervals));

    // Retrieve interview details callback.
    const fetchInterviewDetails = useCallback(() => {
        if (interviewId) {
            dispatch(getInterviewDetails({ id: interviewId }));
        }
    }, [dispatch, interviewId]);

    const _closeInterviewDialog = useCallback(() => {
        /*
        * Reset and clear every state and variable before closing dialog,
        *   to avoid any unexpected behavior. 
        * Since the model instance is not complete removed.
        */
        setZoomMeet({ ...CreateZoomMeetPayload });
        _setInterviewDetails({ ...CreateInterviewPayload });
        setVideoInterviewType('');
        setIsPreviewDialogOpen(false);
        setIsSubmitDisabled(false);
        dispatch(closeInterviewDialog());
        dispatch(resetCreateInterview());
        dispatch(resetGetInterviewDetails());
        dispatch(resetUpdateInterviewSchedule());
        dispatch(resetZoomCreation());
        dispatch(resetZoomUpdate());
        dispatch(resetZoomDelete());
    }, [dispatch]);

    // Load interview details if interview id is given.
    useEffect(() => {
        if (isInterviewDialogOpen) {
            fetchInterviewDetails();
        }
    }, [dispatch, fetchInterviewDetails, isInterviewDialogOpen]);

    // Load interview details if interview is edited.
    useEffect(() => {
        if (zoomMeetUpdateStatus === 'success' || zoomMeetDeleteStatus === 'success') {
            fetchInterviewDetails();
        }
    }, [fetchInterviewDetails, zoomMeetDeleteStatus, zoomMeetUpdateStatus]);

    // Populate interview details on edit mode.
    useEffect(() => {
        if (getInterviewDetailsStatus === 'success' && interviewDetails) {
            const { from_time, info, note, schedule_date, to_time, type_of_interview,
                zoom_meeting_id, zoom_meeting } = interviewDetails;
            _setInterviewDetails({
                from_time: from_time,
                info: info,
                note: note,
                schedule_date: schedule_date,
                to_time: to_time,
                type_of_interview: type_of_interview,
                zoom_meeting_id: zoom_meeting_id
            });

            if (type_of_interview === 'Video') {
                if (zoom_meeting_id && zoom_meeting?.response_data) {
                    setVideoInterviewType('zoom');
                    const { agenda, start_time, password, topic } = zoom_meeting?.response_data;

                    // retrieve date and time from 'start_time' since its in ISO format.
                    const dateTime = new Date(start_time);
                    const date = dateTime.toDateString();
                    const time = dateTime.toLocaleTimeString();

                    setZoomMeet({
                        agenda: agenda,
                        from_time: time,
                        password: password,
                        schedule_date: date,
                        topic: topic,
                        zoom_meeting_id: zoom_meeting_id
                    });
                } else {
                    setVideoInterviewType('other');
                }
            }
        }
    }, [dispatch, getInterviewDetailsStatus, interviewDetails, interviewId]);

    // Update zoom info into _interviewDetails once its created.
    useEffect(() => {
        if (zoomMeetCreationStatus === 'success') {
            _setInterviewDetails((prev) => {
                let newDetails = { ...prev };
                newDetails.zoom_meeting_id = zoomMeetResponse?.id;
                newDetails.info = zoomMeetResponse?.response?.agenda ?? prev.info;
                return newDetails;
            });
            setZoomMeet((prev) => {
                let newZoom = { ...prev };
                newZoom.agenda = zoomMeetResponse?.response?.agenda ?? prev.agenda;
                newZoom.zoom_meeting_id = zoomMeetResponse?.id;
                return newZoom;
            });
        }
    }, [zoomMeetCreationStatus, zoomMeetResponse?.id, zoomMeetResponse?.response?.agenda])

    const onInterviewModeChange = (interviewType: InterviewType) => {
        // Reset 'zoomMeet' payload and 'videoInterviewType' if interview type is not video.
        if (interviewType !== 'Video') {
            setZoomMeet({
                agenda: '',
                from_time: '',
                password: '',
                schedule_date: '',
                topic: ''
            });
            setVideoInterviewType('');
        }
        _setInterviewDetails({ ..._interviewDetails, type_of_interview: interviewType });
    };

    // Disable submit button.
    useEffect(() => {
        const { info, note, schedule_date, type_of_interview } = _interviewDetails;
        setIsSubmitDisabled((videoInterviewType !== 'zoom' && !info) ||
            (videoInterviewType === 'zoom' && (!zoomMeet.agenda || !zoomMeet.password || !zoomMeet.zoom_meeting_id)) ||
            !note || !schedule_date || !type_of_interview);
    }, [_interviewDetails, videoInterviewType, zoomMeet.agenda, zoomMeet.password, zoomMeet.zoom_meeting_id]);

    const scheduleInterview = () => {
        setZoomMeet({
            ...zoomMeet,
            zoom_meeting_id: zoomMeetResponse?.id,
            from_time: _interviewDetails.from_time,
            schedule_date: _interviewDetails.schedule_date,
            topic: _interviewDetails.note
        });
        // Update interview payload with zoom details.
        const interviewPayload = { ..._interviewDetails };
        interviewPayload.zoom_meeting_id = zoomMeetResponse?.id;
        interviewPayload.info = zoomMeet.agenda;
        interviewPayload.application_id = applicantInfo?.candidateEmployerJobId;
        dispatch(createInterviewSchedule(interviewPayload));
    };

    const updateSchedule = () => {
        if (interviewDetails) {
            const { from_time, to_time, schedule_date, type_of_interview, info, note, zoom_meeting_id } = _interviewDetails;
            dispatch(updateInterviewSchedule({
                id: interviewDetails.id,
                body: { from_time, to_time, schedule_date, type_of_interview, info, note, zoom_meeting_id }
            }));
        }
    };

    // Show snackbar notifications when interview schedule is successfully created.
    useEffect(() => {
        if (createInterviewScheduleStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: createInterviewScheduleResponse
            });
            // Change stage after interview is scheduled.
            if (candidateEmployerJobId && stage) {
                dispatch(changeApplicantStageInProfile({ applicantId: candidateEmployerJobId, payload: { stage: stage } }));
            }
            _closeInterviewDialog();
        }
    }, [_closeInterviewDialog, candidateEmployerJobId, createInterviewScheduleResponse,
        createInterviewScheduleStatus, dispatch, notification, stage]);

    // Show snackbar notifications when interview schedule is successfully updated.
    useEffect(() => {
        if (updateInterviewScheduleStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: updateInterviewScheduleResponse
            });
            if (interviewId) {
                dispatch(updateInterviewStatus({ id: interviewId, body: { status: 'Scheduled', comments: 'Re scheduled' } }));
            }
            // Change stage after interview is scheduled.
            if (candidateEmployerJobId && stage) {
                dispatch(changeApplicantStageInProfile({ applicantId: candidateEmployerJobId, payload: { stage: stage } }));
            }
            _closeInterviewDialog();
        }
    }, [_closeInterviewDialog, candidateEmployerJobId, dispatch, interviewId,
        notification, stage, updateInterviewScheduleResponse, updateInterviewScheduleStatus]);

    const showAlerts = (apiState: ApiState, pendingText: string, responseText: string, callBack?:
        ActionCreatorWithoutPayload<"applicantActions/resetZoomCreation"> |
        ActionCreatorWithoutPayload<"applicantActions/resetZoomUpdate"> |
        ActionCreatorWithoutPayload<"applicantActions/resetZoomDelete"> | undefined) => {
        switch (apiState) {
            case 'pending':
                return (<ShAlert marginBottom={2} severity="info">{pendingText}</ShAlert>);
            case 'success':
                return (<ShAlert marginBottom={2} severity="success"
                    onClose={() => callBack ? dispatch(callBack()) : ''}>{responseText}</ShAlert>);
            case 'failed':
                return (<ShAlert marginBottom={2} severity="error">{responseText}</ShAlert>);
            default:
                return (<></>);
        }
    };

    useEffect(() => {
        setTimeIntervals(generateTimeIntervals(intervalState));
    }, [intervalState]);

    return (<>
        <ShContainer maxWidth='sm'>
            <InterviewDialogStyled fullScreen={isSmScreen} open={isInterviewDialogOpen} onClick={e => e.stopPropagation()}
                onClose={() => _closeInterviewDialog()} aria-labelledby="title">
                <DialogTitle variant='h6' margin={1.5} id='title' fontWeight={600}>
                    {getInterviewDetailsStatus === 'pending' ? 'Loading...' :
                        interviewDetails ? `Interview ${interviewDetails.status} - ${candidateName}` :
                            <Box display="flex" alignItems="center">
                                <InsertInvitationOutlinedIcon />
                                &nbsp; Schedule Interview
                            </Box>

                    }
                    <IconButton className='close-btn' onClick={() => { _closeInterviewDialog() }}><ClearIcon /></IconButton>
                </DialogTitle>
                <DialogContent>
                    {showAlerts(zoomMeetCreationStatus, 'Creating Zoom Meet...', zoomMeetCreationResponse, resetZoomCreation)}
                    {showAlerts(zoomMeetUpdateStatus, 'Updating Zoom Meet...', zoomMeetUpdateResponse, resetZoomUpdate)}
                    {showAlerts(zoomMeetDeleteStatus, 'Deleting Zoom Meet...', zoomMeetDeleteResponse, resetZoomDelete)}
                    {showAlerts(createInterviewScheduleStatus, 'Creating Interview schedule...', createInterviewScheduleResponse)}
                    {showAlerts(updateInterviewScheduleStatus, 'Updating Interview schedule...', updateInterviewScheduleResponse)}
                    {getInterviewDetailsStatus === 'pending' ?
                        <Stack justifyContent='center' alignItems='center'>
                            <CircularProgress />
                        </Stack> : <>
                            <Stack spacing={1.5}>
                                <Typography variant='subtitle1' > To: <Chip label={candidateName || applicantInfo?.fullName} /> </Typography>
                                <Typography variant='subtitle1' fontWeight='bold'> Duration </Typography>
                                <ShToggleButtonGroup color="primary" size="small" exclusive
                                    minWidth='100px' orientation={isXsScreen ? 'vertical' : 'horizontal'} value={intervalState}
                                    onChange={(e, newValue) => { newValue !== null && setIntervalState(newValue); }}
                                    aria-label="Duration">
                                    {[15, 30, 60].map(dur => (
                                        <ToggleButton key={dur} value={15} aria-label={`${dur} minutes`}>
                                            {dur} min
                                        </ToggleButton>
                                    ))}
                                    <ToggleButton value={customIntervalState} aria-label="Custom Duration">
                                        Custom Duration
                                    </ToggleButton>
                                </ShToggleButtonGroup>
                                <Stack paddingTop={1}>
                                    {(intervalState === 90 || ![15, 30, 60].includes(intervalState)) && <ShTextField
                                        onBlur={(event) => {
                                            if (event.target.value === '') {
                                                setCustomIntervalState(90);
                                            } else {
                                                setIntervalState(Number(event.target.value));
                                                setCustomIntervalState(Number(event.target.value))
                                            }
                                        }}
                                        type="number" label="Enter Duration (Minutes)" />}
                                </Stack>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Typography fontWeight='bold'>Interview time & date</Typography>
                                    <Stack direction='row' columnGap={2} alignItems='center'>
                                        <DatePicker label="Select Date" value={dayjs(_interviewDetails.schedule_date)}
                                            onChange={(newValue: Dayjs | null) => {
                                                _setInterviewDetails({ ..._interviewDetails, schedule_date: newValue ? newValue.format('YYYY-MM-DD') : '' });
                                            }}
                                            disablePast
                                            disabled={zoomMeetCreationStatus === 'pending'}
                                        />
                                        <FormControl variant="outlined">
                                            <InputLabel>Time Interval</InputLabel>
                                            <Select
                                                value={selectedTime}
                                                disabled={zoomMeetCreationStatus === 'pending'}
                                                onChange={(event) => {
                                                    const interval = event.target.value;
                                                    setSelectedTime(interval);
                                                    const [startTime, endTime] = interval.split(' - ');
                                                    _interviewDetails.from_time = dayjs(startTime, 'hh:mm A').format('HH:mm');
                                                    _interviewDetails.to_time = dayjs(endTime, 'hh:mm A').format('HH:mm');
                                                }}
                                                MenuProps={MenuProps}
                                                label="Time Interval"
                                                renderValue={(selected) => selected}
                                            >
                                                {timeIntervals.map((interval, index) => (
                                                    <MenuItem color='grey' key={index} value={interval}>
                                                        {interval}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </LocalizationProvider>
                                <Stack rowGap={2}>
                                    <Typography fontWeight='bold'>Interview Type</Typography>
                                    <ShToggleButtonGroup color="primary" size="small" exclusive variant='pill' buttonsGap={1.5}
                                        minWidth='100px' orientation={isXsScreen ? 'vertical' : 'horizontal'}
                                        value={_interviewDetails.type_of_interview}>
                                        {InterviewModes.map((interviewMode) => (
                                            <ToggleButton value={interviewMode} key={interviewMode} size='small'
                                                onClick={() => onInterviewModeChange(interviewMode)}>
                                                <Stack direction='row' columnGap={1} alignItems='center'>
                                                    {InterviewModeIconMap[interviewMode]}
                                                    <Typography variant='body2'>
                                                        {interviewMode}
                                                    </Typography>
                                                </Stack>
                                            </ToggleButton>
                                        ))}
                                    </ShToggleButtonGroup>
                                    {(_interviewDetails.type_of_interview === 'Video') &&
                                        <Typography fontWeight='bold'>Select conferencing tool</Typography>}
                                    {_interviewDetails.type_of_interview === 'Video' &&
                                        <FormControl fullWidth disabled={zoomMeetCreationStatus === 'pending'}>
                                            <InputLabel id="duration-select-label">Interview Type</InputLabel>
                                            <Select label="Interview Type" value={videoInterviewType} id='i_t'
                                                onChange={e => setVideoInterviewType(e.target.value as VideoInterviewType)}>
                                                {VideoInterviewTypes.map(vIT => (
                                                    <MenuItem key={vIT.value} value={vIT.value}>{vIT.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                </Stack>
                                {(_interviewDetails.type_of_interview === 'Video' && videoInterviewType !== 'zoom') &&
                                    <Typography fontWeight='bold'>Share video call link</Typography>}
                                {_interviewDetails.type_of_interview === 'In-person' &&
                                    <Typography fontWeight='bold'>Share location of interview</Typography>}
                                {_interviewDetails.type_of_interview === 'Phone' &&
                                    <Typography fontWeight='bold'>Share phone number</Typography>}

                                {(applicantInfo || interviewDetails) && jobId && <>
                                    {videoInterviewType === 'zoom' ? <ZoomMeet applicantInfo={applicantInfo} interviewDetails={_interviewDetails}
                                        setZoomMeet={setZoomMeet} zoomMeet={zoomMeet} jobId={jobId} interviewInfo={interviewDetails} /> :
                                        <TextField id="i_location" label={_interviewDetails.type_of_interview === 'In-person' ? "Interview Location/Address" :
                                            _interviewDetails.type_of_interview === 'Video' ? 'Video Link' : 'Phone number of the applicant'} required
                                            variant="outlined" value={_interviewDetails.info} disabled={zoomMeetCreationStatus === 'pending'}
                                            onChange={e => _setInterviewDetails({ ..._interviewDetails, info: e.target.value })} />
                                    }
                                </>}
                                <Typography fontWeight='bold'>Add Notes</Typography>
                                <TextField minRows={7} id="i_notes" label="Interview Notes" variant="outlined" multiline
                                    value={_interviewDetails.note} disabled={zoomMeetCreationStatus === 'pending'}
                                    onChange={e => _setInterviewDetails({ ..._interviewDetails, note: e.target.value })} />
                            </Stack>
                        </>}

                </DialogContent>
                <DialogActions>
                    {interviewDetails ?
                        <ShGreenBtn variant="contained" disableElevation size="small" onClick={updateSchedule}
                            className='self-right'>Submit</ShGreenBtn> :
                        <>
                            <Stack direction='row' justifyContent='space-between' width='100%'>
                                <ShButton variant="contained" disableElevation size="small"
                                    onClick={() => setIsPreviewDialogOpen(true)}>Preview</ShButton>
                                <ShGreenBtn variant="contained" disableElevation size="small"
                                    disabled={isSubmitDisabled || createInterviewScheduleStatus === 'pending'}
                                    onClick={scheduleInterview}>Schedule and Email</ShGreenBtn>
                            </Stack>
                        </>
                    }
                </DialogActions>
            </InterviewDialogStyled>
            {/* Preview dialog */}
            {applicantInfo && jobId &&
                <InterviewPreviewDialog isDialogOpen={isPreviewDialogOpen} setIsDialogOpen={setIsPreviewDialogOpen}
                    applicantInfo={applicantInfo} interviewInfo={_interviewDetails} jobId={jobId} />
            }
        </ShContainer>
    </>);
};
