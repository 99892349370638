import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextPrimary, WhiteColor } from 'shared/SharedStyles/styleConstants';

export const JobSummaryStack = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    '& .job-detail': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: '160px',
        marginBottom: theme.spacing(2),
        '& .MuiSvgIcon-root': {
            marginRight: '5px',
            color: TextPrimary
        },
        '& .label': {
            color: theme.palette.mode === 'light' ? TextPrimary : WhiteColor,
            width: '100px'
        },
        '& .value': {
            color: theme.palette.mode === 'light' ? '#6A707E' : WhiteColor,
            flex: 1,
            textAlign: 'right'
        },
        '&.toggle-in-dark': {
            '& .MuiSvgIcon-root': {
                color: theme.palette.mode === 'light' ? '#627690' : WhiteColor
            }
        }
    }
}));
