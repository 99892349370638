import { combineReducers } from '@reduxjs/toolkit';
import { ICandidateProfileState } from 'Modules/Core/Candidate/CandidateModel';
import { PersistConfig } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from "redux-persist/lib/storage";
import adminNotificationSlice from 'store/slices/admin/notifications-slice';
import applyJobHomeSlice from "store/slices/candidate/apply-job/apply-job-home-slice";
import applyJobQuestionnaireSlice from "store/slices/candidate/apply-job/apply-job-questionnaire.slice";
import candidateNotificationsSlice from "store/slices/candidate/candidate-notifications-slice";
import candidateProfileSlice from "store/slices/candidate/candidate-profile-slice";
import surveySlice from "store/slices/candidate/survey/survey-slice";

const candidateProfilePersistConfig: PersistConfig<ICandidateProfileState> = {
    key: 'candidateProfile',
    storage,
    whitelist: ['candidateDetails']
};

const candidateReducer = combineReducers({
    applyJobHome: applyJobHomeSlice.reducer,
    candidateProfile: persistReducer(candidateProfilePersistConfig, candidateProfileSlice.reducer),
    survey: surveySlice.reducer,
    candidateNotifications: candidateNotificationsSlice.reducer,
    applyJobQuestionnaire: applyJobQuestionnaireSlice.reducer,
});

export { adminNotificationSlice, applyJobHomeSlice, candidateNotificationsSlice, candidateProfileSlice, surveySlice };
export default candidateReducer;

