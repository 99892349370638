import { Box, Grid, Stack, Typography } from '@mui/material';
import { FacebookIcon, LinkedinIcon, XIcon } from 'react-share';
import { ShLogo } from 'shared/SharedComponents/ShLogo';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ResourceMuiLink, ResourceWrapperStyle } from './Resources.styled';
import { PrimaryWordpressThemeColor } from 'shared/SharedStyles/styleConstants';

export const ResourceFooter = () => {
    return (
        <ShContainer margin="auto" maxWidth="xl">
            <Grid container spacing={3}>
                {/* Logo and Description */}
                <Grid item xs={12} md={2.5}>
                    <Stack spacing={.5}>
                            <Box width='175px' height="50px">
                                <ShLogo />
                            </Box>                     
                            <Typography variant="body2">
                            SmoothHiring, built on predictive analytics, is an all-in-one hiring solution for SMB's.
                        </Typography>
                    </Stack>
                </Grid>

                {/* Company Links */}
                <Grid item xs={12} sm={3} md={2}>
                    <Stack spacing={1}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Company
                        </Typography>
                        <ResourceMuiLink href="https://smoothhiring.com" variant="body2" color="inherit">
                            Home
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/About-Us" variant="body2" color="inherit">
                            About Us
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/testimonials" variant="body2" color="inherit">
                            Testimonials
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/blog" variant="body2" color="inherit">
                            Blog
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/contact-us" variant="body2" color="inherit">
                            Contact Us
                        </ResourceMuiLink>
                    </Stack>
                </Grid>

                {/* Product Links */}
                <Grid item xs={12} sm={3} md={2}>
                    <Stack spacing={1}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Product
                        </Typography>
                        <ResourceMuiLink href="https://smoothhiring.com/integrations" variant="body2" color="inherit">
                            Integrations
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/pricing" variant="body2" color="inherit">
                            Pricing
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/frequently-asked-questions/" variant="body2" color="inherit">
                            FAQ's
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/help-center" variant="body2" color="inherit">
                            Help Center
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://calendly.com/admin-1ue9/30min" variant="body2" color="inherit">
                            Request a Demo
                        </ResourceMuiLink>
                    </Stack>
                </Grid>

                {/* Features Links */}
                <Grid item xs={12} sm={3} md={2}>
                    <Stack spacing={1}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Features
                        </Typography>
                        <ResourceMuiLink href="https://smoothhiring.com/core-features" variant="body2" color="inherit">
                            All Features
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/job-distribution" variant="body2" color="inherit">
                            Post to Many Job Boards
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/predictive-analytics" variant="body2" color="inherit">
                            Predict Best Employees
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/applicant-tracking/" variant="body2" color="inherit">
                            Applicant Tracking System (ATS)
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/careers-pages/" variant="body2" color="inherit">
                            Careers Page
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/onboarding/" variant="body2" color="inherit">
                            Onboarding
                        </ResourceMuiLink>
                    </Stack>
                </Grid>

                {/* Resources Links TBD/FIXED*/}
                <Grid item xs={12} sm={3} md={2}>
                    <Stack spacing={1}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Resources
                        </Typography>
                        <ResourceMuiLink href="https://resources.smoothhiring.com/resources/job-description-templates" variant="body2" color="inherit">
                            Job Description Templates
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://resources.smoothhiring.com/resources/ai-interview-kit" variant="body2" color="inherit">
                            Interview Questions
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://smoothhiring.com/hiring-templates" variant="body2" color="inherit">
                            Offer Templates
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://resources.smoothhiring.com/resources/offer-templates" variant="body2" color="inherit">
                            Applicant Tracking System (ATS)
                        </ResourceMuiLink>
                        <ResourceMuiLink href="https://resources.smoothhiring.com/resources/description-generator" variant="body2" color="inherit">
                            AI Tools
                        </ResourceMuiLink>
                    </Stack>
                </Grid>

                {/* Contact Information and Social Icons */}
                <Grid item xs={12} >
                    <ResourceWrapperStyle color='white' backgroundColor={PrimaryWordpressThemeColor} direction={{ sm: 'column', md: 'row' }} spacing={1} justifyContent="center"  alignItems={{ sm: 'flex-start', md: 'center' }}  paddingBottom={5}>
                        <Typography variant="body2">1 877 789 8767 </Typography>
                        <Typography variant="body2">help@smoothhiring.com</Typography>
                        <Typography variant="body2">Suite #106, 6797 N High Street, Worthington, Ohio-43085</Typography>
                        <Stack direction="row" spacing={1}>
                            <FacebookIcon size={20} />
                            <XIcon size={20} />
                            <LinkedinIcon size={20} />
                        </Stack>
                    </ResourceWrapperStyle>
                </Grid>
            </Grid>
        </ShContainer>
    );
};

export default ResourceFooter;
