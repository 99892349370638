
// ResourceHome.tsx content
export const AI_TOOLS_TITLE_JOB_DESCRIPTION = "Free AI-Powered Job Description Generator";
export const AI_TOOLS_TITLE_INTERVIEW_KIT = "Free AI-Powered Interview Kit with Questions & Answers";
export const AI_TOOLS_DETAILS_JOB_DESCRIPTION = "Effortlessly craft detailed job descriptions using the latest AI technology. Our platform streamlines the process for free, helping you save time and ensure consistency in your job postings.";
export const AI_TOOLS_DETAILS_INTERVIEW_KIT = "Generate interview kits effortlessly with our modern AI technology. Our platform simplifies the process for free, helping you save time and ensuring a consistent interviewing experience.";
export const EXPLORE_SMOOTH_HIRING = "Explore SmoothHiring's ATS Software";
export const EXPLORE_SMOOTH_HIRING_DETAILS = "Seamlessly navigate the hiring process with precision and reach thousands of qualified candidates effortlessly through SmoothHiring's comprehensive hiring suite.";
export const HIRE_BEST_CANDIDATES = "Hire the Best Candidates";
export const HIRE_BEST_CANDIDATES_DETAILS = "How does a list of the best employees for your job sound? Utilize SmoothHiring's patented predictive analytics technology to discover top-tier talent.";

//Job Description card content
export const JOB_DESCRIPTION_TITLE_CARD_1 = "Attract Top Candidates";
export const JOB_DESCRIPTION_DESC_CARD_1 = "Our platform tailors job descriptions with industry-specific keywords and compelling language, aiming to captivate high-caliber professionals. This strategic method boosts applicant numbers and guarantees that your listings attract the finest talent, establishing your company as a preferred destination for elite professionals.";
export const JOB_DESCRIPTION_TITLE_CARD_2 = "Create Professional Job Descriptions";
export const JOB_DESCRIPTION_DESC_CARD_2 = " Our tool enhances job descriptions, making them more readable and concise. Studies show that, on average, candidates spend only about 15 seconds reading a job description. In today's fast-paced era, capturing someone's attention is challenging. Therefore, it's crucial for your job descriptions to be well-formatted and easy to read."


//Interview Kit card content
export const JOB_INTERVIEW_KIT_TITLE_CARD_1 = "Interview Candidates Easily";
export const JOB_INTERVIEW_KIT_DESC_CARD_1 = "Whether you're a hiring manager, recruiter, or business owner, our comprehensive interview kit for candidates easily equips you with the knowledge and tools needed to make informed hiring decisions and build a talented workforce. Unlock the potential of your recruitment process and elevate your company's success with our expert guidance.";
export const JOB_INTERVIEW_KIT_TITLE_CARD_2 = "Save Time with Our Interview Kits ";
export const JOB_INTERVIEW_KIT_DESC_CARD_2 = "Effortlessly save time with our Interview Kits. AI curated and packed with industry-specific questions and tips, they streamline your interview process for quicker, smarter hiring decisions. From industry-specific questions to insightful tips and best practices, our Interview Kits are designed to help you make informed hiring decisions quickly and confidently.";


export const CUSTOMER_TESTIMONIAL = {
    testimonial: "SmoothHiring's AI job description generator is a game-changer, saving us hours and ensuring top-notch job descriptions in seconds. Its made crafting compelling roles effortless and efficient!",
    name: 'John Smith (HR Manager)',
    rating: 5
};

export const ResourceWrapperCTAConstantMessage ={
    message: "🚀 Give our patented predictive analytics platform a try and avoid making bad hires ",
    messageCTA: "Try it free",
    redirection_url: "https://app.smoothhiring.com/employers/new"
} 