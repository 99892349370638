import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ShBullets, UnderlinedText } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { DefaultAPIErrorMsg } from "shared/constants";
import { getSuggestedQuestions } from "store/slices/employer/applicants/applicant-profile-slice";

export const ApplicantQuestions = () => {
    const dispatch = useAppDispatch();
    const { applicantId } = useParams();
    const { getSuggestedQuestionsStatus, getSuggestedQuestionsResponse,
        suggestedQuestions } = useAppSelector((state) => state.employer.applicants.applicantProfile);

    // get suggested questions on load.
    useEffect(() => {
        if (applicantId) {
            dispatch(getSuggestedQuestions({ applicantId: parseInt(applicantId) }));
        }
    }, [applicantId, dispatch]);

    return (<>
        {getSuggestedQuestionsStatus === 'pending' && <LinearProgress />}
        {getSuggestedQuestionsStatus === 'failed' && applicantId &&
            <>
                <Box marginBottom={2} width='100%'>
                    <ShAlert severity="error" action={
                        <ShButton color="inherit" size="small"
                            onClick={() => dispatch(getSuggestedQuestions({ applicantId: parseInt(applicantId) }))}>
                            Retry
                        </ShButton>
                    } >
                        {getSuggestedQuestionsResponse ?? DefaultAPIErrorMsg}
                    </ShAlert>
                </Box>
            </>}
        {/* Questions section */}
        {suggestedQuestions && <>
            <Stack rowGap={1}>
                {getSuggestedQuestionsStatus === 'success' && suggestedQuestions?.questions?.length === 0 &&
                    <ShAlert severity="info">No questions found!</ShAlert>}
                {suggestedQuestions?.questions?.map(question => (
                    <ShPaper key={question.title} variant="outlined">
                        <Box>
                            <UnderlinedText variant="body2" fontWeight={600}>
                                {question.title}
                            </UnderlinedText>
                            <Typography variant="caption" gutterBottom>
                                {question.blurb}
                            </Typography>
                            <Box mb={1} paddingX={{ xs: 1, sm: 2, md: 2.5, lg: 3 }}>
                                <UnderlinedText variant="body2">
                                    {suggestedQuestions?.questions_to_ask}
                                </UnderlinedText>
                                <Stack rowGap={1}>
                                    {question?.questions && Object.keys(question?.questions).map(qstn => (
                                        <Box key={qstn}>
                                            {
                                                question?.questions && question?.questions[qstn] &&
                                                <>
                                                    <Typography variant="body2" fontWeight={600}>
                                                        {question?.questions && question?.questions[qstn].head}
                                                    </Typography>
                                                    <ShBullets>
                                                        {question?.questions[qstn]?.follows?.map((q, i) => (
                                                            q && <li key={i}><Typography variant='caption'>{q}</Typography></li>
                                                        ))}
                                                    </ShBullets>
                                                    <UnderlinedText variant="body2" gutterBottom>
                                                        {suggestedQuestions?.suggested_responses}
                                                    </UnderlinedText>
                                                    <Grid container paddingX={{ xs: 1, sm: 2, md: 2.5, lg: 3 }}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Typography variant="body2">{suggestedQuestions?.strong_responses}</Typography>
                                                            <ShBullets>
                                                                {question.strong_responses?.map((q, i) => (
                                                                    q && <li key={i}><Typography variant='caption'>{q}</Typography></li>
                                                                ))}
                                                            </ShBullets>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Typography variant="body2">{suggestedQuestions?.weak_responses}</Typography>
                                                            <ShBullets>
                                                                {question.weak_responses?.map((q, i) => (
                                                                    q && <li key={i}><Typography variant='caption'>{q}</Typography></li>
                                                                ))}
                                                            </ShBullets>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                        </Box>
                    </ShPaper>
                ))}
            </Stack>
        </>}
    </>);
};