import { Box, Tab } from "@mui/material";
import { CreateJobOutletContainer } from "Modules/Core/CreateJob/CreateJob.styled";
import { CreateJobPaths } from "Modules/Core/CreateJob/CreateJobConstants";
import { CreateJobPath, CreateJobPathEnum } from "Modules/Core/CreateJob/CreateJobModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiState } from "shared/SharedModels";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShTabs } from "shared/SharedStyles/ShNavigation";
import { DefaultAPIErrorMsg } from "shared/constants";
import { resetJobDetailsState } from "store/slices/employer/create-job/create-job-details-slice";
import { resetSelectedBundles } from "store/slices/employer/create-job/create-job-payments-slice";
import { resetCreateJobPreviewState } from "store/slices/employer/create-job/create-job-preview-slice";
import {
    getRequirementsMetaData, resetCreateOrUpdateJob, resetGetRequirementsMetaData, resetStoredInfo, setAllJobDetailsInState, setCurrentJobCreationFlow, setIsBundlesEnabled,
    setIsDetailsEnabled, setIsJobIdWithTemplateCreated, setIsPaymentEnabled, setIsPreviewEnabled
} from "store/slices/employer/create-job/create-job-shared-slices";
import { getJobHiringManagers } from "store/slices/employer/create-job/job-title-slice";
import { getJobDetails, resetGetJobDetails } from "store/slices/employer/jobs/job-info-slice";
import { getJobTitles, getJobsStatesByCountry } from "store/slices/meta-data/jobs-meta-slice";

export const CreateJob = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const pathname = useLocation()?.pathname;
    const lastPathSegment: CreateJobPath = (pathname?.split('/').pop() ?? 'title') as CreateJobPath;
    const { requirementsMetaData, getRequirementsMetaDataStatus, allJobDetails, jobDetails, jobTitleInfo,
        isDetailsEnabled, isPreviewEnabled, getRequirementsMetaDataResponse, isBundlesEnabled, currentJobCreationFlow,
        isPaymentEnabled } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { selectedBundles } = useAppSelector((state) => state.employer.createJob.createJobPayments);
    const { jobStatesByCountry, jobTitles, getJobStatesByCountryStatus, getJobTitlesStatus } = useAppSelector(state => state.metadata.jobsMeta);
    const { employerId, accountCountry } = useAppSelector((state) => state.auth.auth);
    const { jobHiringManagers, getJobHiringManagersStatus } = useAppSelector((state) => state.employer.jobTitle);
    const { getJobDetailsStatus, getJobDetailsResponse, jobDetailsInInfo } = useAppSelector((state) => state.employer.employerJobs.jobInfo);
    const [curPage, setCurPage] = useState<CreateJobPath>('title');
    const isWelcomeCreateJobAcknowledged = useAppSelector(state => state.app.appInfo.isWelcomeCreateJobAcknowledged);

    const resetCreateJob = useCallback(() => {
        dispatch(resetJobDetailsState());
        dispatch(resetGetJobDetails());
        dispatch(resetGetRequirementsMetaData());
        dispatch(resetStoredInfo());
        dispatch(resetSelectedBundles());
        dispatch(resetCreateJobPreviewState());
    }, [dispatch]);

    // clear prev job details if any on load and its a new job creation
    useEffect(() => {
        if (pathname.includes('/new')) {
            resetCreateJob();
        }
    }, [pathname, resetCreateJob]);

    // Get all the meta data required for job creation and persist them so apis are not called until the user logs out.
    useEffect(() => {
        if (jobId) {
            if (requirementsMetaData === undefined) {
                dispatch(getRequirementsMetaData({ jobId: parseInt(jobId) }));
            }
        }
    }, [dispatch, jobId, requirementsMetaData]);

    useEffect(() => {
        if (accountCountry?.id && jobStatesByCountry?.length === 0 &&
            getJobStatesByCountryStatus !== 'pending' && getJobStatesByCountryStatus !== 'failed') {
            dispatch(getJobsStatesByCountry({ countryId: accountCountry?.id }));
        }
    }, [accountCountry?.id, dispatch, getJobStatesByCountryStatus, jobStatesByCountry?.length]);


    useEffect(() => {
        if (jobTitles?.length === 0 && getJobTitlesStatus !== 'pending' && getJobTitlesStatus !== 'failed') {
            dispatch(getJobTitles());
        }
    }, [dispatch, getJobTitlesStatus, jobTitles?.length]);

    useEffect(() => {
        if (jobHiringManagers?.length === 0 && employerId &&
            getJobHiringManagersStatus !== 'pending' && getJobHiringManagersStatus !== 'failed') {
            dispatch(getJobHiringManagers({ empId: employerId }));
        }
    }, [dispatch, employerId, getJobHiringManagersStatus, jobHiringManagers?.length]);

    useEffect(() => {
        if (jobId) {
            if (jobDetailsInInfo === undefined && !isNaN(parseInt(jobId))) {
                dispatch(getJobDetails({ jobId: jobId }));
            }
        }
    }, [dispatch, jobDetailsInInfo, jobId]);

    // Navigate user to Welcome Create Job screen if not acknowledged.
    useEffect(() => {
        if (isWelcomeCreateJobAcknowledged === 'no') {
            navigate(`/employer/job/welcome`);
        }
    }, [isWelcomeCreateJobAcknowledged, navigate]);

    /*
        If routed to parent route 'job', 'job/new' or 'job/jobId' then set curPage to default and navigate to About Company.
        Only navigate to default page when the path includes the above paths and not actual paths like
        'title', 'details', 'preview' which are stored in constant array 'CreateJobPaths'.
    */
    useEffect(() => {
        if (((lastPathSegment === 'new' || lastPathSegment === 'welcome') ||
            !isNaN(parseInt(lastPathSegment))) && !CreateJobPaths?.includes(lastPathSegment)) {
            setCurPage('title');
            if (jobId !== undefined) {
                navigate(`/employer/job/${!isNaN(parseInt(lastPathSegment)) ? jobId : 'new'}/title`);
            }
            dispatch(resetGetJobDetails());
            dispatch(resetGetRequirementsMetaData());
            dispatch(resetStoredInfo());
            dispatch(resetSelectedBundles());
        }
        // If disabled routes are being accessed through url field of browser navigate to appropriate page.
        else if (CreateJobPaths.filter(cp => cp !== 'title')?.includes(lastPathSegment)) {
            let path: CreateJobPath = lastPathSegment;
            switch (lastPathSegment) {
                case 'details':
                    if (jobId && !isNaN(parseInt(jobId))) {
                        if (!isDetailsEnabled) {
                            navigate(`/employer/job/${jobId}/title`);
                            path = 'title';
                        }
                    } else {
                        navigate(`/employer/job/new/title`);
                        path = 'title';
                    }
                    break;
                case 'preview':
                    if (jobId && !isNaN(parseInt(jobId))) {
                        if (!isPreviewEnabled) {
                            navigate(`/employer/job/${jobId}/details`);
                            path = 'details';
                        }
                    } else {
                        navigate(`/employer/job/new/title`);
                        path = 'title';
                    }
                    break;
                case 'bundles':
                    if (jobId && !isNaN(parseInt(jobId))) {
                        if (!isBundlesEnabled) {
                            navigate(`/employer/job/${jobId}/title`);
                            path = 'title';
                        }
                    }
                    break;
                case 'payment':
                    if (jobId && !isNaN(parseInt(jobId))) {
                        if (!isPaymentEnabled) {
                            navigate(`/employer/job/${jobId}/title`);
                            path = 'title';
                        }
                    }
                    break;
                default:
                    break;
            }
            setCurPage(path);
        } else {
            const allSegments = pathname.split('/') as CreateJobPath[];
            if (allSegments.includes('payment') && (lastPathSegment === 'failed' || lastPathSegment === 'success')) {
                setCurPage('payment');
            } else {
                setCurPage(lastPathSegment);
            }
        }
    }, [dispatch, isBundlesEnabled, isDetailsEnabled, isPaymentEnabled, isPreviewEnabled, jobId, lastPathSegment, navigate, pathname]);

    // update allJobDetails in state once retrieved and stored in jobInfo slice.
    useEffect(() => {
        if (getJobDetailsStatus === 'success' && jobDetailsInInfo) {
            dispatch(setAllJobDetailsInState({ allJobDetails: jobDetailsInInfo }));
        }
    }, [dispatch, getJobDetailsStatus, jobDetailsInInfo])

    // Disable or enable Details
    useEffect(() => {
        if (jobId && !isNaN(parseInt(jobId))) {
            dispatch(setIsDetailsEnabled(jobTitleInfo?.job_title_id !== undefined && jobTitleInfo?.job_title_id > 0));
        } else {
            dispatch(setIsDetailsEnabled(false));
        }
    }, [dispatch, jobId, jobTitleInfo?.job_title_id]);

    // Disable or enable Preview, Bundles and Payment
    useEffect(() => {
        if (jobId && !isNaN(parseInt(jobId))) {
            if (allJobDetails?.job_title_id === undefined || allJobDetails?.job_title_id === 0) {
                dispatch(setIsPreviewEnabled(false));
                dispatch(setIsBundlesEnabled(false));
                dispatch(setIsPaymentEnabled(false));
            } else {
                if (jobDetails?.requirements && requirementsMetaData) {
                    dispatch(setIsPreviewEnabled(allJobDetails?.description ? true : false));
                    dispatch(setIsBundlesEnabled(allJobDetails?.description || currentJobCreationFlow === 'useTemplateJob' ? true : false));
                    dispatch(setIsPaymentEnabled(allJobDetails?.description && selectedBundles?.length > 0 ? true : false));
                }
            }
        } else {
            dispatch(setIsPreviewEnabled(false));
            dispatch(setIsBundlesEnabled(false));
            dispatch(setIsPaymentEnabled(false));
        }
    }, [allJobDetails?.description, allJobDetails?.job_title_id, dispatch, currentJobCreationFlow,
    jobDetails?.requirements, jobId, requirementsMetaData, selectedBundles?.length]);

    const isRouteEnabled = () => {
        switch (lastPathSegment) {
            case 'details':
                return isDetailsEnabled;
            case 'preview':
                return isPreviewEnabled;
            default:
                return false;
        }
    };

    const showFailureAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    // Reset all states on unmount.
    useEffect(() => {
        return () => {
            dispatch(setCurrentJobCreationFlow('default'));
            dispatch(setIsJobIdWithTemplateCreated(false));
            dispatch(resetCreateOrUpdateJob());
            resetCreateJob();
        }
    }, [dispatch, resetCreateJob]);

    return (<>
        <CreateJobOutletContainer paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
            <ShTabs className="create-job-tabs" aria-label="Create Job Tabs" variant="scrollable" scrollButtons='auto'
                allowScrollButtonsMobile value={curPage} onChange={(e, nV) => setCurPage(nV as CreateJobPath)}>
                <Tab label="Job Details" component={Link} value={CreateJobPathEnum.Title}
                    to={`/employer/job/${jobId ? jobId : 'new'}/title`} />
                {currentJobCreationFlow !== 'useTemplateJob' &&
                    <Tab label="Job Duties & Skills" component={Link} value={CreateJobPathEnum.Details}
                        disabled={!isDetailsEnabled} to={`/employer/job/${jobId === 'new' ? 'new' : jobId}/details`} />}
                {currentJobCreationFlow !== 'useTemplateJob' &&
                    <Tab label="Preview" component={Link} value={CreateJobPathEnum.Preview}
                        disabled={!isPreviewEnabled} to={`/employer/job/${jobId === 'new' ? 'new' : jobId}/preview`} />}
                {currentJobCreationFlow !== 'templateJob' &&
                    <Tab label="Bundles" disabled={!isBundlesEnabled} value={CreateJobPathEnum.Bundles}
                        component={Link} to={`/employer/job/${jobId === 'new' ? 'new' : jobId}/bundles`} />
                }
                {currentJobCreationFlow !== 'templateJob' &&
                    <Tab label="Payment" disabled={!isPaymentEnabled} value={CreateJobPathEnum.Payment}
                        component={Link} to={`/employer/job/${jobId === 'new' ? 'new' : jobId}/payment`} />}
            </ShTabs>
            <Box flex={1}
                overflow='auto' paddingY={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                {showFailureAlerts(getJobDetailsStatus, getJobDetailsResponse)}
                {showFailureAlerts(getRequirementsMetaDataStatus, getRequirementsMetaDataResponse)}
                <Outlet context={isRouteEnabled()} />
            </Box>
        </CreateJobOutletContainer>
    </>);
};