import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Button, Chip, Divider, IconButton, Paper, Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import Menu from '@mui/material/Menu';
import { ApplicantsHeaders } from 'Modules/Core/Applicants/ApplicantsConstants';
import { ApplicantsFilters } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsFilters';
import { IApplicantsToolBar } from 'Modules/Core/Applicants/ApplicantsModel';
import { useEffect, useState } from 'react';
import { LayoutTypes } from 'shared/SharedModels';
import { ShInputBase } from 'shared/SharedStyles/ShInputs';
import { StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { ShToolbarPaper } from 'shared/SharedStyles/ShSurfaces';
import { PaperVariant } from 'shared/SharedStyles/styleConstants';
import { KeyCodes } from 'shared/constants';

export const ApplicantsToolBar = ({ layoutType, setLayoutType, applyFilters, selectedFilters,
    sortList, sortParams, removeFilter, isSmScreen, searchInApplicants, searchKey }: IApplicantsToolBar) => {

    const [sortByAnchorEl, setSortByAnchorEl] = useState<HTMLElement | null>(null);
    const isSortByOpen = Boolean(sortByAnchorEl);
    const [srchKey, setSrchKey] = useState<string>("");

    // Update search key based on List component.
    useEffect(() => {
        setSrchKey(prev => { return searchKey });
    }, [searchKey]);

    return (<>
        <ShToolbarPaper elevation={0}>
            <Stack className='tools-stack' columnGap={1} rowGap={1}>
                {/* Hide filter chips when filters are empty. */}
                {(selectedFilters.stages?.length > 0 || selectedFilters.keywords?.length > 0 ||
                    selectedFilters.fit?.length > 0 || selectedFilters.rating?.length > 0) ?
                    <Stack className='filter-chips'>
                        {/* Styled MUI tabs component to show overflown filters with scroll buttons instead of default scroll bar. */}
                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="selected filters" value={0}>
                            {selectedFilters.stages?.map((fltr, indx) => (<Tab key={fltr.label} value={indx} disableRipple label={<Chip label={fltr.label} onDelete={() => removeFilter('stages', fltr.value)} />} />))}
                            {selectedFilters.keywords?.map((fltr, indx) => (<Tab key={fltr.label} value={indx} disableRipple label={<Chip label={fltr.label} onDelete={() => removeFilter('keywords', fltr.value)} />} />))}
                            {selectedFilters.fit?.map((fltr, indx) => (<Tab key={fltr.label} value={indx} disableRipple label={<Chip label={fltr.label} onDelete={() => removeFilter('fit', fltr.value)} />} />))}
                            {selectedFilters.rating?.map((fltr, indx) => (<Tab key={fltr.label} value={indx} disableRipple label={<Chip label={'Rating: ' + fltr.value} onDelete={() => removeFilter('rating', fltr.value)} />} />))}
                        </Tabs>
                    </Stack> :
                    <Typography variant='body1' padding={1} fontWeight={600}>Applicants</Typography>}
                <Stack flexDirection='row' columnGap={1}>
                    <Paper className="custom-paper" variant={PaperVariant}>
                        <ShInputBase id='search-applicants' placeholder='Search' inputProps={{ 'aria-label': 'Search Applicants' }}
                            value={srchKey} onChange={e => setSrchKey(e.target.value)} onKeyUp={e => e.code === KeyCodes.Enter && searchInApplicants(srchKey)} />
                        {/* Show clear search icon if there is any search key */}
                        {searchKey?.length > 0 && <Tooltip title="Clear search">
                            <IconButton type="button" aria-label="clear" color='error' onClick={() => searchInApplicants('')}><ClearIcon /></IconButton>
                        </Tooltip>}
                        <Tooltip title="Search">
                            <span>
                                <IconButton disabled={srchKey?.trim().length === 0} type="button" aria-label="search"
                                    onClick={() => searchInApplicants(srchKey)}><SearchIcon /></IconButton>
                            </span>
                        </Tooltip>
                        <Divider orientation="vertical" />
                        {/* ApplicantsFilters component holds all the filter options in a dropdown. */}
                        <ApplicantsFilters applyFilters={applyFilters} selectedFilters={selectedFilters} />
                    </Paper>
                    {/* Sort button displayed only in list view. */}
                    {layoutType === LayoutTypes.List &&
                        <Paper className="custom-paper" variant={PaperVariant}>
                            {/* Sort by column name dropdown and sort direction button*/}
                            <Stack flexDirection='row' columnGap={{ xs: 0, sm: 1, md: 1, lg: 1 }}>
                                <Button size="small"
                                    aria-label={`Sort by ${ApplicantsHeaders.find(ah => ah.columName === sortParams.orderBy)?.label} 
                                in ${sortParams.order === 'asc' ? 'ascending' : 'descending'} order`}
                                    onClick={e => setSortByAnchorEl(e.currentTarget)}>
                                    {ApplicantsHeaders.find(ah => ah.columName === sortParams.orderBy)?.label}
                                </Button>
                                <Menu id="sort-by-menu" aria-label='Sort by menu' anchorEl={sortByAnchorEl}
                                    open={isSortByOpen} onClose={() => setSortByAnchorEl(null)}>
                                    {ApplicantsHeaders.map(hd => (
                                        <StyledMenuItem key={hd.columName} className={hd.columName === sortParams.orderBy ? "selected-item" : ""}
                                            onClick={() => { sortList(hd.columName, sortParams.order); setSortByAnchorEl(null); }}>{hd.label}</StyledMenuItem>
                                    ))}
                                </Menu>
                                <Tooltip title="Reverse sort direction">
                                    <IconButton aria-label={`Sort in ${sortParams.order === 'asc' ? 'ascending' : 'descending'} order`}
                                        onClick={() => {
                                            sortList(sortParams.orderBy, sortParams.order === 'asc' ? 'desc' : 'asc');
                                            setSortByAnchorEl(null);
                                        }}>
                                        {sortParams.order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Paper>}
                    {!isSmScreen && <Paper className="custom-paper" variant={PaperVariant}>
                        {/* View type toggle buttons. Default type is List. */}
                        <ToggleButtonGroup exclusive value={layoutType}
                            onChange={(e, newVal) => newVal !== null && setLayoutType(newVal)} aria-label="toggle layout">
                            <ToggleButton value={LayoutTypes.List} aria-label="List">
                                <ViewListIcon />
                            </ToggleButton>
                            <ToggleButton value={LayoutTypes.Table} aria-label="Table">
                                <TableChartIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>}
                </Stack>
            </Stack>
        </ShToolbarPaper>
    </>);
};