import { Grid, Stack, Typography } from "@mui/material";
import { rejectionTemplates } from 'Modules/Marketing/Resources/Templates/RejectionTemplates/RejectionTemplateConstants';
import { IsSmScreen } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

export const RejectionTemplateHome = () => {

    const isSmScreen = IsSmScreen();

    return (
        <ShContainer maxWidth="xl" height="100%">
            <Stack paddingTop={4} maxWidth='85%'>
                <Typography variant="h6" paddingBottom={3} paddingLeft={1}> Rejection Letter Templates</Typography>
                <Grid container spacing={2} >
                    {rejectionTemplates.map((template, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <ShPaper elevation={2}>
                                <Stack minHeight={isSmScreen ? '100px' : '150px'} justifyContent="space-between" height="100%" padding={1} spacing={.5}>
                                    <Typography textAlign="left" fontWeight="bold" fontSize={isSmScreen ? 15 : 17}>
                                        {template.title}
                                    </Typography>
                                    <Typography textAlign="left" fontSize={isSmScreen ? 11 : 12}>
                                        {template.description}
                                    </Typography>
                                    <ShButton component={RouterLink}
                                        to={`/employer/resources/rejection-letter-templates${template.path}`}
                                        variant="contained" size="small">View Template</ShButton>
                                </Stack>
                            </ShPaper>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </ShContainer>

    );
};

export default RejectionTemplateHome;
