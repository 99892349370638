import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NearMeIcon from '@mui/icons-material/NearMe';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ShareIcon from '@mui/icons-material/Share';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
    Box, Chip, Divider, Grid, IconButton, List, ListItemText, Stack, Typography,
} from '@mui/material';
import { IsSmScreen } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { ShButton, ShWordpressButton } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryThemeColor, PrimaryWordpressThemeColor } from 'shared/SharedStyles/styleConstants';
import { ResourceTypography, TemplateHomesStack } from '../../Resources.styled';
import { PolicyTemplate, PolicyTemplates, requirePolicyTemplateContext } from '../TemplateModel';

const policyDescriptions: PolicyTemplates = requirePolicyTemplateContext.keys().reduce((acc: PolicyTemplates, key: string) => {
    const moduleExports = requirePolicyTemplateContext(key);
    Object.keys(moduleExports).forEach((exportKey) => {
        const moduleName = exportKey.toLowerCase();
        acc[moduleName] = moduleExports[exportKey];
    });
    return acc;
}, {});

export const PolicyTemplatePage = () => {

    const isSmScreen = IsSmScreen();
    const { templateName } = useParams<{ templateName: string | undefined }>();
    const [similarTemplates, setSimilarTemplates] = useState<string[]>([]);
    const [policyTemplate, setPolicyTemplate] = useState<PolicyTemplate | null>(null);
    const currentUrl = window.location.href;
    const navigate = useNavigate();

    const truncateString = (str: string, maxLength: number) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + "...";
        }
        return str;
    };

    useEffect(() => {
        if (templateName) {
            const formattedTemplateName = templateName.replace(/-/g, '').replace(/[()]/g, '').toLowerCase();
            const matchedPolicyTemplate = policyDescriptions[formattedTemplateName];
            setPolicyTemplate(matchedPolicyTemplate ?? null);
            if (!matchedPolicyTemplate) {
                // Navigate to the error page if the job description is empty
                navigate('/error');
                return;
            }
        }
    }, [navigate, templateName]);


    const copyLink = () => {
        navigator.clipboard.writeText(currentUrl);
    };

    const { title = "", policyBlurb = "", preRead = "", sections = [] } = policyTemplate || {};

    // Changing title & meta description for google searches
    document.title = title + " Templates";

    useEffect(() => {
        // Changing title & meta description for google searches
        if (policyTemplate) {
            document.title = `${policyTemplate.title} Templates`;

            const metaDescriptionTag = document.querySelector('meta[name="description"]');
            const metaDesc = `This ${policyTemplate.title.toLowerCase()} will give you a good starting point for your company!`;
            if (metaDescriptionTag) {
                metaDescriptionTag.setAttribute('content', metaDesc);
            }
        }
    }, [policyTemplate]);

    const findSimilarTemplates = useCallback((templateName: string): string[] => {
        try {
            const templateWords = templateName.toLowerCase().split('-');
            Object.keys(policyDescriptions).forEach((key) => {
                const template = policyDescriptions[key];
                const templateWordsToMatch = template.title.toLowerCase().split(" ");
                const matches = templateWords.filter(word => templateWordsToMatch.includes(word)).length;
                if (matches >= 2) {
                    similarTemplates.push(template.title);
                }
            });

            return similarTemplates;
        } catch (error) {
            console.error('Error finding similar templates:', error);
            return ["Nothing similar currently, Check back later!"];
        }
    }, [similarTemplates]);

    useEffect(() => {
        if (templateName) {
            const createSimilarTemplates = async () => {
                const templates = await findSimilarTemplates(templateName);
                setSimilarTemplates(templates)
            };
            createSimilarTemplates();
        }
    }, [templateName, similarTemplates, findSimilarTemplates]);

    return (
        <Stack>
            <TemplateHomesStack justifyContent="center" alignItems="center" backgroundColor="#F0F8FF">
                <ResourceTypography textAlign="center" gutterBottom variant="body2" fontWeight={700} color={PrimaryThemeColor}>
                    HR Templates | Company Policy Templates
                </ResourceTypography>
                <Typography textAlign="center" padding={2} maxWidth={500} variant="h4" gutterBottom fontWeight={800}>
                    {title}
                </Typography>
                <ResourceTypography textAlign="center" maxWidth={1000} variant="body1" paragraph>
                    {policyBlurb}
                </ResourceTypography>

                <Stack margin={2} spacing={2} direction="row" alignContent="center">
                    <ShareIcon fontSize="small" />
                    <Typography variant="subtitle2"> Share this Job Description</Typography>
                </Stack>

                <Stack spacing={2} direction="row">
                    <FacebookShareButton url={currentUrl}>
                        <FacebookIcon size={30} round />
                    </FacebookShareButton>
                    <LinkedinShareButton url={currentUrl}>
                        <LinkedinIcon size={30} round />
                    </LinkedinShareButton>
                    <TwitterShareButton url={currentUrl}>
                        <TwitterIcon size={30} round />
                    </TwitterShareButton>
                    <EmailShareButton url={currentUrl}>
                        <EmailIcon size={30} round />
                    </EmailShareButton>
                    <IconButton onClick={copyLink}>
                        <ContentCopyIcon />
                    </IconButton>
                </Stack>
            </TemplateHomesStack>

            <ShContainer maxWidth="md" height="100%" margin="auto">
                <Stack padding={3}>
                    <ResourceTypography gutterBottom variant="subtitle1" paragraph>
                        {preRead}
                    </ResourceTypography>
                    <ResourceTypography variant="h6" fontWeight='bold'>
                        Table of Contents
                    </ResourceTypography>
                    <List>
                        {sections.map((section, index) => (
                            !section.heading.toLowerCase().includes('table of contents') && (
                                <ShMuiLink key={index} component={RouterLink} to={`#section${index}`} onClick={(e) => {
                                    e.preventDefault();
                                    const element = document.getElementById(`section${index}`);
                                    if (element) {
                                        element.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}>
                                    <ResourceTypography> {index}. {section.heading} </ResourceTypography>
                                </ShMuiLink>
                            )
                        ))}
                    </List>
                    {sections.map((section, index) => (
                        <Box key={index} marginBottom={3} id={`section${index}`}>
                            {!section.heading.toLowerCase().includes('table of contents') && (
                                <ResourceTypography fontWeight="bold" variant="h5" gutterBottom>
                                    {section.heading}
                                </ResourceTypography>
                            )}
                            {Array.isArray(section.paragraphs) ? (
                                <List>
                                    {section.paragraphs.map((bullet, bulletIndex) => (
                                        !section.heading.toLowerCase().includes('table of contents') && (
                                            <ListItemText key={bulletIndex}>
                                                <ResourceTypography variant="body1" paddingTop={bullet.length > 80 ? 1 : 0}>
                                                    {bullet.length < 60 && `${bulletIndex + 1}.`} {bullet}
                                                </ResourceTypography>
                                            </ListItemText>
                                        )
                                    ))}
                                </List>
                            ) : (
                                <ResourceTypography variant="body1" paragraph>
                                    {section.paragraph}
                                </ResourceTypography>
                            )}
                            {index === 1 && (
                                <Stack paddingBottom={5} paddingTop={5}>
                                    <ShPaper elevation={4}>
                                        <Stack spacing={2} padding={1}>
                                            <ResourceTypography gutterBottom variant="h5" fontWeight="bold">
                                                Struggling to find qualified candidates for your Job?
                                            </ResourceTypography>
                                            <ResourceTypography gutterBottom variant="subtitle1">
                                                Discover how SmoothHiring's patented predictive analytics assessment ensures you find only the most qualified candidates!
                                            </ResourceTypography>
                                            <Stack paddingBottom={1} direction={isSmScreen ? 'column' : 'row'} spacing={1}>
                                                <Chip icon={<VerifiedIcon />} label="Sponsored Jobs" color="primary" variant='outlined' />
                                                <Chip icon={<VerifiedIcon />} label="Predictive Assessments" color="primary" variant='outlined' />
                                                <Chip icon={<VerifiedIcon />} label="Interview Scheduling" color="primary" variant='outlined' />
                                                <Chip icon={<PlaylistAddIcon />} label="And Much More!" color="primary" />
                                            </Stack>
                                            <ShButton href="https://app.smoothhiring.com/employers/new" size="large" color="primary" variant="contained" endIcon={<NearMeIcon />}>
                                                <Typography fontWeight="bold">Get Qualified Candidates&nbsp;</Typography>
                                            </ShButton>
                                        </Stack>
                                    </ShPaper>
                                </Stack>
                            )}
                        </Box>
                    ))}
                </Stack>
                {policyTemplate && (
                    <Stack padding={3}>
                        <ResourceTypography variant="h6" fontWeight="bold" paddingBottom={2}> Similar Policy Templates</ResourceTypography>
                        <Divider />
                        <Grid container spacing={1} paddingTop={2} >
                            {[...new Set(similarTemplates)].slice(0, 20).map((template, index) => (
                                <Grid item sm={12} md={6} key={index}>
                                    <ShMuiLink noWrap component={RouterLink}
                                        to={`/resources/policy-templates/${template.toLowerCase().split(" ").join("-")}`}
                                        variant="subtitle2" paddingTop={1} fontWeight='bold' noUnderline
                                    >
                                        {truncateString(template, 40)}
                                    </ShMuiLink>
                                </Grid>
                            ))}
                        </Grid>
                        <TemplateHomesStack color='white' spacing={2} marginBottom={10} backgroundColor={PrimaryWordpressThemeColor} marginTop={4}>
                            <ResourceTypography gutterBottom variant="h6" fontWeight="bold">
                                Ready to post your job to 100's of job boards?
                            </ResourceTypography>
                            <ResourceTypography gutterBottom variant="subtitle1">
                                LinkedIn, Talent.com, career page - place your job on multiple platforms for FREE with just one click.
                            </ResourceTypography>
                            <ShWordpressButton href="https://app.smoothhiring.com/employers/new" size="large" color="primary" variant="contained" endIcon={<NearMeIcon />}>
                                <Typography fontWeight="bold">Post this Job &nbsp;</Typography>
                            </ShWordpressButton>
                        </TemplateHomesStack>
                    </Stack>
                )}
            </ShContainer>
        </Stack>
    );
};

export default PolicyTemplatePage;