import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Box, Divider, Grid, IconButton, Rating, Stack, Tooltip, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ApplicantsFiltersMap, MatchTypes } from 'Modules/Core/Applicants/ApplicantsConstants';
import { FilterPopover } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsList.styled';
import { FilterType, IApplicantsFilter, IFilterComponent } from 'Modules/Core/Applicants/ApplicantsModel';
import { DistortionIcon, FitIcon, StrongFitIcon, WeakFitIcon } from 'assets/Icons';
import { useEffect, useState } from 'react';
import { ShButton } from 'shared/SharedStyles/ShInputs';

export const ApplicantsFilters = ({ applyFilters, selectedFilters }: IFilterComponent) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [filters, setFilters] = useState<IApplicantsFilter>(selectedFilters);

    const addFilter = (fltrType: FilterType, val: string) => {
        const tmpStgs = [...filters[fltrType]];
        const indx = tmpStgs.map(ts => ts.value).indexOf(val);
        if (indx === -1) {
            const obj = ApplicantsFiltersMap[fltrType].find(s => s.value === val);
            if (obj) {
                tmpStgs.push(obj);
            }
        } else {
            tmpStgs.splice(indx, 1);
        }
        setFilters({ ...filters, [fltrType]: tmpStgs });
    };

    // Update filters when removed from chips in ApplicantsList component.
    useEffect(() => { setFilters(selectedFilters) }, [selectedFilters]);

    return (<>
        <Tooltip title="Filters">
            <IconButton aria-label="filters" onClick={e => setAnchorEl(e.currentTarget)}>
                {anchorEl === null ? <FilterListIcon /> : <FilterListOffIcon />}
            </IconButton>
        </Tooltip>

        <FilterPopover id='filter-popover' open={open} anchorEl={anchorEl} elevation={0}
            onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <Box className='filters-wrapper'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    <Grid item xs={12} sm={5.5} md={5.5} lg={5.5}>
                        <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Stages</Typography>
                        <Stack>
                            {/* Map filters constant array with filter type key */}
                            {ApplicantsFiltersMap.stages.map(stg => (
                                <FormControlLabel key={stg.label} id={stg.label} aria-label={stg.label} label={stg.label}
                                    labelPlacement="start" value={stg.value}
                                    control={<Checkbox checked={filters.stages?.map(s => s.value)?.includes(stg.value)}
                                        onChange={() => addFilter('stages', stg.value)} />}
                                />
                            ))}
                        </Stack>
                    </Grid>
                    <Grid className='desktop-only' item xs={0} sm={0.4} md={0.4} lg={0.4}>
                        <Divider orientation="vertical" variant="middle" flexItem />
                    </Grid>
                    <Divider className='mobile-only' orientation="horizontal" flexItem />
                    <Grid item xs={12} sm={5.5} md={5.5} lg={5.5}>
                        <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Fit</Typography>
                        <Stack>
                            {/* Map filters constant array with filter type key with conditionally rendering icons */}
                            {ApplicantsFiltersMap.fit.map(fit => (
                                <FormControlLabel key={fit.label} id={fit.label} value={fit.value} aria-label={fit.label} labelPlacement="start"
                                    label={<Typography className='icon-label'>{fit.value === MatchTypes.strong ? <StrongFitIcon /> :
                                        fit.value === MatchTypes.distortion ? <DistortionIcon /> :
                                            fit.value === MatchTypes.match ? <FitIcon /> : <WeakFitIcon />}
                                        {fit.label}</Typography>} control={<Checkbox checked={filters.fit?.map(s => s.value)?.includes(fit.value)}
                                            onChange={() => addFilter('fit', fit.value)} />} />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
                <Divider orientation="horizontal" flexItem />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    <Grid item xs={12} sm={5.5} md={5.5} lg={5.5}>
                        <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Keywords</Typography>
                        <Stack>
                            {ApplicantsFiltersMap.keywords.map(kw => (
                                <FormControlLabel key={kw.label} id={kw.label} value={kw.value} label={kw.label} labelPlacement="start"
                                    control={<Checkbox checked={filters.keywords?.map(s => s.value)?.includes(kw.value)}
                                        onChange={() => addFilter('keywords', kw.value)} />} />
                            ))}
                        </Stack>
                    </Grid>
                    <Grid className='desktop-only' item xs={0} sm={0.4} md={0.4} lg={0.4}>
                        <Divider orientation="vertical" variant="middle" flexItem />
                    </Grid>
                    <Divider className='mobile-only' orientation="horizontal" flexItem />
                    <Grid item xs={12} sm={5.5} md={5.5} lg={5.5}>
                        <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Rating</Typography>
                        <Stack>
                            {ApplicantsFiltersMap.rating.map(rt => (
                                <FormControlLabel key={rt.label} id={rt.label} value={rt.value} aria-label={rt.label} labelPlacement="start"
                                    label={<Rating size='small' name={rt.label} value={parseInt(rt.value) ?? 0} readOnly />}
                                    control={<Checkbox checked={filters.rating?.map(s => s.value)?.includes(rt.value)}
                                        onChange={() => addFilter('rating', rt.value)} />}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Stack className='actions'>
                <ShButton size='small' variant='outlined'
                    onClick={() => setFilters({ stages: [], fit: [], keywords: [], rating: [] })}>Clear All</ShButton>
                {/* Apply button has to be clicked to apply the filters. This invokes the API call in ApplicantsList component.*/}
                <ShButton size='small' variant="contained" disableElevation onClick={() => { applyFilters(filters); setAnchorEl(null) }}>Apply</ShButton>
            </Stack>
        </FilterPopover>
    </>);
};