import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IApplicantActionsState, IRejectionEmailPayload, IZoomMeetAPIPayload, IZoomMeetResponse } from 'Modules/Core/Applicants/ApplicantsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from 'shared/constants';

const initialApplicantsState: IApplicantActionsState = {
    zoomMeetCreationResponse: '',
    zoomMeetCreationStatus: 'idle',
    zoomMeetDeleteResponse: '',
    zoomMeetDeleteStatus: 'idle',
    zoomMeetUpdateResponse: '',
    zoomMeetUpdateStatus: 'idle'
};

export const createZoomMeet = createAsyncThunk<IZoomMeetResponse, IZoomMeetAPIPayload, { rejectValue: IBaseResponse }>(
    'createZoomMeet',
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.ZOOM_MEETS}`, payload)
            .then((response: AxiosResponse<IZoomMeetResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateZoomMeet = createAsyncThunk<IZoomMeetResponse, IZoomMeetAPIPayload, { rejectValue: IBaseResponse }>(
    'updateZoomMeet',
    async ({ payload, zoomId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.ZOOM_MEETS}/${zoomId}`, payload)
            .then((response: AxiosResponse<IZoomMeetResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const deleteZoomMeet = createAsyncThunk<IZoomMeetResponse, IZoomMeetAPIPayload, { rejectValue: IBaseResponse }>(
    'deleteZoomMeet',
    async ({ zoomId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .delete(`${EmployerApiEndpoints.ZOOM_MEETS}/${zoomId}`)
            .then((response: AxiosResponse<IZoomMeetResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const sendRejectionEmail = createAsyncThunk<IBaseResponse, {
    payload: IRejectionEmailPayload, applicationId: number, jobId: number
}, { rejectValue: IBaseResponse }>(
    'sendRejectionEmail',
    async ({ payload, applicationId, jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYER_JOBS}/${jobId}/applicants/${applicationId}/reject_email`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const applicantActionsSlice = createSlice({
    name: 'applicantActions',
    initialState: initialApplicantsState,
    reducers: {
        resetZoomCreation: (state) => { state.zoomMeetCreationResponse = ''; state.zoomMeetCreationStatus = 'idle' },
        resetZoomUpdate: (state) => { state.zoomMeetUpdateResponse = ''; state.zoomMeetUpdateStatus = 'idle' },
        resetZoomDelete: (state) => { state.zoomMeetDeleteResponse = ''; state.zoomMeetDeleteStatus = 'idle' },
        resetRejectionEmail: (state) => { state.rejectionEmailResponse = ''; state.rejectionEmailStatus = 'idle' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplicantsState;
        });
        // create zoom meet
        builder.addCase(createZoomMeet.pending, (state, action) => {
            state.zoomMeetCreationStatus = 'pending';
        });
        builder.addCase(createZoomMeet.fulfilled, (state, action) => {
            state.zoomMeetCreationStatus = 'success';
            state.zoomMeetCreationResponse = 'Zoom meeting created successfully';
            state.zoomMeetResponse = action.payload;
        });
        builder.addCase(createZoomMeet.rejected, (state, action) => {
            state.zoomMeetCreationStatus = 'failed';
            state.zoomMeetCreationResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update zoom meet
        builder.addCase(updateZoomMeet.pending, (state, action) => {
            state.zoomMeetUpdateStatus = 'pending';
        });
        builder.addCase(updateZoomMeet.fulfilled, (state, action) => {
            state.zoomMeetUpdateStatus = 'success';
            state.zoomMeetUpdateResponse = 'Zoom meeting updated successfully';
            state.zoomMeetResponse = action.payload;
        });
        builder.addCase(updateZoomMeet.rejected, (state, action) => {
            state.zoomMeetUpdateStatus = 'failed';
            state.zoomMeetUpdateResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });
        // delete zoom meet
        builder.addCase(deleteZoomMeet.pending, (state, action) => {
            state.zoomMeetDeleteStatus = 'pending';
        });
        builder.addCase(deleteZoomMeet.fulfilled, (state, action) => {
            state.zoomMeetDeleteStatus = 'success';
            state.zoomMeetDeleteResponse = 'Zoom meeting deleted successfully';
            state.zoomMeetResponse = action.payload;
        });
        builder.addCase(deleteZoomMeet.rejected, (state, action) => {
            state.zoomMeetDeleteStatus = 'failed';
            state.zoomMeetDeleteResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });
        // send rejection email
        builder.addCase(sendRejectionEmail.pending, (state, action) => {
            state.rejectionEmailStatus = 'pending';
        });
        builder.addCase(sendRejectionEmail.fulfilled, (state, action) => {
            state.rejectionEmailStatus = 'success';
            state.rejectionEmailResponse = action.payload?.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(sendRejectionEmail.rejected, (state, action) => {
            state.rejectionEmailStatus = 'failed';
            state.rejectionEmailResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetZoomCreation, resetZoomDelete, resetZoomUpdate, resetRejectionEmail } = applicantActionsSlice.actions;
export default applicantActionsSlice;
